import React, { useState, useEffect} from 'react';
import { Table, Breadcrumb, Button, Nav, Tab, Container } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import Select from 'react-select';
import AddClient from './AddClient';
import EditClient from './EditClient';
// import InviteClient from './InviteClient';
import EditOrg from './EditOrg';
import ShowOrgUsers from './ShowOrgUsers';
import ShowOrgProjects from './ShowOrgProjects';
import ShowClientProjects from './ShowClientProjects';
import { CSVLink } from "react-csv";
import { paginationOptions, customStyles } from '../../data/Data.js'
import reportError from '../../utils/ReportError';



export default function User() {

    const Parse = require('parse');

    const [users, setUsers] = useState([]);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [isUserTableLoaded, setIsUserTableLoaded] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [isOrgsLoaded, setIsOrgsLoaded] = useState(false);
    const [isOrgTableLoaded, setIsOrgTableLoaded] = useState(false);

    const [key, setKey] = useState('users');

    useEffect(() => {
        document.title = "Clients";
        if (!isUsersLoaded) {
            queryUsers();
        }
        if (!isOrgsLoaded) {
            queryOrgs();
        }
    },[]);

    const queryUsers = () => {
        const clientQuery = new Parse.Query(Parse.Role);
        clientQuery.equalTo('name', 'client');
        clientQuery.first()
        .then(function(clientQueryRes) {
            const clients = clientQueryRes.getUsers().query();
            clients.limit(999999);
            clients.notEqualTo('email', 'nouser@email.com');
            clients.ascending('firstName');
            clients.find()
            .then((userQueryRes) => {
                setUsers(userQueryRes);
                setIsUsersLoaded(true);
            }, (error) => {
                reportError('User 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('User 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryOrgs = () => {
        const Organisation = Parse.Object.extend('Organisation');
        const query = new Parse.Query(Organisation);
        query.limit(999999);
        query.notContainedIn('name', ['No organisation added', 'Riskcon Engineering']);
        query.ascending('name');
        query.find()
        .then(function(orgQueryRes) {
            setOrgs(orgQueryRes);
            const options = orgQueryRes.map(org => ({
                "value" : org.id,
                "label" : org.attributes.name,
                "object" : org,
            }));
            setOrgOptions(options);
            setIsOrgsLoaded(true);
        }, (error) => {
            reportError('User 3', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const getUserData = () => {

        var data2 = [];

        for (var i = 0; i < users.length; i++) {
            var user = users[i];
            var verified = '';
            if (user.get('emailVerified')) {
                verified = <i className="fas fa-check" style={{color: 'var(--success)'}}></i>;
            } else {
                verified = <i className="fas fa-lock" style={{color: 'var(--danger)'}}></i>;
            }

            const firstName = user.get('firstName');
            const lastName = user.get('lastName');
            const usersOrg = user.get('organisation');

            var orgIdDisp = usersOrg.id;
            var orgDisp = usersOrg.attributes.name;

            data2.push({
                id: i,
                idDisplay: user.id,
                firstNameDisplay: firstName,
                lastNameDisplay: lastName,
                nameDisplay: firstName + ' ' + lastName,
                emailDisplay: user.get('username'),
                orgIdDisplay: orgIdDisp,
                organisationDisplay: orgDisp,
                verifiedDisplay: verified,
            })
        }

        return data2;
    }

    const [userData, setUserData] = React.useState(React.useMemo(() => getUserData, [isUsersLoaded]));
    if (!isUserTableLoaded) {
        if (users.length > 0) {
            setUserData(getUserData);
            setIsUserTableLoaded(true);
        }
    }

    const userCols = React.useMemo(
        () => [
            {
                Header: 'User Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'first name',
                accessor: 'firstNameDisplay',
                show: false,
            },
            {
                Header: 'last name',
                accessor: 'lastNameDisplay',
                show: false,
            },
            {
                Header: 'users org id',
                accessor: 'orgIdDisplay',
                show: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'nameDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Email</span>,
                accessor: 'emailDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Organisation</span>,
                accessor: 'organisationDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Projects</span>,
                accessor: 'projectsDisplay',
                Cell: ({ cell }) => <>
                    <ShowClientProjects
                        row={cell.row}
                    />
                </>
            },
            // {
            //     Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Invited</span>,
            //     accessor: 'verifiedDisplay',
            // },
            {
                Header: "",
                id: "update",
                accessor: "updateDisplay",
                Cell: ({ cell }) => <>
                    <EditClient
                        row={cell.row}
                        userData={[...userData]}
                        setUserData={setUserData}
                        orgOptions={orgOptions}
                    />
                    {/* <span>&nbsp;</span>
                    <InviteClient
                        row={cell.row}
                    /> */}
                </>
            }
        ],
        [userData]
    );

    const getOrgData = () => {

        var data2 = [];

        for (var i = 0; i < orgs.length; i++) {
            var org = orgs[i];
            var PO = '';
            var POValue = 'false';
            if (org.get('POrequired')) {
                PO = <i className="fas fa-check" style={{color: 'var(--success)'}}></i>;
                POValue = 'true';
            } else {
                PO = <i className="fas fa-times" style={{color: 'var(--danger)'}}></i>;
            }
            data2.push({
                id: i,
                idDisplay: org.id,
                nameDisplay: org.get('name'),
                PODisplay: PO,
                POValueDisplay: POValue,
            })
        }

        return data2;
    }

    const [orgData, setOrgData] = React.useState(React.useMemo(() => getOrgData, [isOrgsLoaded]));
    if (!isOrgTableLoaded) {
        if (orgs.length > 0) {
            setOrgData(getOrgData);
            setIsOrgTableLoaded(true);
        }
    }

    const orgCols = React.useMemo(
        () => [
            {
                Header: 'Org Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'PO Value',
                accessor: 'POValueDisplay',
                show: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'nameDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>PO Required</span>,
                accessor: 'PODisplay',
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Users</span>,
                accessor: 'usersDisplay',
                Cell: (tableProps) => <>
                    <ShowOrgUsers
                        row={tableProps.row}
                        userData={[...userData]}
                    />
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Projects</span>,
                accessor: 'projectsDisplay',
                Cell: (tableProps) => <>
                    <ShowOrgProjects
                        row={tableProps.row}
                    />
                </>
            },
            {
                Header: "",
                id: "update",
                accessor: "updateDisplay",
                Cell: (tableProps) => <>
                    <EditOrg
                        row={tableProps.row}
                        orgData={[...orgData]}
                        setOrgData={setOrgData}
                    />
                </>
            },
        ],
        [orgData, userData]
    );

    // const csvData = [
    //     ["Name", "Email", "Phone", "Organisation"],
    //     ...userData.map(({ nameDisplay, emailDisplay, phoneDisplay, organisationDisplay }) => [
    //         nameDisplay,
    //         emailDisplay,
    //         phoneDisplay,
    //         organisationDisplay,
    //     ]),
    // ];
    
    const UserTable = () => {
        if(isUsersLoaded) {
            return <>
                <TableView columns={userCols} data={userData} tableType='users'/>
            </>    
        } else if (!isUsersLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading client users...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No client users found</td></tr></tbody></Table>
        }
    }

    const OrgTable = () => {
        if(isOrgsLoaded) {
            return <>
                <TableView columns={orgCols} data={orgData} tableType='org'/>
            </>    
        } else if (!isOrgsLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading client organisations...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No client organisations found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data, tableType }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            // setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 10,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        // const [pageFilter, setPageFilter] = useState({
        //     "value" : "20",
        //     "label" : "Show 20",
        // });

        // const handlePageFilter = (e) => {
        //     setPageFilter(e);
        //     setPageSize(Number(e.value));
        // }

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {/* <CSVLink className='cont' filename="lote-clients.csv" data={csvData}>
                    <i className="fas fa-file-excel"></i>
                </CSVLink>                 */}
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                    {/* <Select 
                        styles={customStyles}
                        className="right-select"
                        options={paginationOptions}
                        value={pageFilter}
                        onChange={e => {handlePageFilter(e)}}
                    /> */}
                </div>
            </Container>
        )
    }

    


    function handleTabSelect (selectedKey) {
        // if (selectedKey === 'users') {
        //     setShowAddClient(true);
        // } else {
        //     setShowAddClient(false);
        // }
        setKey(selectedKey);
    }

    return <>
        {/* <Container>
            <Breadcrumb className='container'>
                <Breadcrumb.Item href='/staff'>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Clients</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="home-header mb-2">
                <h2>&nbsp;</h2>
                <AddClient 
                    orgOptions={orgOptions}
                    setOrgOptions = {setOrgOptions}
                    userData={[...userData]}
                    setUserData={setUserData}
                    orgData={[...orgData]}
                    setOrgData={setOrgData}
                />
            </div>
            {/* <hr/> */}
            <Tab.Container defaultActiveKey='users' activeKey={key} onSelect={(k) => handleTabSelect(k)}>
                <Nav justify variant='tabs'>
                    <Nav.Item>
                        <Nav.Link eventKey='users'>Client Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey='org'>Client Organisations</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey='users'>
                        <div className="table-homepage">
                            <div>
                                <UserTable />
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='org'>
                        <div className="table-homepage">
                            <div>
                                <OrgTable />
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    </>
}

    // const getUserData = () => {

    //     var data2 = [];

    //     for (var i = 0; i < users.length; i++) {
    //         var user = users[i];
    //         var verified = '';
    //         if (user.get('emailVerified')) {
    //             verified = <i className="fas fa-check" style={{color: '#198754'}}></i>;
    //         } else {
    //             verified = <i className="fas fa-times" style={{color: '#990000'}}></i>;
    //         }

    //         data2.push({
    //             id: i,
    //             idDisplay: user.id,
    //             nameDisplay: user.get('firstName') + ' ' + user.get('lastName'),
    //             phoneDisplay: user.get('phone'),
    //             usernameDisplay: user.get('username'),
    //             verifiedDisplay: verified,
    //             statusDisplay: user.get('status'),
    //         })
    //     }

    //     return data2;
    // }

    // const [userData, setUserData] = React.useState(React.useMemo(() => getUserData, [isUsersLoaded]));
    // if (!isUserTableLoaded) {
    //     if (users.length > 0) {
    //         setUserData(getUserData);
    //         setIsUserTableLoaded(true);
    //     }
    // }

    // const userCols = React.useMemo(
    //     () => [
    //         {
    //             Header: 'User Id',
    //             accessor: 'idDisplay',
    //             show: false,
    //         },
    //         {
    //             Header: 'Name',
    //             accessor: 'nameDisplay',
    //         },
    //         {
    //             Header: 'Phone',
    //             accessor: 'phoneDisplay',
    //         },
    //         {
    //             Header: 'Username',
    //             accessor: 'usernameDisplay',
    //         },
    //         {
    //             Header: 'Verified',
    //             accessor: 'verifiedDisplay',
    //         },
    //         {
    //             Header: 'Status',
    //             accessor: 'statusDisplay',
    //         },
    //         // {
    //         //     Header: "",
    //         //     id: "update",
    //         //     accessor: "updateDisplay",
    //         //     Cell: (tableProps) => <>
    //         //         <Button variant='secondary'
    //         //             onClick={() => {
    //         //                 const dataCopy = [...userData];
    //         //                 dataCopy.splice(tableProps.row.index, 1);
    //         //                 setUserData(dataCopy);
    //         //             }}
    //         //         >
    //         //             <i className='fas fa-pen'/>
    //         //         </Button>
    //         //         <span>&nbsp;</span>
    //         //         <Button variant='outline-primary'
    //         //             onClick={() => {
    //         //                 const dataCopy = [...userData];
    //         //                 dataCopy.splice(tableProps.row.index, 1);
    //         //                 setUserData(dataCopy);
    //         //             }}
    //         //         >
    //         //             Invite
    //         //         </Button>
    //         //     </>
    //         // }
    //     ],
    //     [userData]
    // );

    // const getOrgData = () => {

    //     var data2 = [];

    //     for (var i = 0; i < orgs.length; i++) {
    //         var org = orgs[i];
    //         var userView = '';


    //         // User view should be a button
    //         // Which on press returns query which shows users in org
    //         // const users = org.relation('users');
            
    //         // users.query().find().then((res)=> {
    //         //     for (let j = 0; j < res.length; j++) {
    //         //         const user = res[j];
    //         //         console.log(user.attributes.firstName);
    //         //         userView = user.attributes.firstName + ' ' + user.attributes.lastName;
    //         //     }
    //         // });

    //         data2.push({
    //             id: i,
    //             idDisplay: org.id,
    //             nameDisplay: org.get('name'),
    //             // usersDisplay: userView,
    //         })
    //     }

    //     return data2;
    // }

    // const [orgData, setOrgData] = React.useState(React.useMemo(() => getOrgData, [isOrgsLoaded]));
    // if (!isOrgTableLoaded) {
    //     if (orgs.length > 0) {
    //         setOrgData(getOrgData);
    //         setIsOrgTableLoaded(true);
    //     }
    // }

    // const orgCols = React.useMemo(
    //     () => [
    //         {
    //             Header: 'Org Id',
    //             accessor: 'idDisplay',
    //             show: false,
    //         },
    //         {
    //             Header: 'Name',
    //             accessor: 'nameDisplay',
    //         },
    //         // {
    //         //     Header: 'Users',
    //         //     accessor: 'usersDisplay',
    //         // },
    //         // {
    //         //     Header: "",
    //         //     id: "update",
    //         //     accessor: "updateDisplay",
    //         //     Cell: (tableProps) => <>
    //         //         <EditOrg
    //         //             row={tableProps.row}
    //         //             orgData={[...orgData]}
    //         //             setOrgData={setOrgData}
    //         //         />
    //         //         <Button variant='secondary'
    //         //             onClick={() => {
    //         //                 const dataCopy = [...orgData];
    //         //                 dataCopy.splice(tableProps.row.index, 1);
    //         //                 setOrgData(dataCopy);
    //         //             }}
    //         //         >
    //         //             <i className='fas fa-pen'/>
    //         //         </Button>
    //         //     </>
    //         // }
    //     ],
    //     [orgData]
    // );

    // const getStaffData = () => {

    //     var data2 = [];

    //     for (var i = 0; i < staff.length; i++) {
    //         const user = staff[i];

    //         var verified = '';
    //         if (user.get('emailVerified')) {
    //             verified = <i className="fas fa-check" style={{color: '#198754'}}></i>;
    //         } else {
    //             verified = <i className="fas fa-times" style={{color: '#990000'}}></i>;
    //         }

    //         data2.push({
    //             id: i,
    //             idDisplay: user.id,
    //             nameDisplay: user.get('firstName') + ' ' + user.get('lastName'),
    //             phoneDisplay: user.get('phone'),
    //             usernameDisplay: user.get('username'),
    //             verifiedDisplay: verified,
    //             statusDisplay: user.get('status'),
    //         })
    //     }

    //     return data2;
    // }

    // const [staffData, setStaffData] = React.useState(React.useMemo(() => getStaffData, [isStaffLoaded]));
    // if (!isStaffTableLoaded) {
    //     if (staff.length > 0) {
    //         setStaffData(getStaffData);
    //         setIsStaffTableLoaded(true);
    //     }
    // }

    // const staffCols = React.useMemo(
    //     () => [
    //         {
    //             Header: 'User Id',
    //             accessor: 'idDisplay',
    //             show: false,
    //         },
    //         {
    //             Header: 'Name',
    //             accessor: 'nameDisplay',
    //         },
    //         {
    //             Header: 'Phone',
    //             accessor: 'phoneDisplay',
    //         },
    //         {
    //             Header: 'Username',
    //             accessor: 'usernameDisplay',
    //         },
    //         {
    //             Header: 'Verified',
    //             accessor: 'verifiedDisplay',
    //         },
    //         {
    //             Header: 'Status',
    //             accessor: 'statusDisplay',
    //         },
    //         // {
    //         //     Header: "",
    //         //     id: "update",
    //         //     accessor: "updateDisplay",
    //         //     Cell: (tableProps) => <>
    //         //         <Button variant='secondary'
    //         //             onClick={() => {
    //         //                 const dataCopy = [...staffData];
    //         //                 dataCopy.splice(tableProps.row.index, 1);
    //         //                 setStaffData(dataCopy);
    //         //             }}
    //         //         >
    //         //             <i className='fas fa-pen'/>
    //         //         </Button>
    //         //     </>
    //         // }
    //     ],
    //     [staffData]
    // );
    
    // const UserTable = () => {
    //     if(isUsersLoaded) {
    //         return <>
    //             <TableView columns={userCols} data={userData} tableType='users'/>
    //         </>    
    //     } else if (!isUsersLoaded) {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}><em>Loading users...</em></td></tr></tbody></Table>
    //     } else {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}>No users found</td></tr></tbody></Table>
    //     }
    // }

    // const OrgTable = () => {
    //     if(isOrgsLoaded) {
    //         return <>
    //             <TableView columns={orgCols} data={orgData} tableType='org'/>
    //         </>    
    //     } else if (!isOrgsLoaded) {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}><em>Loading organisations...</em></td></tr></tbody></Table>
    //     } else {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}>No organisations found</td></tr></tbody></Table>
    //     }
    // }
    
    // const StaffTable = () => {
    //     if(isStaffLoaded) {
    //         return <>
    //             <TableView columns={staffCols} data={staffData} tableType='staff'/>
    //         </>    
    //     } else if (!isStaffLoaded) {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}><em>Loading users...</em></td></tr></tbody></Table>
    //     } else {
    //         return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center'}}>No users found</td></tr></tbody></Table>
    //     }
    // }

    // function GlobalFilter({
    //     preGlobalFilteredRows,
    //     globalFilter,
    //     setGlobalFilter,
    // }) {
    //     const [value, setValue] = React.useState(globalFilter)
    //     const onChange = useAsyncDebounce(value => {
    //         setGlobalFilter(value || undefined)
    //     }, 200)
      
    //     return (
    //         <span>
    //             <input
    //                 value={value || ""}
    //                 onChange={e => {
    //                     setValue(e.target.value);
    //                     onChange(e.target.value);
    //                 }}
    //                 placeholder={'Search'}
    //                 style={{
    //                     fontSize: '1.1rem',
    //                     border: '0',
    //                     width: '100%',
    //                 }}
    //             />
    //         </span>
    //     )
    // }

    // function TableView ({ columns, data, tableType }) {

    //     const {
    //         getTableProps,
    //         getTableBodyProps,
    //         headerGroups,
    //         prepareRow,
    //         page,
    //         canPreviousPage,
    //         canNextPage,
    //         pageOptions,
    //         pageCount,
    //         gotoPage,
    //         nextPage,
    //         previousPage,
    //         setPageSize,
    //         state: { pageIndex, pageSize },
    //         state,
    //         // setFilter,
    //         preGlobalFilteredRows,
    //         setGlobalFilter,
    //     } = useTable(
    //         {
    //             columns,
    //             data,
    //             initialState: {
    //                 pageIndex: 0,
    //                 pageSize: 20,
    //                 hiddenColumns: columns.map(column => {
    //                     if (column.show === false) {
    //                         return column.accessor || column.id;
    //                     }
    //                 }),
    //             },            
    //         },
    //         useFilters,
    //         useGlobalFilter,
    //         useSortBy,
    //         usePagination
    //     )

    //     return (
    //         <Container>

    //             <div>&nbsp;</div>
    //             <GlobalFilter
    //                 preGlobalFilteredRows={preGlobalFilteredRows}
    //                 globalFilter={state.globalFilter}
    //                 setGlobalFilter={setGlobalFilter}
    //             />
    //             <div>&nbsp;</div>

    //             <Table {...getTableProps()}>
                    
    //                 <thead>
    //                     {headerGroups.map(headerGroup => (
    //                     <tr {...headerGroup.getHeaderGroupProps()}>
    //                         {headerGroup.headers.map(column => (
    //                         <th {...column.getHeaderProps(column.getSortByToggleProps())}>
    //                             {column.render('Header')}
    //                             <span>
    //                                 {column.isSorted
    //                                 ? column.isSortedDesc
    //                                     ? ' 🔽'
    //                                     : ' 🔼'
    //                                 : ''}
    //                             </span>
    //                         </th>
    //                         ))}
    //                     </tr>
    //                     ))}
    //                 </thead>
    //                 <tbody {...getTableBodyProps()}>
    //                     {page.map((row, i) => {
    //                         prepareRow(row)
    //                         return (
    //                         <tr {...row.getRowProps()}>
    //                             {row.cells.map(cell => {
    //                             return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    //                             })}
    //                         </tr>
    //                         )
    //                     })}
    //                 </tbody>
    //             </Table>
    //             <div className="pagination">
    //                 <span>
    //                     <Button variant="outline-secondary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
    //                         {'<<'}
    //                     </Button>
    //                     &nbsp;
    //                     <Button variant="outline-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
    //                         {'<'}
    //                     </Button>
    //                     &nbsp;
    //                     <Button variant="outline-secondary" onClick={() => nextPage()} disabled={!canNextPage}>
    //                         {'>'}
    //                     </Button>
    //                     &nbsp;
    //                     <Button variant="outline-secondary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
    //                         {'>>'}
    //                     </Button>
    //                     &nbsp;&nbsp;&nbsp;&nbsp;
    //                     <span>
    //                         Page&nbsp;
    //                             <strong>{pageIndex + 1}</strong> of {pageOptions.length}
    //                     </span>
    //                 </span>
    //             </div>
    //         </Container>
    //     )
    // }

    

    // const customSelectStyles = {
    //     valueContainer: () => ({
    //         // none of react-select's styles are passed to <Control />
    //         height: 'calc(2.75rem) !important',
    //         padding: '0.75rem 0.75rem',
    //         fontSize: '0.9rem'
    //     }),
    //     input: () => ({
    //         paddingTop: '.5rem',
    //     }),
    //     singleValue: () => ({
    //         padding: '.5rem 0',
    //         paddingLeft: '.1rem',
    //     }),
    //     placeholder: (provided, state) => ({
    //         ...provided,
    //         position: 'absolute',
    //         top: state.hasValue || state.selectProps.inputValue,
    //         transition: "top 0.1s, font-size 0.1s",
    //         fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    //     })
    // }

// function AddClient() {
        
//     // Modal
//     const [show,setShow] = useState(false);

//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName ] = useState('');
//     const [phone, setPhone] = useState('');
//     const [email, setEmail] = useState('');
//     const [organisation, setOrganisation] = useState('');
//     // const [clientRole, setClientRole] = useState('');
//     const [clientType, setClientType] = useState('individual');
//     const [disable, setDisable] = useState(true);
//     const [organisationOptions, setOrganisationOptions] = useState([]);
//     const [isNewOrganisation, setIsNewOrganisation] = useState(false);

//     const Parse = require('parse');

//     // const history = useHistory();

//     useEffect(()=> {
//         const queryOrgs = () => {
//             const Parse = require('parse');
//             const orgQuery = new Parse.Query('Organisation');
//             orgQuery.find()
//             .then(function(orgQueryRes) {
//                 const options = orgQueryRes.map(organisation => ({
//                     "value" :organisation.id,
//                     "label" : organisation.get('name'),
//                     "object" : organisation
//                 }));
//                 setOrganisationOptions(options);
//             }, (error) => {
    // reportError('User 4', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//             });
//         }
//         queryOrgs();
//     },[])

//     useEffect(()=>{
//         if (clientType === 'individual') {
//             (firstName && lastName && phone && email) ? setDisable(false) : setDisable(true);
//         } else {
//             (firstName && lastName && phone && email && organisation) ? setDisable(false) : setDisable(true);
//         }
//     },[firstName, lastName, phone, email, organisation, clientType])

//     const handleClose = () => {
//         setFirstName('');
//         setLastName('');
//         setPhone('');
//         setEmail('');
//         setOrganisation('');
//         setClientType('individual');
//         setShow(false);
//     }
//     const handleShow = () => {
//         setShow(true);
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const User = Parse.Object.extend('User');
//         const user = new User();

//         user.set('firstName', firstName);
//         user.set('lastName', lastName);
//         user.set('phone', phone);
//         user.set('email', email);
//         user.set('username', email);
//         user.set('status', 'Active');

//         const password = Math.random().toString(36) + Math.random().toString(36).toUpperCase();
//         user.set('password', password);

//         // ACL
//         var acl = new Parse.ACL();
//         acl.setPublicReadAccess(false);
//         acl.setRoleReadAccess("superadmin", true);
//         acl.setRoleWriteAccess("superadmin", true);
//         acl.setRoleReadAccess("admin", true);
//         acl.setRoleWriteAccess("admin", true);
//         acl.setRoleReadAccess("staff", true);
//         acl.setRoleWriteAccess("staff", false);
//         acl.setRoleReadAccess("client", true);
//         acl.setRoleWriteAccess("client", false);
//         user.setACL(acl);

//         user.save()
//         .then((res)=>{
//             // Organisation
//             const Org = Parse.Object.extend('Organisation');
//             const org = new Org();

//             if(isNewOrganisation && clientType === 'organisation') {
//                 org.set('name', organisation);
//                 org.save().then((res2)=> {
//                     res2.relation('users').add(res);
//                     res2.save();
//                 }).catch((error)=> {
    // reportError('User 5', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//                 })  
//             } else if (clientType === 'organisation' && !isNewOrganisation) {
//                 const res2 = organisation.object;
//                 res2.relation('users').add(res);
//                 res2.save();
//             }

//             // Add user to client role
//             var query = new Parse.Query(Parse.Role);
//             query.equalTo('name', 'client');
//             query.first()
//             .then((res2)=>{
//                 res2.getUsers().add(res);
//                 res2.save();
//             }).catch((error)=> {
    // reportError('User 6', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//             })
//         }).catch((error)=> {
    // reportError('User 7', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//         })      
//         handleClose();
//     }

//     // const getClientRole = () => {
//     //     const query = new Parse.Query(Parse.Role);
//     //     query.equalTo('name','client')
//     //     .first()
//     //     .then(client => { 
//     //         setClientRole(client);
//     //     })
//     //     .catch(error => { 
    // reportError('User 8', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//     //     });
//     // }

//     const clientTypeHandle = (e) => {
//         const type = e.target.value;
//         if(type === 'organisation') {
//             document.getElementById('organisation-input').className = "flex";
//         } else {
//             document.getElementById('organisation-input').className = "hide";
//         }
//         setClientType(type);
//     }

//     const statusStageHandleChange = (newValue, actionMeta) => {
//         if(actionMeta.action === 'select-option') {
//             setOrganisation(newValue.label);
//             setIsNewOrganisation(true);
//         } else if (actionMeta.action === 'create-option') {
//             setOrganisation(newValue.label);
//             setIsNewOrganisation(true);
//         } else if (actionMeta.action === 'clear') {
//             setOrganisation('');
//             setIsNewOrganisation(false);
//         }
//     }

//     const handleInputChange = (inputValue, actionMeta) => {
//         if(actionMeta.action === 'set-value') {
//             setOrganisation(inputValue);
//         } else if (actionMeta.action === 'clear') {
//             setOrganisation('');
//         }
//     }

//     const { ValueContainer, Placeholder } = components;

//     const CustomValueContainer = ({ children, ...props }) => {
//         return (
//             <ValueContainer {...props}>
//             <Placeholder {...props} isFocused={props.isFocused}>
//                 {props.selectProps.placeholder}
//             </Placeholder>
//             {React.Children.map(children, (child) =>
//                 child && child.type !== Placeholder ? child : null
//             )}
//             </ValueContainer>
//         );
//     };

//     const queryClientRole = () => {
//         const clientRoleQuery = new Parse.Query(Parse.Role);
//         clientRoleQuery.equalTo('name', 'client');
//         // const newClient = {
//         //     username: 'test',
//         //     password: 'test'
//         // }
//         clientRoleQuery.first()
//         .then(function(staffQueryRes) {
//             var relation = staffQueryRes.relation("users");
//             var query = relation.query();
//         }, (error) => {
    // reportError('User 9', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//         });
//     }

//     return (
//         <>
//             <Button onClick={handleShow}>New Client</Button>
//             <Modal show={show} onHide={handleClose} centered>
//                 <Modal.Header closeButton>
//                 <Modal.Title>New User</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group className="mb-2">
//                             <Form.Group className="mb-2">
//                                 <Form.Check
//                                     inline
//                                     id='radio-type-1'
//                                     name='radio-type'
//                                     label='Individual/Sole Trader'
//                                     value='individual'
//                                     type='radio'
//                                     onChange={clientTypeHandle}
//                                     defaultChecked
//                                 />
//                                 <Form.Check
//                                     inline
//                                     id='radio-type-2'
//                                     name='radio-type'
//                                     label='Organisation'
//                                     value='organisation'
//                                     type='radio'
//                                     onChange={clientTypeHandle}
//                                 />
//                             </Form.Group>
//                             <div id='organisation-input' className="hide">
//                                 <Form.Group as={Col} md="8">
//                                     <CreatableSelect
//                                         placeholder="Organisation"
//                                         components={{
//                                             ValueContainer: CustomValueContainer
//                                         }}
//                                         styles={customSelectStyles}
//                                         options={organisationOptions}
//                                         onChange={statusStageHandleChange}
//                                         onInputChange={handleInputChange}
//                                         getOptionValue={option => option.label}
//                                         isClearable
//                                         autoFocus
//                                     />
//                                 </Form.Group>
//                                 {/* <FloatingLabel label="Organisation">
//                                     <Form.Control
//                                         type='text'
//                                         placeholder='Enter Organisation Name...'
//                                         onChange={(e)=>setOrganisation(e.target.value)}
//                                     />
//                                 </FloatingLabel> */}
//                             </div>
//                         </Form.Group>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="First Name">
//                                 <Form.Control
//                                     type='text'
//                                     placeholder='Enter First Name...'
//                                     onChange={(e)=>setFirstName(e.target.value)}
//                                 />
//                             </FloatingLabel>    
//                         </Form.Group>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="Last Name">
//                                 <Form.Control
//                                     type='text'
//                                     placeholder='Enter Last Name...'
//                                     onChange={(e)=>setLastName(e.target.value)}
//                                 />
//                             </FloatingLabel>
//                         </Form.Group>
//                         <FloatingLabel label='Phone Number'>
//                             <NumberFormat
//                                 id="new-client-phone"
//                                 format="+61 #### ### ###"
//                                 className="form-control mb-2"
//                                 placeholder="Client Phone Number"
//                                 required
//                                 onChange={(e) => setPhone(e.target.value)}
//                             />
//                         </FloatingLabel>
//                         <FloatingLabel label='Email'>
//                             <Form.Control
//                                 id="new-client-email"
//                                 type="email"
//                                 className="mb-2"
//                                 placeholder="name@example.com"
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 required
//                             />
//                         </FloatingLabel>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>Close</Button>
//                     <Button
//                         variant="primary"
//                         onClick={handleSubmit}
//                         disabled={disable}
//                     >
//                         Submit
//                     </Button>
//                 </Modal.Footer>
//             </Modal>  
//         </>
//     )
// }

// function EditClient(props) {

//     // Variable Hooks
//     const [firstName,setFirstName] = useState();
//     const [lastName,setLastName] = useState();
//     const [organisation,setOrganisation] = useState();
//     const [phone,setPhone] = useState();
//     // const [email,setEmail] = useState();
    
//     const history = useHistory();
    
//     // Modal
//     const [show,setShow] = useState(false);

//     useEffect(()=>{
//         setFirstName(props.firstName);
//         setLastName(props.lastName);
//         setOrganisation(props.organisation);
//         setPhone(props.phone);
//         // setEmail(props.email);
//     },[show])

//     const handleClose = () => {
//         setFirstName('');
//         setLastName('');
//         setOrganisation('');
//         setPhone('');
//         // setEmail('');
//         setShow(false);
//     }
//     const handleShow = () => {
//         setShow(true);
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const Parse = require('parse');

//         const Client = Parse.Object.extend('User');
//         const client = new Parse.Query(Client);

//         client.get(props.user.id)
//         .then((client) => {
//             client.set('ACL', accessControlList);
//             client.set('firstName', firstName);
//             client.set('lastName', lastName);
//             client.set('phone', phone);
//             // client.set('email', email);
//             client.set('organisation', organisation);
//             client.save().then(()=>{
//                 setTimeout(()=>{
//                     history.go(0);
//                 })
//             }).catch((error)=> {
    // reportError('User 10', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');
//             })
//         }).catch((error)=> {
    // reportError('User 11', error.message);
    // alert('Error: Please refresh the page and try again. An error report has been sent.');//         });
//         handleClose();
//     }

//     return (
//         <>
//             <Button variant="secondary" size="sm" style={{fontSize:'0.7rem'}} onClick={handleShow}>
//                 <i className="fas fa-pen"/>
//             </Button>
//             <Modal show={show} onHide={handleClose} centered>
//                 <Modal.Header closeButton>
//                 <Modal.Title>Update User</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="Organisation">
//                                 <Form.Control
//                                     type='text'
//                                     placeholder='Enter Organisation Name...'
//                                     defaultValue={props.organisation}
//                                     onChange={(e)=>setOrganisation(e.target.value)}
//                                 />
//                             </FloatingLabel>
//                         </Form.Group>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="First Name">
//                                 <Form.Control
//                                     type='text'
//                                     placeholder='Enter First Name...'
//                                     defaultValue={props.firstName}
//                                     onChange={(e)=>setFirstName(e.target.value)}
//                                 />
//                             </FloatingLabel>    
//                         </Form.Group>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="Last Name">
//                                 <Form.Control
//                                     type='text'
//                                     placeholder='Enter Last Name...'
//                                     defaultValue={props.lastName}
//                                     onChange={(e)=>setLastName(e.target.value)}
//                                 />
//                             </FloatingLabel>    
//                         </Form.Group>
//                         <Form.Group className="mb-2">
//                             <FloatingLabel label="Phone">
//                                 <NumberFormat
//                                     format="+(##) ##########"
//                                     className="form-control mb-2"
//                                     placeholder="Client Phone Number"
//                                     defaultValue={props.phone}
//                                     required
//                                     onChange={(e) => setPhone(e.target.value)}
//                                 />
//                             </FloatingLabel>
//                         </Form.Group>
//                         {/* <Form.Group className="mb-2">
//                             <FloatingLabel label='Email'>
//                                 <Form.Control
//                                     type="email"
//                                     placeholder="name@example.com"
//                                     defaultValue={props.email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     required
//                                 />
//                             </FloatingLabel>
//                         </Form.Group> */}
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>Close</Button>
//                     <Button variant="primary" onClick={handleSubmit}>Update</Button>
//                 </Modal.Footer>
//             </Modal>  
//         </>
//     );
// }

// const accessControlList = () => {
//     const Parse = require('parse');
//     var acl = new Parse.ACL();
//     acl.setPublicReadAccess(true);
//     acl.setRoleReadAccess("staff", true);
//     acl.setRoleWriteAccess("staff", true);
//     return acl;
// }
