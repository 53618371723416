import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import reportError from '../../utils/ReportError';


export default function EditTaskDeadline(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [action, setAction] = useState('Edit Deadline');
    const [deadline, setDeadline] = useState();
    const [submitString, setSubmitString] = useState('Save Task');

    useEffect(()=> {
        (!deadline) ? setIsDisabled(true) : setIsDisabled(false)
    }, [deadline]);

    const handleClose = () =>  {
        setAction('Edit Deadline');
        setDeadline('');
        setSubmitString('Save Task');
        setShow(false);
    }

    const handleShow = () => {
        setAction(props.row.values.actionTextDisplay);
        setDeadline(props.row.values.deadlineValueDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const taskId = props.row.values.idDisplay;

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(taskId)
        .then((task) => {
            task.set('deadline', deadline);
            task.save()
            .then((res)=>{
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                if (deadline) {
                    thisTask.deadlineValueDisplay = deadline;
                    thisTask.deadlineDisplay = moment(deadline).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                }
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
                handleClose();
            }).catch((error)=>{
                reportError('EditTaskDeadline 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Save Task');
            })
        }).catch((error)=>{
            reportError('EditTaskDeadline 2', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Save Task');
        })
    }

    const DeadlineDisplay = () => {
        const today = new Date();

        if (props.completeFilter) {
            return <>
                <div>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{props.cell}</span>
                </div>
            </>
        } else if (props.row.values.deadlineValueDisplay < today) {
            return <>
                <div onDoubleClick={handleShow}>
                    <span style={{color:'var(--danger)', fontSize:'1em'}}>{props.cell}</span> <i style={{color:'var(--danger)'}} className="fas fa-exclamation"></i>
                </div>
            </>
        } else {
            return <>
                <div onDoubleClick={handleShow}>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{props.cell}</span>
                </div>
            </>
        }
    }

    return <>
        <DeadlineDisplay />
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{action}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Deadline</Form.Label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy h:mm aa"
                                className='form-control'
                                selected={deadline}
                                showTimeSelect
                                // minDate={new Date()}
                                todayButton="Today"
                                onChange={(date)=> setDeadline(date)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
