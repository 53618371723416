import React, { useState, useEffect, useReducer} from 'react';
import { Table, Container, Row, Col} from 'react-bootstrap';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table'

import history from '../../utils/History';

export default function Drafting() {

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);

    useEffect(() => {
        queryAdmin(); 
    },[])

    const queryAdmin = () => {
        if (currentUser.id === 'BzQyAMBIEF') {
            setUserIsSuperAdmin(true);
        } else {
            history.push('/');
            history.go(0);
        }
    }



    

    // const [isAdminLoaded, setIsAdminLoaded] = useState(false);

    // const [tasks, taskDispatch] = useReducer(tasksReducer, []);
    // const [isTasksLoaded, setIsTasksLoaded] = useState(false);
    // const [taskSubOn, setTaskSubOn] = useState(false);

    // const [projects, projectDispatch] = useReducer(projectsReducer, []);
    // const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    // const [projectSubOn, setProjectSubOn] = useState(false);

    // useEffect(() => {
    //     document.title = "Draft Zone";
    // }, []);

    // function tasksReducer (tasks, action) {
    //     switch (action.type) {
    //         case 'load': {
    //             return action.data;
    //         }
    //         case 'create': {
    //             var newArr = [...tasks, action.task];
    //             newArr.sort(
    //                 (t1, t2) => (t1.attributes.deadline > t2.attributes.deadline) ? 1 : (t1.attributes.deadline < t2.attributes.deadline) ? -1 : 0);
    //             return newArr;
    //         }
    //         case 'update': {
    //             var newArr = tasks.map((t) => {
    //                 if (t.id === action.id) {
    //                     return action.task;
    //                 } else {
    //                     return t;
    //                 }
    //             });
    //             newArr.sort(
    //                 (t1, t2) => (t1.attributes.deadline > t2.attributes.deadline) ? 1 : (t1.attributes.deadline < t2.attributes.deadline) ? -1 : 0);
    //             return newArr;
    //         }
    //         case 'delete': {
    //             return tasks.filter((t) => t.id !== action.id);
    //         }
    //         default: {
    //             throw Error('Unknown action: ' + action.type);
    //         }
    //     }
    // }

    // function projectsReducer (projects, action) {
    //     switch (action.type) {
    //         case 'load': {
    //             return action.data;
    //         }
    //         case 'create': {
    //             var newArr = [...projects, action.project];
    //             newArr.sort(
    //                 (p1, p2) => (p1.attributes.projectId > p2.attributes.projectId) ? 1 : (p1.attributes.projectId < p2.attributes.projectId) ? -1 : 0);
    //             return newArr;
    //         }
    //         case 'update': {
    //             var newArr = projects.map((t) => {
    //                 if (t.id === action.id) {
    //                     return action.project;
    //                 } else {
    //                     return t;
    //                 }
    //             });
    //             newArr.sort(
    //                 (p1, p2) => (p1.attributes.projectId > p2.attributes.projectId) ? 1 : (p1.attributes.projectId < p2.attributes.projectId) ? -1 : 0);
    //             return newArr;
    //         }
    //         case 'delete': {
    //             return projects.filter((t) => t.id !== action.id);
    //         }
    //         default: {
    //             throw Error('Unknown action: ' + action.type);
    //         }
    //     }
    // }
      
    // useEffect(() => {
    //     const handleTabClose = event => {
    //         Parse.LiveQuery.close();
    //         event.preventDefault();
    //         return (event.returnValue = 'Are you sure you want to exit?');
    //     };

    //     window.addEventListener('beforeunload', handleTabClose);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleTabClose);
    //     };
    // }, []);

    // const queryTasks = () => {

    //     const Task = Parse.Object.extend('Task');

    //     const query = new Parse.Query(Task);
    //     query.equalTo('assignedTo', currentUser);
    //     query.equalTo('complete', false);
    //     query.limit(999999);
    //     query.includeAll();
    //     query.ascending('deadline');
        
    //     query.find()
    //     .then(function(res) {
    //         taskDispatch({
    //             type: 'load',
    //             data: res,
    //         });
    //         setIsTasksLoaded(true);
    //     }, (error) => {
    //         alert(error);
    //     });

    //     query.subscribe().then(subscription => {
    //         subscription.on('open', () => {
    //             setTaskSubOn(true);
    //         });

    //         subscription.on('create', (task) => {
    //             taskDispatch({
    //                 type: 'create',
    //                 task: task,
    //             });
    //         });

    //         subscription.on('enter', (task) => {
    //             taskDispatch({
    //                 type: 'create',
    //                 task: task,
    //             });
    //         });
    
    //         subscription.on('update', (task) => {
    //             taskDispatch({
    //                 type: 'update',
    //                 id: task.id,
    //                 task: task,
    //             });
    //         });
    
    //         subscription.on('delete', (task) => {
    //             taskDispatch({
    //                 type: 'delete',
    //                 id: task.id,
    //                 task: task,
    //             });
    //         });

    //         subscription.on('leave', (task) => {
    //             taskDispatch({
    //                 type: 'delete',
    //                 id: task.id,
    //                 task: task,
    //             });
    //         });

    //         subscription.on('close', () => {
    //             setTaskSubOn(false);
    //         });
    //     });
    // }

    // const queryProjects = () => {
    //     const Project = Parse.Object.extend('Project');
    //     const query = new Parse.Query(Project);
    //     query.equalTo('managerId', currentUser);
    //     query.limit(999999);
    //     query.includeAll();
    //     query.ascending('projectId');
    //     query.find()
    //     .then(function(res) {
    //         projectDispatch({
    //             type: 'load',
    //             data: res,
    //         });
    //         setIsProjectsLoaded(true);
    //     }, (error) => {
    //         alert(error);
    //     });

    //     query.subscribe().then(subscription => {
    //         subscription.on('open', () => {
    //             setProjectSubOn(true);
    //         });

    //         subscription.on('create', (project) => {
    //             projectDispatch({
    //                 type: 'create',
    //                 project: project,
    //             });
    //         });

    //         subscription.on('enter', (project) => {
    //             projectDispatch({
    //                 type: 'create',
    //                 project: project,
    //             });
    //         });
    
    //         subscription.on('update', (project) => {
    //             projectDispatch({
    //                 type: 'update',
    //                 id: project.id,
    //                 project: project,
    //             });
    //         });
    
    //         subscription.on('delete', (project) => {
    //             projectDispatch({
    //                 type: 'delete',
    //                 id: project.id,
    //                 project: project,
    //             });
    //         });

    //         subscription.on('leave', (project) => {
    //             projectDispatch({
    //                 type: 'delete',
    //                 id: project.id,
    //                 project: project,
    //             });
    //         });

    //         subscription.on('close', () => {
    //             setProjectSubOn(false);
    //         });
    //     });
    // }

    // const getTaskData = () => {

    //     var data2 = [];

    //     for (var i = 0; i < tasks.length; i++) {
    //         const task = tasks[i];
    //         var actionString = task.get('action');

    //         data2.push({
    //             id: i,
    //             actionDisplay: actionString,
    //         })
    //     }

    //     return data2;
    // }

    // const [taskData, setTaskData] = React.useState(React.useMemo(() => getTaskData, [isTasksLoaded]));
    // useEffect(() => {
    //     if (tasks.length > 0) {
    //         setTaskData(getTaskData);
    //     }
    // },[tasks]);

    // const taskCols = React.useMemo(
    //     () => [
    //         {
    //             Header: 'Task',
    //             accessor: 'actionDisplay',
    //         },
    //     ],
    //     [taskData]
    // );

    // const TaskTableLive = () => {
    //     return <>
    //         <TableView columns={taskCols} data={taskData}/>
    //     </>    
    // }

    // const getProjectData = () => {

    //     var data2 = [];

    //     for (var i = 0; i < projects.length; i++) {
    //         const project = projects[i];
    //         var nameString = project.get('name');

    //         data2.push({
    //             id: i,
    //             nameDisplay: nameString,
    //         })
    //     }

    //     return data2;
    // }

    // const [projectData, setProjectData] = React.useState(React.useMemo(() => getTaskData, [isProjectsLoaded]));
    // useEffect(() => {
    //     if (projects.length > 0) {
    //         setProjectData(getProjectData);
    //     }
    // },[projects]);

    // const projectCols = React.useMemo(
    //     () => [
    //         {
    //             Header: 'Name',
    //             accessor: 'nameDisplay',
    //         },
    //     ],
    //     [projectData]
    // );

    // const ProjectTableLive = () => {
    //     return <>
    //         <TableView columns={projectCols} data={projectData}/>
    //     </>    
    // }

    // function TableView ({ columns, data }) {

    //     const {
    //         getTableProps,
    //         getTableBodyProps,
    //         headerGroups,
    //         prepareRow,
    //         page,
    //         canPreviousPage,
    //         canNextPage,
    //         pageOptions,
    //         pageCount,
    //         gotoPage,
    //         nextPage,
    //         previousPage,
    //         setPageSize,
    //         state: { pageIndex, pageSize },
    //         state,
    //     } = useTable(
    //         {
    //             columns,
    //             data,
    //             initialState: {
    //                 pageIndex: 0,
    //                 pageSize: 10000,
    //                 hiddenColumns: columns.map(column => {
    //                     if (column.show === false) {
    //                         return column.accessor || column.id;
    //                     }
    //                 }),
    //             },            
    //         },
    //         useFilters,
    //         useSortBy,
    //         usePagination
    //     )

    //     return (
    //         <Container>
    //             <Table {...getTableProps()}>
                    
    //                 <thead>
    //                     {headerGroups.map(headerGroup => (
    //                     <tr {...headerGroup.getHeaderGroupProps()}>
    //                         {headerGroup.headers.map(column => (
    //                         <th {...column.getHeaderProps(column.getSortByToggleProps())}>
    //                             {column.render('Header')}
    //                             <span>
    //                                 {column.isSorted
    //                                 ? column.isSortedDesc
    //                                     ? <i className="fas fa-sort-down"></i>
    //                                     : <i className="fas fa-sort-up"></i>
    //                                 : ''}
    //                             </span>
    //                         </th>
    //                         ))}
    //                     </tr>
    //                     ))}
    //                 </thead>
    //                 <tbody {...getTableBodyProps()}>
    //                     {page.map((row, i) => {
    //                         prepareRow(row)
    //                         return (
    //                         <tr {...row.getRowProps()}>
    //                             {row.cells.map(cell => {
    //                             return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    //                             })}
    //                         </tr>
    //                         )
    //                     })}
    //                 </tbody>
    //             </Table>
    //         </Container>
    //     )
    // }

    return <>
        {userIsSuperAdmin && (
            <Container>
                <div className="home-header">
                    <h2>Drafting Zone</h2>
                </div>
                <hr/>
                <p>
                    Page for drafting and testing new components on a blank canvas
                </p>
                <hr/>
                {/* <h4>My Tasks</h4>
                {taskSubOn && (
                    <span>
                        Task list updating live
                    </span>
                )}
                {!taskSubOn && (
                    <span>
                        No subscription to task database
                    </span>
                )}
                <hr/>
                <TaskTableLive />
                <hr/>
                <h4>My Projects</h4>
                {projectSubOn && (
                    <span>
                        Project list updating live
                    </span>
                )}
                {!projectSubOn && (
                    <span>
                        No subscription to project database
                    </span>
                )}
                <hr/>
                <ProjectTableLive />
                <hr/> */}
            </Container>
        )}
    </>
}
