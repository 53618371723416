import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import reportError from '../../utils/ReportError';


export default function EditProjectComment(props) {

    const [show, setShow] = useState(false);
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [comment, setComment] = useState('');
    const [submitString, setSubmitString] = useState('Save Project');

    const handleClose = () =>  {
        setComment('');
        setProjectDisplayString('');
        setSubmitString('Save Project');
        setShow(false);
    }

    const handleShow = () => {
        if (props.row.values.commentDisplay) {
            setComment(props.row.values.commentDisplay);
        }
        setProjectDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('comment', comment);
            project.save()
            .then((res2)=>{
                if (!props.fromDashboard) {
                    const dataCopy = props.projectData;
                    var thisProj = dataCopy[props.row.index];
                    if (comment) {
                        thisProj.commentDisplay = comment;
                    }
                    dataCopy[props.row.index] = thisProj;
                    props.setProjectData(dataCopy);
                }
                handleClose();
            }).catch((error)=>{
                reportError('EditProjectComment 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            reportError('EditProjectComment 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Save Project');
        })
    }

    return <>
        {!props.fromDashboard && (
            <div onDoubleClick={handleShow} >
                <span style={{color:'var(--text)', fontSize:'1em'}}>{props.cell}</span>
            </div>
        )}
        {props.fromDashboard && (
            <div onDoubleClick={handleShow} >
                <span style={{color:'var(--text)', fontSize:'0.8em'}}>{props.cell}</span>
            </div>
        )}
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'> 
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
