import { map } from 'jquery';
import React, { useState, useEffect} from 'react';
import { Table, Breadcrumb, Button, Container, Badge, Row, Col } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import reportError from '../../utils/ReportError';


export default function Staff() {

    const Parse = require('parse');

    const [staff, setStaff] = useState([]);
    const [isStaffLoaded, setIsStaffLoaded] = useState(false);
    const [isStaffTableLoaded, setIsStaffTableLoaded] = useState(false);

    useEffect(() => {
        document.title = "Riskcon Team";
        if (!isStaffLoaded) {
            queryStaff();
        }
    },[]);

    const queryStaff = () => {
        const staffQuery = new Parse.Query(Parse.Role);
        staffQuery.equalTo('name', 'staff');
        staffQuery.first()
        .then(function(staffQueryRes) {
            const staff = staffQueryRes.getUsers().query();
            staff.equalTo('status', 'Active');
            staff.ascending('firstName');
            staff.find()
            .then((userQueryRes) => {
                setStaff(userQueryRes);
                setIsStaffLoaded(true);
            }, (error) => {
                reportError('Staff 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('Staff 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const getStaffData = () => {

        var data2 = [];

        for (var i = 0; i < staff.length; i++) {
            const user = staff[i];

            if (user.id !== 'BzQyAMBIEF') {
                data2.push({
                    id: i,
                    // idDisplay: user.id,
                    nameDisplay: user.get('firstName') + ' ' + user.get('lastName'),
                    emailDisplay: user.get('username'),
                })
            }
        }

        return data2;
    }

    const [staffData, setStaffData] = React.useState(React.useMemo(() => getStaffData, [isStaffLoaded]));
    if (!isStaffTableLoaded) {
        if (staff.length > 0) {
            setStaffData(getStaffData);
            setIsStaffTableLoaded(true);
        }
    }

    const staffCols = React.useMemo(
        () => [
            // {
            //     Header: 'User Id',
            //     accessor: 'idDisplay',
            //     show: false,
            // },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Name</span>,
                accessor: 'nameDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Email</span>,
                accessor: 'emailDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
        ],
        [staffData]
    );
    
    const StaffTable = () => {
        if(isStaffLoaded) {
            return <>
                <TableView columns={staffCols} data={staffData} tableType='staff'/>
            </>    
        } else if (!isStaffLoaded) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading Riskcon Team...</em></td></tr></tbody></Table>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}>No team members found</td></tr></tbody></Table>
        }
    }

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)
      
        return (
            <span>
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function TableView ({ columns, data, tableType }) {

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
            state,
            // setFilter,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: {
                    pageIndex: 0,
                    pageSize: 10000,
                    hiddenColumns: columns.map(column => {
                        if (column.show === false) {
                            return column.accessor || column.id;
                        }
                    }),
                },            
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        return (
            <Container>

                <div>&nbsp;</div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <div>&nbsp;</div>

                <Table {...getTableProps()}>
                    
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {/* <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                </div> */}
            </Container>
        )
    }
    return <>
        {/* <Container>
            <Breadcrumb className='container'>
                <Breadcrumb.Item href='/staff'>Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Lote Team</Breadcrumb.Item>
            </Breadcrumb>
        </Container> */}
        <Container>
            <div className="home-header">
                <h3>Riskcon Team</h3>
            </div>
            <hr/>
            <div className="table-homepage">
                <div>
                    <StaffTable />
                </div>
            </div>
        </Container>
    </>
}
