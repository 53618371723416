import { Button} from 'react-bootstrap';
import reportError from '../../utils/ReportError';


export default function StarTask(props) {

    const Parse = require('parse');

    const starThisTask = () => {
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            task.set('star', true);
            task.save()
            .then((res)=>{
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                thisTask.starDisplay = 'true';
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
            }).catch((error)=>{
                reportError('StarTask 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }).catch((error)=>{
            reportError('StarTask 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        })
    }

    const unstarThisTask = () => {
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            task.set('star', false);
            task.save()
            .then((res)=>{
                const dataCopy = props.taskData;
                var thisTask = dataCopy[props.row.index];
                thisTask.starDisplay = 'false';
                dataCopy[props.row.index] = thisTask;
                props.setTaskData(dataCopy);
            }).catch((error)=>{
                reportError('StarTask 3', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }).catch((error)=>{
            reportError('StarTask 4', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        })
    }

    if (props.row.values.isComplete === 'true') {
        return null;
    } else if (props.row.values.starDisplay === 'true') {
        return <>
            <Button variant='link' className="star" size="sm" onClick={unstarThisTask}>
                <i className="fas fa-star"></i>
            </Button>
        </>
    } else {
        return <>
            <Button variant='link' className="star" size="sm" onClick={starThisTask}>
                <i className="far fa-star"></i>
            </Button>
        </>
    }
}
