import { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { customStyles } from '../../data/Data.js'
import reportError from '../../utils/ReportError';



export default function AddClient(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [creatingOrg, setCreatingOrg] = useState(true);
    const [organisation, setOrganisation] = useState(null);
    const [newOrg, setNewOrg] = useState('');

    const [submitString, setSubmitString] = useState('Create Client');

    useEffect(()=> {
        if (organisation !== null) {
            (!firstName || !lastName || !email || !organisation) ? setIsDisabled(true) : setIsDisabled(false)
        } else {
            (!firstName || !lastName || !email || !newOrg) ? setIsDisabled(true) : setIsDisabled(false)
        }
    }, [firstName, lastName, email, organisation, newOrg]);

    const handleClose = () =>  {
        setShow(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setOrganisation(null);
        setNewOrg('');
        setCreatingOrg(true);
        setSubmitString('Create Client');
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        if (creatingOrg) {
            var newOrgName = newOrg;
            const Organisation = Parse.Object.extend('Organisation');
            const query = new Parse.Query(Organisation);
            query.equalTo('name', newOrgName);
            query.find()
            .then(function(res) {
                if (res.length < 1) {
                    const newOrg = new Organisation();
                    newOrg.set('name', newOrgName);
                    var orgACL = new Parse.ACL();
                    orgACL.setRoleReadAccess('staff', true);
                    orgACL.setRoleWriteAccess('staff', true);
                    newOrg.setACL(orgACL);
    
                    newOrg.save().then((orgRes) => {
                        createUserFunction(orgRes, newOrgName);
                    }).catch((error)=> {
                        reportError('AddClient 1', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setSubmitString('Create Client');
                    });
                } else {
                    alert('Error: An organisation with this name already exists.');
                    setSubmitString('Create Client');
                }
            }, (error) => {
                reportError('AddClient 2', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Create Client');
            });
        } else {
            createUserFunction(null, null);
        }
    }

    const createUserFunction = (orgRes, newOrgName) => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.equalTo('email', email);
        query.find()
        .then(function(res) {
            if (res.length < 1) {

                var clientRole;

                const queryRole = new Parse.Query(Parse.Role);
                queryRole.equalTo('name','client').first().then(clientRoleRes => { 
                    clientRole = clientRoleRes;
                }, (error) => {
                    reportError('AddClient 3', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                });

                const user = new User();
                user.set('firstName', firstName);
                user.set('lastName', lastName);
                user.set('username', email);
                user.set('email', email);
                user.set('slimView', false);
                user.set('darkMode', false);
                user.set('status', 'Inactive');

                if (creatingOrg) {
                    user.set('organisation', orgRes);
                } else {
                    user.set('organisation', organisation.object);
                }

                var userACL = new Parse.ACL();
                userACL.setRoleReadAccess('staff', true);
                userACL.setRoleWriteAccess('staff', true);
                user.setACL(userACL);

                const password = Math.random().toString(36) + Math.random().toString(36).toUpperCase();
                user.set('password', password);

                user.save().then((clientRes) => {

                    if (orgRes !== null) {
                        var orgAcl = new Parse.ACL();
                        orgAcl.setReadAccess(clientRes, true);
                        orgAcl.setWriteAccess(clientRes, false);
                        orgAcl.setRoleReadAccess('staff', true);
                        orgAcl.setRoleWriteAccess('staff', true);
                        orgRes.setACL(orgAcl);
                        orgRes.save().catch((error)=>{
                            reportError('AddClient 4', error.message);
                            alert('Error: Please refresh the page and try again. An error report has been sent.');
                            setSubmitString('Create Client');
                        });
                    }
                    
                    clientRole.relation('users').add(clientRes);
                    clientRole.save().then((clientRoleRes) => {
                        if (creatingOrg) {
                            handleAddOrgDisplay(orgRes);
                        }
                        handleAddUserDisplay(clientRes, orgRes, newOrgName);
                    }).catch((error)=>{
                        reportError('AddClient 5', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setSubmitString('Create Client');
                    });
                }).catch((error)=> {
                    reportError('AddClient 6', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setSubmitString('Create Client');
                });
            } else {
                if (creatingOrg) {
                    orgRes.destroy().then((myObject) => {
                        setSubmitString('Create Client');
                    }, (error) => {
                        reportError('AddClient 7', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setSubmitString('Create Client');
                    });
                }
                alert('Error: A user with this email address already exists.');
                setSubmitString('Create Client');
            }
        }, (error) => {
            reportError('AddClient 8', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Create Client');
        });
    }

    const handleAddOrgDisplay = (orgRes, newOrgName) => {
        const dataCopy = props.orgData;
        dataCopy.unshift({
            id: 0,
            idDisplay: orgRes.id,
            nameDisplay: newOrg,
        });
        props.setOrgData(dataCopy);
    }

    const handleAddUserDisplay = (clientRes, orgRes, newOrgName) => {
        var orgIdDisp, orgNameDisp;
        if (orgRes !== null) {
            orgIdDisp = orgRes.id;
            orgNameDisp = newOrgName;
        } else {
            orgIdDisp = organisation.value;
            orgNameDisp = organisation.label;
        }
        const dataCopy = props.userData;
        dataCopy.unshift({
            id: 0,
            idDisplay: clientRes.id,
            firstNameDisplay: firstName,
            lastNameDisplay: lastName,
            nameDisplay: firstName + ' ' + lastName,
            emailDisplay: email,
            orgIdDisplay: orgIdDisp,
            organisationDisplay: orgNameDisp,
            verifiedDisplay: <i className="fas fa-lock" style={{color: '#990000'}}></i>,

        });
        props.setUserData(dataCopy);
        handleClose();
    }

    const handleTypeNewOrg = (textTyped) => {
        setNewOrg(textTyped);
        setOrganisation(null);
        setCreatingOrg(true);
    }

    const handleOrgSelect = (selectedOrgOption) => {
        setNewOrg('');
        setOrganisation(selectedOrgOption);
        setCreatingOrg(false);
    }

    return <>
        <Button onClick={handleShow}> 
            Add Client
            {/* <i className="fas fa-plus"></i> */}
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Add Client</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                id="new-client-first-name"
                                type="text"
                                className="mb-2"
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                id="new-client-last-name"
                                type="text"
                                className="mb-2"
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                            /> 
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                id="new-client-email"
                                type="email"
                                className="mb-2"
                                placeholder="name@example.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Organisation</Form.Label>
                            <Form.Control
                                id="org-input"
                                type="text"
                                className="mb-2"
                                placeholder="New org name"
                                value={newOrg}
                                onChange={(e) => handleTypeNewOrg(e.target.value)}
                            /> 
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Existing org</Form.Label>
                            <Select 
                                styles={customStyles}
                                options={props.orgOptions}
                                onChange={(e)=>handleOrgSelect(e)}
                                value={organisation}
                                defaultValue={organisation}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
