import { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Form } from 'react-bootstrap';
import RecurringHistory from './RecurringHistory';
import moment from 'moment';
import 'moment/locale/en-au';
import axios from 'axios';
import reportError from '../../utils/ReportError';


export default function CompleteTask(props) {

    const [show, setShow] = useState(false);
    const [showPerm, setShowPerm] = useState(false);

    // const [hasChecklist, setHasChecklist] = useState(false);
    // const [listLength, setListLength] = useState(0);
    // const [listArray, setListArray] = useState();
    // const [checkArray, setCheckArray] = useState();
    // const [show2, setShow2] = useState(false);
    // const [show3, setShow3] = useState(false);
    // const [show4, setShow4] = useState(false);
    // const [show5, setShow5] = useState(false);
    // const [show6, setShow6] = useState(false);
    // const [show7, setShow7] = useState(false);
    // const [c1, setc1] = useState(false);
    // const [c2, setc2] = useState(false);
    // const [c3, setc3] = useState(false);
    // const [c4, setc4] = useState(false);
    // const [c5, setc5] = useState(false);
    // const [c6, setc6] = useState(false);
    // const [c7, setc7] = useState(false);

    const [taskDisplayString, setTaskDisplayString] = useState('');
    const [projDisplayString, setProjDisplayString] = useState('');
    const [submitString, setSubmitString] = useState('Mark Complete');
    const [permSubmitString, setPermSubmitString] = useState('Permanently Complete');
    // const [isDisabled, setIsDisabled] = useState(true);


    const Parse = require('parse');

    const handleClose = () =>  {
        // if (hasChecklist && isDisabled) {
        //     const Parse = require('parse');
        //     const query = new Parse.Query(Parse.Object.extend('Task'));
        //     query.get(props.row.values.idDisplay)
        //     .then((task) => {
        //         var newCheckArray = [];
        //         switch (listLength) {
        //             case 1:
        //                 newCheckArray = [c1];
        //                 break;
        //             case 2:
        //                 newCheckArray = [c1, c2];
        //                 break;
        //             case 3:
        //                 newCheckArray = [c1, c2, c3];
        //                 break;
        //             case 4:
        //                 newCheckArray = [c1, c2, c3, c4];
        //                 break;
        //             case 5:
        //                 newCheckArray = [c1, c2, c3, c4, c5];
        //                 break;
        //             case 6:
        //                 newCheckArray = [c1, c2, c3, c4, c5, c6];
        //                 break;
        //             case 7:
        //                 newCheckArray = [c1, c2, c3, c4, c5, c6, c7];
        //                 break;
        //             default:
        //                 break;
        //         }
        //         task.set('check', newCheckArray);
                
        //         task.save()
        //         .then((res)=>{
        //             const dataCopy = props.taskData;
        //             var thisTask = dataCopy[props.row.index];
        //             thisTask.checkArrayDisplay = newCheckArray.toString();
        //             dataCopy[props.row.index] = thisTask;
        //             props.setTaskData(dataCopy);
        //             setTaskDisplayString('');
        //             setProjDisplayString('');
        //             setHasChecklist(false);
        //             setListLength(0);
        //             setListArray('');
        //             setCheckArray('');
        //             setShow2(false);
        //             setShow3(false);
        //             setShow4(false);
        //             setShow5(false);
        //             setShow6(false);
        //             setShow7(false);
        //             setc1(false);
        //             setc2(false);
        //             setc3(false);
        //             setc4(false);
        //             setc5(false);
        //             setc6(false);
        //             setc7(false);
        //             setShow(false);
        //             setSubmitString('Mark Complete');
        //             setPermSubmitString('Permanently Complete');
        //         }).catch((error)=>{
            // reportError('CompleteTask 1', error.message);
            // alert('Error: Please refresh the page and try again. An error report has been sent.');
        //             setSubmitString('Mark Complete');
        //         })
        //     }).catch((error)=>{
            // reportError('CompleteTask 2', error.message);
            // alert('Error: Please refresh the page and try again. An error report has been sent.');
        //         setSubmitString('Mark Complete');
        //     })
        // } else {
            setTaskDisplayString('');
            setProjDisplayString('');
            // setHasChecklist(false);
            // setListLength(0);
            // setListArray('');
            // setCheckArray('');
            // setShow2(false);
            // setShow3(false);
            // setShow4(false);
            // setShow5(false);
            // setShow6(false);
            // setShow7(false);
            // setc1(false);
            // setc2(false);
            // setc3(false);
            // setc4(false);
            // setc5(false);
            // setc6(false);
            // setc7(false);
            setShow(false);
            setSubmitString('Mark Complete');
            setPermSubmitString('Permanently Complete');
        // }
    }

    const handleShow = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        var taskString = '';
        var projString = '';
        taskString += props.row.values.actionTextDisplay;
        if (props.row.values.projStringDisplay.length > 0) {
            projString += props.row.values.projStringDisplay;
        }
        setTaskDisplayString(taskString);
        setProjDisplayString(projString);
        // if (props.row.values.hasChecklistDisplay === 'true') {
        //     setHasChecklist(true);
        //     var ca2 = props.row.values.checkArrayDisplay.split(",");
        //     setCheckArray(ca2);
            
        //     if ((listArray === undefined) || (listArray === '')) {
        //         var ids = props.row.values.checklistDisplay;
        //         var obj = props.checklists.find((element) => element.id === ids)
        //         setListArray(obj.attributes.list);
        //         setListLength(obj.attributes.list.length);
        //         switch (obj.attributes.list.length) {
        //             case 1:
        //                 setShow2(false);
        //                 setShow3(false);
        //                 setShow4(false);
        //                 setShow5(false);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 break;
        //             case 2:
        //                 setShow2(true);
        //                 setShow3(false);
        //                 setShow4(false);
        //                 setShow5(false);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 break;
        //             case 3:
        //                 setShow2(true);
        //                 setShow3(true);
        //                 setShow4(false);
        //                 setShow5(false);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 break;
        //             case 4:
        //                 setShow2(true);
        //                 setShow3(true);
        //                 setShow4(true);
        //                 setShow5(false);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 break;
        //             case 5:
        //                 setShow2(true);
        //                 setShow3(true);
        //                 setShow4(true);
        //                 setShow5(true);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 break;
        //             case 6:
        //                 setShow2(true);
        //                 setShow3(true);
        //                 setShow4(true);
        //                 setShow5(true);
        //                 setShow6(true);
        //                 setShow7(false);
        //                 break;
        //             case 7:
        //                 setShow2(true);
        //                 setShow3(true);
        //                 setShow4(true);
        //                 setShow5(true);
        //                 setShow6(true);
        //                 setShow7(true);
        //                 break;
        //             default:
        //                 setShow2(false);
        //                 setShow3(false);
        //                 setShow4(false);
        //                 setShow5(false);
        //                 setShow6(false);
        //                 setShow7(false);
        //                 setShow(false);
        //                 break;
        //         }
        //     }
        // }
        setShow(true);
    }

    // useEffect(()=> {
    //     if (!hasChecklist) {
    //         setIsDisabled(false);
    //     } else {
    //         if (listLength === 1) {
    //             (!c1) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 2) {
    //             (!c1 || !c2) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 3) {
    //             (!c1 || !c2 || !c3) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 4) {
    //             (!c1 || !c2 || !c3 || !c4) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 5) {
    //             (!c1 || !c2 || !c3 || !c4 || !c5) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 6) {
    //             (!c1 || !c2 || !c3 || !c4 || !c5 || !c6) ? setIsDisabled(true) : setIsDisabled(false)
    //         } else if (listLength === 7) {
    //             (!c1 || !c2 || !c3 || !c4 || !c5 || !c6) || !c7 ? setIsDisabled(true) : setIsDisabled(false)
    //         }
    //     }
    // }, [c1, c2, c3, c4, c5, c6, c7, listLength, hasChecklist]);

    // useEffect(()=>{
    //     if ((checkArray !== undefined) && (checkArray.length > 0)) {
    //         switch (checkArray.length) {
    //             case 1:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 break;
    //             case 2:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 break;
    //             case 3:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 if (checkArray[2] === 'true') {
    //                     setc3(true);
    //                 }
    //                 break;
    //             case 4:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 if (checkArray[2] === 'true') {
    //                     setc3(true);
    //                 }
    //                 if (checkArray[3] === 'true') {
    //                     setc4(true);
    //                 }
    //                 break;
    //             case 5:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 if (checkArray[2] === 'true') {
    //                     setc3(true);
    //                 }
    //                 if (checkArray[3] === 'true') {
    //                     setc4(true);
    //                 }
    //                 if (checkArray[4] === 'true') {
    //                     setc5(true);
    //                 }
    //                 break;
    //             case 6:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 if (checkArray[2] === 'true') {
    //                     setc3(true);
    //                 }
    //                 if (checkArray[3] === 'true') {
    //                     setc4(true);
    //                 }
    //                 if (checkArray[4] === 'true') {
    //                     setc5(true);
    //                 }
    //                 if (checkArray[5] === 'true') {
    //                     setc6(true);
    //                 }
    //                 break;
    //             case 7:
    //                 if (checkArray[0] === 'true') {
    //                     setc1(true);
    //                 }
    //                 if (checkArray[1] === 'true') {
    //                     setc2(true);
    //                 }
    //                 if (checkArray[2] === 'true') {
    //                     setc3(true);
    //                 }
    //                 if (checkArray[3] === 'true') {
    //                     setc4(true);
    //                 }
    //                 if (checkArray[4] === 'true') {
    //                     setc5(true);
    //                 }
    //                 if (checkArray[5] === 'true') {
    //                     setc6(true);
    //                 }
    //                 if (checkArray[6] === 'true') {
    //                     setc7(true);
    //                 }
    //                 break; 
    //             default:
                    
    //                 break;
    //         }
    //     }
    // }, [checkArray])

    const handlePermClose = () =>  {
        setShowPerm(false);
        setShow(true);
    }

    const handlePermTrigger = () => {
        setShowPerm(true);
    }

    const handleComplete = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            // var newCheckArray = [];

            // task.set('flag', false);
            task.set('star', false);
            task.set('complete', true);
            var today = new Date();
            task.set('completedOn', today);
            // if (hasChecklist) {
            //     switch (listLength) {
            //         case 1:
            //             newCheckArray = [c1];
            //             break;
            //         case 2:
            //             newCheckArray = [c1, c2];
            //             break;
            //         case 3:
            //             newCheckArray = [c1, c2, c3];
            //             break;
            //         case 4:
            //             newCheckArray = [c1, c2, c3, c4];
            //             break;
            //         case 5:
            //             newCheckArray = [c1, c2, c3, c4, c5];
            //             break;
            //         case 6:
            //             newCheckArray = [c1, c2, c3, c4, c5, c6];
            //             break;
            //         case 7:
            //             newCheckArray = [c1, c2, c3, c4, c5, c6, c7];
            //             break;
            //         default:
            //             break;
            //     }
            //     task.set('check', newCheckArray);
            // }
            task.save()
            .then((res)=>{
                if (!props.fromDashboard) {
                    const dataCopy = props.taskData;
                    var thisTask = dataCopy[props.row.index];
                    thisTask.isComplete = 'true';
                    thisTask.completeValueDisplay = today;
                    thisTask.completeDisplay = moment(today).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                    // if (hasChecklist) {
                    //     thisTask.checkArrayDisplay = newCheckArray.toString();
                    // }
                    dataCopy[props.row.index] = thisTask;
                    props.setTaskData(dataCopy);
                }
                // if (res.get('createdBy').id !== res.get('assignedTo').id) {
                //     var staffObject = res.get('createdBy');
                //     var completerObject = res.get('assignedTo');
                //     if ((staffObject.attributes.slackNotifHook !== undefined)) {
                //         const url = staffObject.attributes.slackNotifHook;
                //         var userString = '';
                //         if (staffObject.attributes.slackUserId.length > 0) {
                //             userString = '<@' + staffObject.attributes.slackUserId + '>';
                //         } else {
                //             userString = staffObject.attributes.firstName + ' ' + staffObject.attributes.lastName;
                //         }
                //         var taskString = taskDisplayString + '\n' + projDisplayString;
                //         const data = { text: 'Hi '+ userString + ' ' + 
                //         completerObject.attributes.firstName + ' ' + completerObject.attributes.lastName + 
                //             ' has completed a task you assigned:\n' + taskString};
    
                //         axios.post(url, JSON.stringify(data), {
                //             withCredentials: false,
                //             transformRequest: [
                //                 (data, headers) => {
                //                     return data;
                //                 },
                //             ],
                //         });
                //     }
                // }
                // if (res.has('project')) {
                //     if (res.get('project').id === 'pnvbSttUiz') {
                //         window.open('https://forms.office.com/r/4c7mdKYTNG','_newtab' + Date.now());
                //     }
                // }
                handleClose();
                props.handleCelebrate();
            }).catch((error)=>{
                reportError('CompleteTask 3', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Mark Complete');
            })
        }).catch((error)=>{
            reportError('CompleteTask 4', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Mark Complete');
        })
    }

    const handleCompleteRecurring = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            var today = new Date();
            var recurringHistory = task.get('recurringOutcomes');
            if (!recurringHistory) {
                recurringHistory = [];
            }
            var newOutcome = {
                date: today
            };
            recurringHistory.push(newOutcome);
            task.set('recurringOutcomes', recurringHistory);
            task.set('recurringCompleteOnce', true);
            
            var oldDeadline = task.get('deadline');
            var m = moment(oldDeadline);
            var newDeadline;
            switch (task.get('recurringType')) {
                case 'day':
                    var weekDayTest = m.isoWeekday();
                    if (weekDayTest === 5) {
                        newDeadline = m.clone().add(3, 'days');
                    } else if (weekDayTest === 6) {
                        newDeadline = m.clone().add(2, 'days');
                    } else {
                        newDeadline = m.clone().add(1, 'days');
                    }
                    break;
                case 'week':
                    newDeadline = m.clone().add(7, 'days');
                    break;
                case 'fortnight':
                    newDeadline = m.clone().add(14, 'days');
                    break;
                case 'month':
                    newDeadline = m.clone().add(1, 'months');
                    break;
                case 'quarter':
                    newDeadline = m.clone().add(3, 'months');
                    break;
                case 'year':
                    newDeadline = m.clone().add(1, 'years');
                    break;
                default:
                    break;
            }
            task.set('deadline', newDeadline.toDate());
            task.save()
            .then((res)=>{
                if (!props.fromDashboard) {
                    const dataCopy = props.taskData;
                    var thisTask = dataCopy[props.row.index];
                    thisTask.completeValueDisplay = today;
                    thisTask.completeDisplay = moment(today).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                    thisTask.deadlineValueDisplay = newDeadline.toDate();
                    thisTask.deadlineDisplay = moment(newDeadline.toDate()).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                    dataCopy[props.row.index] = thisTask;
                    props.setTaskData(dataCopy);
                }
                // if (res.has('project')) {
                //     if (res.get('project').id === 'pnvbSttUiz') {
                //         window.open('https://forms.office.com/r/4c7mdKYTNG','_newtab' + Date.now());
                //     }
                // }
                handleClose();
                props.handleCelebrate();
            }).catch((error)=>{
                reportError('CompleteTask 5', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Mark Complete');
            })
        }).catch((error)=>{
            reportError('CompleteTask 6', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Mark Complete');
        })
    }

    const handlePermanentComplete = (e) => {
        e.preventDefault();
        setPermSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            var today = new Date();
            var recurringHistory = task.get('recurringOutcomes');
            if (!recurringHistory) {
                recurringHistory = [];
            }
            var newOutcome = {
                date: today
            };
            recurringHistory.push(newOutcome);
            task.set('recurringOutcomes', recurringHistory);
            task.set('recurringCompleteOnce', true);
            // task.set('flag', false);
            task.set('star', false);
            task.set('complete', true);
            task.set('completedOn', today);
            task.save()
            .then((res)=>{
                if (!props.fromDashboard) {
                    const dataCopy = props.taskData;
                    var thisTask = dataCopy[props.row.index];
                    thisTask.isComplete = 'true';
                    thisTask.completeValueDisplay = today;
                    thisTask.completeDisplay = moment(today).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM h:mm A',
                        sameElse: 'ddd DD MMM h:mm A' 
                    });
                    dataCopy[props.row.index] = thisTask;
                    props.setTaskData(dataCopy);
                }
                // if (res.has('project')) {
                //     if (res.get('project').id === 'pnvbSttUiz') {
                //         window.open('https://forms.office.com/r/4c7mdKYTNG','_newtab' + Date.now());
                //     }
                // }
                handleClose();
                props.handleCelebrate();
            }).catch((error)=>{
                reportError('CompleteTask 7', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setPermSubmitString('Permanently Complete');
            })
        }).catch((error)=>{
            reportError('CompleteTask 8', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setPermSubmitString('Permanently Complete');
        })
    }

    if (props.row.values.isComplete === 'true') {
        return null;
    } else if (props.row.values.isRecurring) {
        return <>
            <Button variant='outline-success' className='square-button' onClick={handleShow}>
                <i className="fas fa-redo"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Complete Recurring Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {taskDisplayString}
                    &nbsp;
                    <RecurringHistory
                        row={props.row}
                        insideComplete={true}
                    />
                    <br></br>
                    {projDisplayString}
                    {/* {hasChecklist && (
                        // <span>
                        //     <span>{listArray.toString()}</span>
                        //     <span>{checkArray.toString()}</span>
                        // </span>
                        <Form.Group md="2" className="mb-3 mt-3">
                            <Form.Check
                                type='checkbox'
                                id='check1'
                                checked={c1}
                                onChange={(e) => setc1(e.target.checked)}
                                label={listArray[0]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show2) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check2'
                                checked={c2}
                                onChange={(e) => setc2(e.target.checked)}
                                label={listArray[1]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show3) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check3'
                                checked={c3}
                                onChange={(e) => setc3(e.target.checked)}
                                label={listArray[2]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show4) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check4'
                                checked={c4}
                                onChange={(e) => setc4(e.target.checked)}
                                label={listArray[3]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show5) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check5'
                                checked={c5}
                                onChange={(e) => setc5(e.target.checked)}
                                label={listArray[4]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show6) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check6'
                                checked={c6}
                                onChange={(e) => setc6(e.target.checked)}
                                label={listArray[5]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show7) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check7'
                                checked={c7}
                                onChange={(e) => setc7(e.target.checked)}
                                label={listArray[6]}
                            />
                        </Form.Group>
                    )} */}
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={handlePermTrigger} 
                        // disabled={isDisabled}
                    >
                        {permSubmitString}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleCompleteRecurring}
                        // disabled={isDisabled}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPerm} onHide={handlePermClose} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Permanently Complete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    Are you sure you want to mark task "{taskDisplayString}" as permanently completed?  
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handlePermClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePermanentComplete}>
                        {permSubmitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    } else {
        return <>
            <Button variant='outline-success' className='square-button' onClick={handleShow}>
                <i className="fas fa-check"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Complete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {taskDisplayString}
                    <br></br>
                    {projDisplayString}
                    {/* {hasChecklist && (
                        // <span>
                        //     <span>{listArray.toString()}</span>
                        //     <span>{checkArray.toString()}</span>
                        // </span>
                        <Form.Group md="2" className="mb-3 mt-3">
                            <Form.Check
                                type='checkbox'
                                id='check1'
                                checked={c1}
                                onChange={(e) => setc1(e.target.checked)}
                                label={listArray[0]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show2) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check2'
                                checked={c2}
                                onChange={(e) => setc2(e.target.checked)}
                                label={listArray[1]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show3) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check3'
                                checked={c3}
                                onChange={(e) => setc3(e.target.checked)}
                                label={listArray[2]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show4) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check4'
                                checked={c4}
                                onChange={(e) => setc4(e.target.checked)}
                                label={listArray[3]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show5) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check5'
                                checked={c5}
                                onChange={(e) => setc5(e.target.checked)}
                                label={listArray[4]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show6) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check6'
                                checked={c6}
                                onChange={(e) => setc6(e.target.checked)}
                                label={listArray[5]}
                            />
                        </Form.Group>
                    )}
                    {(hasChecklist && show7) && (
                        <Form.Group md="2" className="mb-3">
                            <Form.Check
                                type='checkbox'
                                id='check7'
                                checked={c7}
                                onChange={(e) => setc7(e.target.checked)}
                                label={listArray[6]}
                            />
                        </Form.Group>
                    )} */}
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleComplete}
                        // disabled={isDisabled}
                    >
                        {submitString}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}
