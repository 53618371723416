import axios from 'axios';

export default function reportError(code, message) {
    const url = process.env.REACT_APP_ERROR_LOG_URL;
    const data = { text: 'Error in ' + code + ' : ' + message};
    axios.post(url, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [
            (data, headers) => {
                return data;
            },
        ],
    });
}
