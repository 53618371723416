import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { customStyles } from '../../data/Data.js'
import reportError from '../../utils/ReportError';


export default function EditProjectStatus(props) {

    const [show, setShow] = useState(false);
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [status, setStatus] = useState('');
    const [submitString, setSubmitString] = useState('Save Project');
    const [editStatusOptions, setEditStatusOptions] = useState([
        {"value" : "Proposal", "label" : "Proposal"}, 
        {"value" : "Issued", "label" : "Issued"},
        {"value" : "Allocated", "label" : "Allocated"},
        {"value" : "Quality Assurance", "label" : "Quality Assurance"},
        {"value" : "Draft Sent", "label" : "Draft Sent"},
        {"value" : "To Invoice", "label" : "To Invoice"},
        {"value" : "Invoiced", "label" : "Invoiced"}, 
        {"value" : "Loss", "label" : "Loss"},
    ]);

    const statusOptions = [
        {"value" : "Proposal", "label" : "Proposal"}, 
        {"value" : "Issued", "label" : "Issued"},
        {"value" : "Allocated", "label" : "Allocated"},
        {"value" : "Quality Assurance", "label" : "Quality Assurance"},
        {"value" : "Draft Sent", "label" : "Draft Sent"},
        {"value" : "To Invoice", "label" : "To Invoice"},
        {"value" : "Invoiced", "label" : "Invoiced"}, 
        {"value" : "Loss", "label" : "Loss"},
    ];

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const handleClose = () =>  {
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setProjectDisplayString('');
        setSubmitString('Save Project');
        setShow(false);
    }

    const handleShow = () => {
        const pastStatus = props.row.values.statusDisplay;
        if (pastStatus === 'Proposal' || pastStatus === 'Issued') {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Loss", "label" : "Loss"},
            ]);
        } else if (pastStatus === 'Allocated' || pastStatus === 'Quality Assurance' || pastStatus === 'Draft Sent' || pastStatus === 'To Invoice') {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
        } else if (pastStatus === 'Invoiced') {
            setEditStatusOptions([
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "To Invoice", "label" : "To Invoice"},
            ]);
        } else if (pastStatus === 'Loss') {
            setEditStatusOptions([
                {"value" : "Loss", "label" : "Loss"}, 
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
            ]);
        } else {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
        }
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setProjectDisplayString(props.row.values.numberDisplay + ' — ' + props.row.values.nameDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        var isNewStatus = false;
        if (status.value !== props.row.values.statusDisplay) {
            isNewStatus = true;
        }

        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            if (isNewStatus) {
                project.set('status', status.value);
            }
            project.save()
            .then((res2)=>{
                const dataCopy = props.projectData;
                var thisProj = dataCopy[props.row.index];
                if (isNewStatus && status.value) {
                    thisProj.statusDisplay = status.value;
                }
                dataCopy[props.row.index] = thisProj;
                props.setProjectData(dataCopy);
                handleClose();
            }).catch((error)=>{
                reportError('EditProjectStatus 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            reportError('EditProjectStatus 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Save Project');
        })
    }

    return <>
        <Button variant='outline-secondary' className='square-button' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered size='sm' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Edit Status: {projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'> 
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={editStatusOptions}
                                        value={status}
                                        defaultValue={status}
                                        onChange={e=>setStatus(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
