import { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { customStyles } from '../../data/Data.js'
// import axios from 'axios';
import reportError from '../../utils/ReportError';


export default function EditProjectManager(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [projectDisplayString, setProjectDisplayString] = useState('');
    const [manager, setManager] = useState();
    const [second, setSecond] = useState();
    const [submitString, setSubmitString] = useState('Save Project');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    useEffect(()=>{
        setManager(defaultSingleValue(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue(props.projectManagerOptions, props.row.values.secondDisplay));
    }, [props.projectManagerOptions])

    useEffect(()=> {
        if (manager === second) {
            setErrorText('Pilot and copilot cannot be same');
            setIsDisabled(true);
        } else {
            setErrorText('');
            (!manager || !second) ? setIsDisabled(true) : setIsDisabled(false)
        }
    }, [manager, second]);

    const handleClose = () =>  {
        setManager(defaultSingleValue(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue(props.projectManagerOptions, props.row.values.secondDisplay));
        setProjectDisplayString('');
        setSubmitString('Save Project');
        setShow(false);
    }

    const handleShow = () => {
        setManager(defaultSingleValue(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue(props.projectManagerOptions, props.row.values.secondDisplay));
        setProjectDisplayString(props.row.values.projectNumberDisplay + ' — ' + props.row.values.projectNameDisplay);
        setShow(true);
    }

    const handleSubmit = () => {
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);


        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            if (manager.object) {
                project.set('managerId', manager.object);
            }
            if (second.object) {
                project.set('leadId', second.object);
            }
            project.save()
            .then((res2)=>{
                // if (manager.object !== undefined) {
                //     if ((manager.object.id !== props.currentUser.id) && 
                //         (manager.object.id !== props.row.values.personDisplay) &&
                //         (manager.object.attributes.slackNotifHook !== undefined)) {
                //         const url = manager.object.attributes.slackNotifHook;
                //         var userString = '';
                //         if (manager.object.attributes.slackUserId.length > 0) {
                //             userString = '<@' + manager.object.attributes.slackUserId + '>';
                //         } else {
                //             userString = manager.object.attributes.firstName + ' ' + manager.object.attributes.lastName;
                //         }
                //         const data = { text: 'Hi '+ userString + ' ' + 
                //             props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                //             ' has assigned you a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                //             props.row.values.projectNumberDisplay + '|' + props.row.values.projectNumberDisplay + 
                //             ' — ' + props.row.values.projectNameDisplay + '>'};
    
                //         axios.post(url, JSON.stringify(data), {
                //             withCredentials: false,
                //             transformRequest: [
                //                 (data, headers) => {
                //                     return data;
                //                 },
                //             ],
                //         });
                //     }
                // }
                // if (second.object !== undefined) {
                //     if ((second.object.id !== props.currentUser.id) && 
                //         (second.object.id !== props.row.values.secondDisplay) && 
                //         (second.object.attributes.slackNotifHook !== undefined)) {
                //         const url = second.object.attributes.slackNotifHook;
                //         var userString = '';
                //         if (second.object.attributes.slackUserId.length > 0) {
                //             userString = '<@' + second.object.attributes.slackUserId + '>';
                //         } else {
                //             userString = second.object.attributes.firstName + ' ' + second.object.attributes.lastName;
                //         }
                //         const data = { text: 'Hi '+ userString + ' ' + 
                //             props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                //             ' has set you as copilot for a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                //             props.row.values.projectNumberDisplay + '|' + props.row.values.projectNumberDisplay + 
                //             ' — ' + props.row.values.projectNameDisplay + '>'};
    
                //         axios.post(url, JSON.stringify(data), {
                //             withCredentials: false,
                //             transformRequest: [
                //                 (data, headers) => {
                //                     return data;
                //                 },
                //             ],
                //         });
                //     }
                // }
                const dataCopy = props.projectData;
                var thisProj = dataCopy[props.row.index];
                if (manager.object) {
                    thisProj.personDisplay = manager.object.id;
                    thisProj.personNameDisplay = manager.label;
                }
                if (second.object) {
                    thisProj.secondDisplay = second.object.id;
                    thisProj.secondNameDisplay = second.label;
                }
                dataCopy[props.row.index] = thisProj;
                props.setProjectData(dataCopy);
                handleClose();
            }).catch((error)=>{
                reportError('EditProjectManager 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Save Project');
            })
        }).catch((error)=>{
            reportError('EditProjectManager 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Save Project');
        })
    }

    return <>
        <div onDoubleClick={handleShow} >
            <span style={{color:'var(--text)', fontSize:'1em'}}>{props.cell}</span>
        </div>
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='md' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>{projectDisplayString}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'> 
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Allocated</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={manager}
                                        defaultValue={manager}
                                        onChange={e=>setManager(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Lead</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={second}
                                        defaultValue={second}
                                        onChange={e=>setSecond(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                            <div className='form-fail'>
                                {errorText}
                            </div>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
