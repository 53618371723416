import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import NumericFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles, yearOptions, documentOptions } from '../../data/Data.js'
// import axios from 'axios';
import reportError from '../../utils/ReportError';


export default function EditProject(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [number, setNumber] = useState();
    const [name, setName] = useState();
    const [manager, setManager] = useState();
    const [second, setSecond] = useState();
    const [status, setStatus] = useState();
    const [deadline, setDeadline] = useState();
    const [comment, setComment] = useState();
    const [year, setYear] = useState();
    const [fee, setFee] = useState();
    const [invoiced, setInvoiced] = useState();
    const [document, setDocument] = useState();
    const [editStatusOptions, setEditStatusOptions] = useState([
        {"value" : "Proposal", "label" : "Proposal"}, 
        {"value" : "Issued", "label" : "Issued"},
        {"value" : "Allocated", "label" : "Allocated"},
        {"value" : "Quality Assurance", "label" : "Quality Assurance"},
        {"value" : "Draft Sent", "label" : "Draft Sent"},
        {"value" : "To Invoice", "label" : "To Invoice"},
        {"value" : "Invoiced", "label" : "Invoiced"}, 
        {"value" : "Loss", "label" : "Loss"},
    ]);
    const [submitString, setSubmitString] = useState('Save Project');

    const statusOptions = [
        {"value" : "Proposal", "label" : "Proposal"}, 
        {"value" : "Issued", "label" : "Issued"},
        {"value" : "Allocated", "label" : "Allocated"},
        {"value" : "Quality Assurance", "label" : "Quality Assurance"},
        {"value" : "Draft Sent", "label" : "Draft Sent"},
        {"value" : "To Invoice", "label" : "To Invoice"},
        {"value" : "Invoiced", "label" : "Invoiced"}, 
        {"value" : "Loss", "label" : "Loss"},
    ];

    const thisYear2Digit = new Date().getFullYear().toString().slice(-2);

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const defaultSingleValue2 = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }

    const defaultMultiValue = (options, propsValue) => {
        return options.filter(option => 
            propsValue.map(propOption => {return propOption}).includes(option.value));
    }

    const simplifyMultiArray = (arrayToMod) => {
        var toReturn = [];
        for (let i = 0; i < arrayToMod.length; i++) {
            toReturn[i] = arrayToMod[i].value
        }
        return toReturn;
    }

    useEffect(()=>{
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
    }, [props.projectManagerOptions])

    useEffect(()=> {
        setErrorText('');
        (!number || !name || !manager || !second || !status || !deadline) ? setIsDisabled(true) : setIsDisabled(false);
    }, [number, name, manager, second, status, deadline]);

    const handleClose = () =>  {
        setNumber('');
        setName('');
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setComment('');
        setDeadline('');
        setYear(defaultSingleValue(yearOptions, props.row.values.yearDisplay));
        setFee('');
        setInvoiced('');
        setDocument(defaultMultiValue(documentOptions, props.row.values.documentDisplay.split(',')));
        setShow(false);
        setSubmitString('Save Project');
    }

    const handleShow = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        const pastStatus = props.row.values.statusDisplay;
        if (pastStatus === 'Proposal' || pastStatus === 'Issued') {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Loss", "label" : "Loss"},
            ]);
        } else if (pastStatus === 'Allocated' || pastStatus === 'Quality Assurance' || pastStatus === 'Draft Sent' || pastStatus === 'To Invoice') {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
        } else if (pastStatus === 'Invoiced') {
            setEditStatusOptions([
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "To Invoice", "label" : "To Invoice"},
            ]);
        } else if (pastStatus === 'Loss') {
            setEditStatusOptions([
                {"value" : "Loss", "label" : "Loss"}, 
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
            ]);
        } else {
            setEditStatusOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
        }
        console.log(props.row.values.projectNumberDisplay);
        setNumber(props.row.values.projectNumberDisplay);
        setName(props.row.values.projectNameDisplay);
        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        setSecond(defaultSingleValue2(props.projectManagerOptions, props.row.values.secondDisplay));
        setStatus(defaultSingleValue(statusOptions, props.row.values.statusDisplay));
        setComment(props.row.values.commentDisplay);
        setDeadline(props.row.values.deadlineValueDisplay);
        setYear(defaultSingleValue(yearOptions, props.row.values.yearDisplay));
        setDocument(defaultMultiValue(documentOptions, props.row.values.documentDisplay.split(',')));
        setFee(props.row.values.feeDisplay);
        setInvoiced(props.row.values.invoicedDisplay);
        setShow(true);
    }

    function isInt(value) {
        return !isNaN(value) && 
               parseInt(Number(value)) === value && 
               !isNaN(parseInt(value, 10));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const Parse = require('parse');
        // const query = new Parse.Query(Parse.Object.extend('Project'));
        // query.equalTo('projectId', parseInt(number));
        // query.find()
        // .then(function(res) {
        //     if ((res.length < 1 && number.length === 3) || number === props.row.values.projectNumberDisplay) {
                
                const query2 = new Parse.Query(Parse.Object.extend('Project'));
                query2.get(props.row.values.idDisplay)
                .then((project) =>{
                    var displayNumber;
                    if (isInt(number)) {
                        displayNumber = 'RCE-' + thisYear2Digit + number;
                        project.set('projectId', displayNumber);
                    } else {
                        displayNumber = number;
                        project.set('projectId', displayNumber);
                    }
                    
                    project.set('name', name);
                    if (manager.object) {
                        project.set('managerId', manager.object);
                    }
                    if (second.object) {
                        project.set('leadId', second.object);
                    }
                    if (status.value) {
                        project.set('status', status.value);
                    }
                    project.set('comment', comment);
                    project.set('deadline', deadline);
                    project.set('fee', parseInt(fee.slice(1)));
                    project.set('invoiced', parseInt(invoiced.slice(1)));
                    if (year.value) {
                        project.set('year', parseInt(year.value));
                    }
                    if (document[0]) {
                        project.set('document', simplifyMultiArray(document));
                    }

                    project.save()
                    .then((res2)=>{
                        if (!props.fromDashboard) {
                            const dataCopy = props.projectData;
                            var thisProj = dataCopy[props.row.index];
                            if (number && name) {
                                thisProj.projectNumberDisplay = displayNumber.slice(-3);
                                thisProj.projectNameDisplay = name;
                                thisProj.projectDisplay = <Link target='_blank' to={'/staff/' + displayNumber}>{displayNumber} — {name}</Link>;
                            }
                            if (manager.object) {
                                thisProj.personDisplay = manager.object.id;
                                thisProj.personNameDisplay = manager.object.attributes.firstName + ' ' + manager.object.attributes.lastName;
                            }
                            if (second.object) {
                                thisProj.secondDisplay = second.object.id;
                                thisProj.secondNameDisplay = second.object.attributes.firstName + ' ' + second.object.attributes.lastName;
                            }
                            if (status.value) {
                                thisProj.statusDisplay = status.value;
                            }
                            if (comment) {
                                thisProj.commentDisplay = comment;
                            }
                            if (deadline) {
                                thisProj.deadlineValueDisplay = deadline;
                                thisProj.deadlineDisplay = moment(deadline).locale('en-au').calendar({
                                    sameDay: '[Today]',
                                    nextDay: '[Tomorrow]',
                                    nextWeek: 'DD MMM',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'DD MMM',
                                    sameElse: 'DD MMM' 
                                });
                            }
                            if (year.value) {
                                thisProj.yearDisplay = year.value;
                            }
                            if (fee) {
                                thisProj.feeDisplay = fee;
                            }
                            if (invoiced) {
                                thisProj.invoicedDisplay = invoiced;
                            }
                            // if (document[0]) {
                            //     thisProj.documentDisplay = simplifyMultiArray(document);
                            // }
                            dataCopy[props.row.index] = thisProj;
                            props.setProjectData(dataCopy);
                        }
                        // if (manager.object !== undefined) {
                        //     if ((manager.object.id !== props.currentUser.id) && 
                        //         (manager.object.id !== props.row.values.personDisplay) &&
                        //         (manager.object.attributes.slackNotifHook !== undefined)) {
                        //         const url = manager.object.attributes.slackNotifHook;
                        //         var userString = '';
                        //         if (manager.object.attributes.slackUserId.length > 0) {
                        //             userString = '<@' + manager.object.attributes.slackUserId + '>';
                        //         } else {
                        //             userString = manager.object.attributes.firstName + ' ' + manager.object.attributes.lastName;
                        //         }
                        //         const data = { text: 'Hi '+ userString + ' ' + 
                        //             props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                        //             ' has assigned you a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                        //             number + '|' + number + ' — ' + name + '>'};
            
                        //         axios.post(url, JSON.stringify(data), {
                        //             withCredentials: false,
                        //             transformRequest: [
                        //                 (data, headers) => {
                        //                     return data;
                        //                 },
                        //             ],
                        //         });
                        //     }
                        // }
                        // if (second.object !== undefined) {
                        //     if ((second.object.id !== props.currentUser.id) && 
                        //         (second.object.id !== props.row.values.secondDisplay) && 
                        //         (second.object.attributes.slackNotifHook !== undefined)) {
                        //         const url = second.object.attributes.slackNotifHook;
                        //         var userString = '';
                        //         if (second.object.attributes.slackUserId.length > 0) {
                        //             userString = '<@' + second.object.attributes.slackUserId + '>';
                        //         } else {
                        //             userString = second.object.attributes.firstName + ' ' + second.object.attributes.lastName;
                        //         }
                        //         const data = { text: 'Hi '+ userString + ' ' + 
                        //             props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                        //             ' has set you as copilot for a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                        //             number + '|' + number + ' — ' + name + '>'};
            
                        //         axios.post(url, JSON.stringify(data), {
                        //             withCredentials: false,
                        //             transformRequest: [
                        //                 (data, headers) => {
                        //                     return data;
                        //                 },
                        //             ],
                        //         });
                        //     }
                        // }
                        handleClose();
                    }).catch((error)=>{
                        reportError('EditProject 1', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setSubmitString('Save Project');
                    })
                }).catch((error)=>{
                    reportError('EditProject 2', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setSubmitString('Save Project');
                })
            // } else if (res.length >= 1) {
            //     alert('Error: This project number already exists. Look for the project in the database!');
            //     setSubmitString('Save Project');
            // } else {
            //     alert('Error: Project number incorrect format');
            //     setSubmitString('Save Project');
            // }
        // }, (error) => {
            // reportError('EditProject 3', error.message);
            // alert('Error: Please refresh the page and try again. An error report has been sent.');
        //     setSubmitString('Save Project');
        // });
    }
    
    return <>
        <Button variant='outline-secondary' className='square-button' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered size='lg' backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Edit Project</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Row>
                            <Form.Group as={Col} md="4" id="new-project-number" className="mb-3">
                                <Form.Label>Project Number</Form.Label>
                                <NumericFormat
                                    className="form-control"
                                    displayType="input"
                                    prefix={'REC-' + thisYear2Digit}
                                    allowNegative={false}
                                    allowLeadingZeros
                                    value = {number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    id='form-project-name'
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={editStatusOptions}
                                        value={status}
                                        defaultValue={status}
                                        onChange={e=>setStatus(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Allocated</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={manager}
                                        defaultValue={manager}
                                        onChange={e=>setManager(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Lead</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        id='form-project-manager'
                                        options={props.projectManagerOptions}
                                        value={second}
                                        defaultValue={second}
                                        onChange={e=>setSecond(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Year</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={yearOptions}
                                        value={year}
                                        defaultValue={year}
                                        onChange={e=>setYear(e)}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Fee (AUD)</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'$'}
                                        allowNegative={false}
                                        value = {fee}
                                        onChange={(e) => setFee(e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group controlId="form-project-manager">
                                    <Form.Label>Invoiced</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'$'}
                                        allowNegative={false}
                                        value = {invoiced}
                                        onChange={(e) => setInvoiced(e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Group>
                                    <Form.Label>Documents</Form.Label>
                                        <Select
                                            styles={customStyles}
                                            options={documentOptions}
                                            value={document}
                                            defaultValue={document}
                                            onChange={e=>setDocument(e)}
                                            isMulti
                                        />
                                </Form.Group>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" className="mb-3">
                                <Form.Label>Deadline</Form.Label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control'
                                    selected={deadline}
                                    // minDate={new Date()}
                                    todayButton="Today"
                                    onChange={(date)=> setDeadline(date)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="8" className="mb-3">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <div className='form-fail'>
                                {errorText}
                            </div>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
