import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumericFormat from 'react-number-format';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles } from '../../data/Data.js'
import axios from 'axios';
import reportError from '../../utils/ReportError';


export default function AddProject(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectManager, setProjectManager] = useState();
    const [submitString, setSubmitString] = useState('Create Project');
    const [isDisabled, setIsDisabled] = useState(true);

    const thisYear2Digit = new Date().getFullYear().toString().slice(-2);

    useEffect(()=>{
        handleProjectManagerDefault(props.projectManagerOptions);
        var nextNum = props.nextProjectNumber;
        switch (nextNum.toString().length) {
            case 3:
                setProjectNumber('RCE-' + thisYear2Digit + nextNum);
                break;
            case 2:
                setProjectNumber('RCE-' + thisYear2Digit + '0' + nextNum);
                break;
            case 1:
                setProjectNumber('RCE-' + thisYear2Digit + '00' + nextNum);
                break;    
            default:
                setProjectNumber('RCE-' + thisYear2Digit + nextNum);
                break;
        }
    }, [props.projectManagerOptions])

    const handleProjectManagerDefault = (options) => {
        setProjectManager(options[0]);
    }

    useEffect(()=> {
        if (((projectNumber.length === 9) || (projectNumber.length === 3)) && checkProjNum(projectNumber)) {
        // if ((projectNumber.length === 6) && (checkProjNum(projectNumber))) {
        // if ((projectNumber.length === 6) && (checkProjNum(projectNumber))) {
            setErrorText('');
            (!projectNumber || !projectName || !projectManager) ? setIsDisabled(true) : setIsDisabled(false);
        } 
        else if ((projectNumber.length !== 9) && (projectNumber.length !== 3)) {
            setErrorText('Project number must be three digits');
            setIsDisabled(true);
        } else {
            setErrorText('Project number already exists');
            setIsDisabled(true);
        }
    }, [projectNumber, projectName, projectManager]);

    const checkProjNum = (projNum) => {
        var sender = true;
        for (let i = 0; i < props.projectData.length; i++) {
            if (props.projectData[i].projectNumberDisplay === projNum.slice(-3)) {
                sender =  false;
            }
        }
        return sender;
    }

    const handleClose = () =>  {
        setShow(false);
        setErrorText('');
        setProjectNumber(props.nextProjectNumber.toString());
        setProjectName('');
        handleProjectManagerDefault(props.projectManagerOptions);
        setSubmitString('Create Project');
    }

    const handleShow = () => {
        setShow(true);
        var nextNum = props.nextProjectNumber;
        switch (nextNum.toString().length) {
            case 3:
                setProjectNumber('RCE-' + thisYear2Digit + nextNum);
                break;
            case 2:
                setProjectNumber('RCE-' + thisYear2Digit + '0' + nextNum);
                break;
            case 1:
                setProjectNumber('RCE-' + thisYear2Digit + '00' + nextNum);
                break;    
            default:
                setProjectNumber('RCE-' + thisYear2Digit + nextNum);
                break;
        }
    }

    function isInt(value) {
        return !isNaN(value) && 
               parseInt(Number(value)) === value && 
               !isNaN(parseInt(value, 10));
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Creating&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);
        
        var newNum = projectNumber;
        if (isInt(newNum)) {
            setProjectNumber('RCE=' + thisYear2Digit + newNum.toString());
        }

        const Project = Parse.Object.extend('Project');
        const newProject = new Project();
        const query = new Parse.Query(Project);
        query.equalTo('proposalId', projectNumber);
        query.find()
        .then(function(res) {
            if (res.length < 1) {

                if (projectNumber) {
                    newProject.set('proposalId', projectNumber);
                    newProject.set('projectId', projectNumber);
                }

                if (projectManager) {
                    newProject.set('managerId', projectManager.object);
                    newProject.set('leadId', projectManager.object);
                }

                if (projectName) {
                    newProject.set('name', projectName);
                }

                newProject.set('createdBy', props.currentUser);
                newProject.set('status', 'Proposal');
                newProject.set('organisation', props.dummyOrg);
                const thisYear = new Date().getFullYear();
                newProject.set('year', thisYear);

                const today = new Date();
                const twodays = new Date(today);
                twodays.setDate(twodays.getDate() + 2);
                newProject.set('deadline', twodays);
                newProject.set('commencedOn', today);

                newProject.set('invoiced', 0);

                var projectACL = new Parse.ACL();
                projectACL.setRoleReadAccess('staff', true);
                projectACL.setRoleWriteAccess('staff', true);
                newProject.setACL(projectACL);

                newProject.save().then((projectRes) => {
                    projectRes.relation('users').add(props.dummyUser);
                    projectRes.save().then((newProjectRes)=>{
                        if (props.taskMode) {
                            var newProjectOptions = props.localProjectOptions;
                            var newProjectItem = {
                                "value" : newProjectRes.id,
                                "label" : projectNumber + ' — ' + projectName,
                                "object" : newProjectRes,
                                "number" : projectNumber,
                            };
                            newProjectOptions.unshift(newProjectItem);
                            props.setLocalProjectOptions(newProjectOptions);
                            props.handleProjectSelect(newProjectItem);
                        }
                        // if (projectManager.object !== undefined) {
                        //     if ((projectManager.object.id !== props.currentUser.id) && (projectManager.object.attributes.slackNotifHook !== undefined)) {
                        //         const url = projectManager.object.attributes.slackNotifHook;
                        //         var userString = '';
                        //         if (projectManager.object.attributes.slackUserId.length > 0) {
                        //             userString = '<@' + projectManager.object.attributes.slackUserId + '>';
                        //         } else {
                        //             userString = projectManager.object.attributes.firstName + ' ' + projectManager.object.attributes.lastName;
                        //         }
                        //         const data = { text: 'Hi '+ userString + ' ' + 
                        //             props.currentUser.attributes.firstName + ' ' + props.currentUser.attributes.lastName + 
                        //             ' has assigned you a project:\n' + '<https://portal.loteconsulting.com/staff/' + 
                        //             projectNumber + '|' + projectNumber + ' — ' + projectName + '>'};
            
                        //         axios.post(url, JSON.stringify(data), {
                        //             withCredentials: false,
                        //             transformRequest: [
                        //                 (data, headers) => {
                        //                     return data;
                        //                 },
                        //             ],
                        //         });
                        //     }
                        // }
                        // createSlackChannel(projectName, projectNumber, projectManager);
                        // window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=s7vl7bsdK0u5roqdJ5kw132Yt_zARDFCrA9KrbI6BXBUNzBUQzM1VDlHNkMwRDdaUk9KR0QxVkdBWS4u','_newtab' + Date.now());
                        handleClose();
                    }).catch((error)=> {
                        reportError('AddProject 1', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setSubmitString('Create Project');
                    })
                }).catch((error)=> {
                    reportError('AddProject 2', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setSubmitString('Create Project');
                })
            } else if (res.length >= 1) {
                alert('Error: This project number already exists. Look for the project in the database!');
                setSubmitString('Create Project');
            } else {
                alert('Error: Project number incorrect format');
                setSubmitString('Create Project');
            }
        }, (error) => {
            reportError('AddProject 3', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Create Project');
        });
    }

    // async function createSlackChannel (projname, projnumber, projmanager, projsecond) {
    //     const { WebClient } = require('@slack/web-api');
    //     const token = process.env.REACT_APP_SLACK_WEBHOOK_URL;
    //     const web = new WebClient(token);
    //     const namestring = projnumber + '-' + projname.toLowerCase();
    //     try {
    //         const result = await web.conversations.create({
    //             name: namestring,
    //             is_private: true
    //         });
    //         console.log(result);
    //         const chanString = result. channel.id;
    //         const userString = projmanager.object.attributes.slackUserId + "," + projsecond.object.attributes.slackUserId;
    //         const result2 = await web.conversations.invite({
    //             channel: chanString,
    //             users: userString
    //         });
    //         console.log(result2);
    //     }
    //     catch (error) {
    //         console.error(error);
    //     }
    // }

    function handleProjectManager(e) {
        setProjectManager(e);
    }

    return <>
        {(!props.taskMode && props.noText && !props.fromDashboard) && (
            <Button onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button>
        )}
        {(!props.taskMode && props.noText && props.fromDashboard) && (
            <Button variant="outline-primary" className='inline-button' onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button>
        )}
        {(!props.taskMode && !props.noText) && (
            <Button onClick={handleShow}>
                New Proposal
            </Button>
        )}
        {(props.taskMode && !props.noText) && (
            <Button variant="outline-primary" onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button> 
        )}
        {(props.taskMode && props.noText) && (
            <Button variant="outline-primary" className='inline-button' onClick={handleShow}>
                <i className="fas fa-plus"></i>
            </Button> 
        )}
        <Modal show={show} onHide={handleClose} onExited={props.handleExit} centered backdrop="static"> 
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>New Proposal</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label>Project Number</Form.Label>
                            <NumericFormat
                                className="form-control"
                                displayType="input"
                                prefix={'RCE-' + thisYear2Digit}
                                allowNegative={false}
                                allowLeadingZeros
                                value = {projectNumber}
                                onChange={(e) => setProjectNumber(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Project Name</Form.Label>
                            <Form.Control
                                id='form-project-name'
                                type="text"
                                size="sm"
                                autoFocus
                                value={projectName}
                                placeholder=""
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label className='pl-4'>Lead</Form.Label>
                            <Select
                                styles={customStyles}
                                id='form-project-manager'
                                options={props.projectManagerOptions}
                                onChange={(e)=>handleProjectManager(e)}
                                value={projectManager}
                                defaultValue={projectManager}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <div className='form-fail'>
                            {errorText}
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
