import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Container, Tab, Nav, Row, Col, Form, Stack, 
    ButtonGroup, ToggleButton, Card, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table'
import moment from 'moment';
import 'moment/locale/en-au';
import Select from 'react-select';
import Confetti from 'react-confetti'
import { Fireworks } from 'fireworks/lib/react'
import { useReward } from 'react-rewards';
import AddTask from './AddTask';
import AddProject from './AddProject';
import EditTask from './EditTask';
import StarTask from './StarTask';
import CompleteTask from './CompleteTask';
import StarProject from './StarProject';
import IndicateManager from './IndicateManager';
import EditProject from './EditProject';
import EditTaskAction from './EditTaskAction';
import EditTaskDeadline from './EditTaskDeadline';
import EditProjectManager from './EditProjectManager';
import EditProjectComment from './EditProjectComment';
import EditProjectDeadline from './EditProjectDeadline';
import ProjectTasks from './ProjectTasks';
import ProjectClient from './ProjectClient';
// import ProjectStatus from './ProjectStatus';
import ProgressProject from './ProgressProject';
import RecurringHistory from './RecurringHistory';
import NumberFormat from 'react-number-format';
import { paginationOptions, yearOptions, customStyles, documentOptions } from '../../data/Data.js';
import reportError from '../../utils/ReportError';


import './Task.css';

export default function Task() {

    const Parse = require('parse');
    const currentUser = Parse.User.current();

    const [allProjectsLoaded, setAllProjectsLoaded] = useState(false);
    const [showLoadingProjects, setShowLoadingProjects] = useState(false);

    const [slimView, setSlimView] = useState(currentUser.attributes.slimView);

    const [tasks, taskDispatch] = useReducer(tasksReducer, []);
    const [isTasksLoaded, setIsTasksLoaded] = useState(false);
    const [taskSubOn, setTaskSubOn] = useState(false);

    const [projects, projectDispatch] = useReducer(projectsReducer, []);
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    const [projectSubOn, setProjectSubOn] = useState(false);

    const [projectOptions, projectOptionsDispatch] = useReducer(projectOptionsReducer, []);

    const [dummyOrg, setDummyOrg] = useState();
    const [dummyUser, setDummyUser] = useState();
    const [users, setUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);

    // const [projectManagers, setProjectManagers] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [staffFilterOptions, setStaffFilterOptions] = useState([]);
    const [projectManagerOptions, setProjectManagerOptions] = useState([]);
    const [projectManagerFilterOptions, setProjectManagerFilterOptions] = useState([]);

    const [userFilter, setUserFilter] = useState({
        "value" : currentUser.id,
        "label" : currentUser.attributes.firstName + ' ' + currentUser.attributes.lastName,
    });
    const [userFilterArray, setUserFilterArray] = useState([currentUser.id]);
    const [completeFilter, setCompleteFilter] = useState(false);
    const [completeFilterArray, setCompleteFilterArray] = useState(['false', 'both']);
    const [tableSortArray, setTableSortArray] = useState([{
        id: "deadlineDisplay",
        desc: false
    }]);
    const [starFilter, setStarFilter] = useState(false);
    // const [assignedView, setAssignedView] = useState(false);
    const [showResponsible, setShowResponsible] = useState(false);
    const [showManager, setShowManager] = useState(false);

    // const [starFilter2, setStarFilter2] = useState(false);

    const [isManagerFilter, setIsManagerFilter] = useState(false);
    const [showManagerFilter, setShowManagerFilter] = useState(true);
    // const [isSecondFilter, setIsSecondFilter] = useState(true);

    const [pipeSwitch, setPipeSwitch] = useState(true);
    const [currentSwitch, setCurrentSwitch] = useState(false);
    const [databaseSwitch, setDatabaseSwitch] = useState(false);

    const [showDetailFilters, setShowDetailFilters] = useState(false);
    const [detailFilterButtonString, setDetailFilterButtonString] = useState(<i className="fas fa-angle-down"></i>);

    const [statusFilterOptions, setStatusFilterOptions] = useState([
        {"value" : "Proposal", "label" : "Proposal"}, 
        {"value" : "Issued", "label" : "Issued"},
        // {"value" : "Allocated", "label" : "Allocated"},
        // {"value" : "Quality Assurance", "label" : "Quality Assurance"},
        // {"value" : "Draft Sent", "label" : "Draft Sent"},
        // {"value" : "To Invoice", "label" : "To Invoice"},
        // {"value" : "Invoiced", "label" : "Invoiced"}, 
        // {"value" : "Loss", "label" : "Loss"},
    ]);
    const [statusFilter, setStatusFilter] = useState();
    const [statusFilterArray, setStatusFilterArray] = useState(['Proposal', 'Issued']);
    const [yearFilter, setYearFilter] = useState();
    const [yearFilterArray, setYearFilterArray] = useState([]);

    const [documentFilter, setDocumentFilter] = useState();
    const [documentFilterArray, setDocumentFilterArray] = useState([]);
    
    const [showFireworks, setShowFireworks] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [celebrationColours, setCelebrationColours] = useState(['#033f57', '#f47a63']);

    const [key, setKey] = useState('tasks');
    const [showAddTask, setShowAddTask] = useState(true);
    const [showAddProject, setShowAddProject] = useState(false);

    const globalFilterRef = useRef(null);
    const taskLaterRef = useRef(null);
    const projectLaterRef = useRef(null);
    const projectOptionsLaterRef = useRef(null);

    const [nextProjectNumber, setNextProjectNumber] = useState(1);

    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [isAdminLoaded, setIsAdminLoaded] = useState(false);

    const thisYear2Digit = new Date().getFullYear().toString().slice(-2);

    useEffect(() => {
        document.title = "Tasks & Projects";
    },[]);
    
    useEffect(() => {
        queryAdmin();
        queryTasks();
        queryRelevantProjects();
        queryDummyOrg();
        queryDummyUser();
        queryStaff();
        queryProjectManagers();
    },[]);

    const queryAdmin = () => {
        if (currentUser.id === 'BzQyAMBIEF') {
            setUserIsAdmin(true);
        }
        setIsAdminLoaded(true);
    }

    const queryDatabaseMode = () => {
        if (!allProjectsLoaded) {
            setAllProjectsLoaded(true);
            queryDatabaseProjects();
        }
    }

    const isCurrentEditing = () => {
        var toRet = false;
        var element = document.getElementsByClassName('modal-dialog');
        if ((element.length > 0) || (showDetailFilters && showAddProject) || (globalFilterRef.current)) {
            toRet = true;
        }
        return toRet;
    }

    const isModalOpen = () => {
        var toRet = false;
        var element = document.getElementsByClassName('modal-dialog');
        if ((element.length > 0)) {
            toRet = true;
        }
        return toRet;
    }

    const handleExit = () => {
        if (showFireworks) {
            setShowFireworks(false);
        }
        if (showConfetti) {
            setShowConfetti(false);
        }
        if (taskLaterRef.current) {
            for (let i = 0; i < taskLaterRef.current.length; i++) {
                if (taskLaterRef.current[i].task.attributes.assignedTo !== undefined) {
                    taskDispatch(taskLaterRef.current[i]);
                }
            }
            taskLaterRef.current = null;
        }
        if (projectLaterRef.current) {
            for (let j = 0; j < projectLaterRef.current.length; j++) {
                if (projectLaterRef.current[j].project.attributes.managerId !== undefined) {
                    projectDispatch(projectLaterRef.current[j]);
                }
            }
            projectLaterRef.current = null;
        }
        if (projectOptionsLaterRef.current) {
            for (let k = 0; k < projectOptionsLaterRef.current.length; k++) {
                projectOptionsDispatch(projectOptionsLaterRef.current[k]);
            }
            projectOptionsLaterRef.current = null;
        }
    }

    function tasksReducer (tasks, action) {
        switch (action.type) {
            case 'load': {
                return action.data;
            }
            case 'create': {
                var newArr = [...tasks, action.task];
                newArr.sort(
                    (t1, t2) => (t1.attributes.deadline > t2.attributes.deadline) ? 1 : (t1.attributes.deadline < t2.attributes.deadline) ? -1 : 0);
                return newArr;
            }
            case 'update': {
                var newArr = tasks.map((t) => {
                    if (t.id === action.id) {
                        return action.task;
                    } else {
                        return t;
                    }
                });
                newArr.sort(
                    (t1, t2) => (t1.attributes.deadline > t2.attributes.deadline) ? 1 : (t1.attributes.deadline < t2.attributes.deadline) ? -1 : 0);
                return newArr;
            }
            case 'delete': {
                return tasks.filter((t) => t.id !== action.id);
            }
            default: {
                reportError('Task 1', action.type);
                throw Error('Error: Please refresh the page and try again. An error report has been sent.');
            }
        }
    }

    function projectsReducer (projects, action) {
        switch (action.type) {
            case 'load': {
                return action.data;
            }
            case 'create': {
                var newArr = [...projects, action.project];
                newArr.sort(
                    (p1, p2) => (p1.attributes.projectId > p2.attributes.projectId) ? 1 : (p1.attributes.projectId < p2.attributes.projectId) ? -1 : 0);
                return newArr;
            }
            case 'update': {
                var newArr = projects.map((t) => {
                    if (t.id === action.id) {
                        return action.project;
                    } else {
                        return t;
                    }
                });
                newArr.sort(
                    (p1, p2) => (p1.attributes.projectId > p2.attributes.projectId) ? 1 : (p1.attributes.projectId < p2.attributes.projectId) ? -1 : 0);
                return newArr;
            }
            case 'delete': {
                return projects.filter((t) => t.id !== action.id);
            }
            default: {
                reportError('Task 2', action.type);
                throw Error('Error: Please refresh the page and try again. An error report has been sent.');
            }
        }
    }

    function projectOptionsReducer (projectOptions, action) {
        switch (action.type) {
            case 'load': {
                var options = action.data.map(project => ({
                    "value" : project.id,
                    "label" : project.attributes.projectId + ' — ' + project.attributes.name,
                    "object" : project,
                    "number" : project.attributes.projectId
                }));
                options.unshift({
                    "value" : 'null',
                    "label" : 'No project',
                    "object" : 'null',
                    "number" : ''
                })
                return options;
            }
            case 'create': {
                var newArr = [...projectOptions];
                newArr.splice(1, 0, {
                    "value" : action.id,
                    "label" : action.project.attributes.projectId + ' — ' + action.project.attributes.name,
                    "object" : action.project,
                    "number" : action.project.attributes.projectId
                });
                return newArr;
            }
            case 'update': {
                var newArr = projectOptions.map((t) => {
                    if (t.value === action.id) {
                        return {
                            "value" : action.id,
                            "label" : action.project.attributes.projectId + ' — ' + action.project.attributes.name,
                            "object" : action.project,
                            "number" : action.project.attributes.projectId
                        };
                    } else {
                        return t;
                    }
                });
                return newArr;
            }
            case 'delete': {
                return projectOptions.filter((t) => t.value !== action.id);
            }
            default: {
                throw Error('Unknown action: ' + action.type);
            }
        }
    }

    const queryDummyOrg = () => {
        const Org = Parse.Object.extend('Organisation');
        const query = new Parse.Query(Org);
        query.get('qRTS98lQsP').then((res) => {
            setDummyOrg(res);
        }).catch((error) =>  {
            reportError('Task 1', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryDummyUser = () => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.get('dsuBq5k0vu').then((res) => {
            setDummyUser(res);
        }).catch((error) =>  {
            reportError('Task 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryRelevantProjects = () => {

        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        const Project = Parse.Object.extend('Project');
        const query = new Parse.Query(Project);

        query.limit(999999);
        query.includeAll();
        query.ascending('projectId');
        query.find()
        .then(function(res) {
            projectOptionsDispatch({
                type: 'load',
                data: res,
            });
            projectDispatch({
                type: 'load',
                data: res,
            });
            setIsProjectsLoaded(true);
        }, (error) => {
            reportError('Task 3', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });

        const query2 = new Parse.Query(Project);
        query2.limit(999999);
        query2.includeAll();
        query2.ascending('projectId');

        if (!projectSubOn) {
            query2.subscribe().then(subscription => {
                subscription.on('open', () => {
                    setProjectSubOn(true);
                });
    
                subscription.on('create', (project) => {
                    if (isCurrentEditing()) {
                        if (projectLaterRef.current) {
                            projectLaterRef.current.push({
                                type: 'create',
                                project: project,
                            });
                        } else {
                            projectLaterRef.current = [{
                                type: 'create',
                                project: project,
                            }];
                        }
                        if (projectOptionsLaterRef.current) {
                            projectOptionsLaterRef.current.push({
                                type: 'create',
                                project: project,
                            });
                        } else {
                            projectOptionsLaterRef.current = [{
                                type: 'create',
                                project: project,
                            }];
                        }
                    } else {
                        if (project.attributes.managerId !== undefined) {
                            projectDispatch({
                                type: 'create',
                                project: project,
                            });
                        }
                        projectOptionsDispatch({
                            type: 'create',
                            project: project,
                        });
                    }
                });
    
                subscription.on('enter', (project) => {
                    if (isCurrentEditing()) {
                        if (projectLaterRef.current) {
                            projectLaterRef.current.push({
                                type: 'create',
                                project: project,
                            });
                        } else {
                            projectLaterRef.current = [{
                                type: 'create',
                                project: project,
                            }];
                        }
                        if (projectOptionsLaterRef.current) {
                            projectOptionsLaterRef.current.push({
                                type: 'create',
                                project: project,
                            });
                        } else {
                            projectOptionsLaterRef.current = [{
                                type: 'create',
                                project: project,
                            }];
                        }
                    } else {
                        if (project.attributes.managerId !== undefined) {
                            projectDispatch({
                                type: 'create',
                                project: project,
                            });
                        }
                        projectOptionsDispatch({
                            type: 'create',
                            project: project,
                        });
                    }
                });
        
                subscription.on('update', (project) => {
                    if (isCurrentEditing()) {
                        if (projectLaterRef.current) {
                            projectLaterRef.current.push({
                                type: 'update',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectLaterRef.current = [{
                                type: 'update',
                                id: project.id,
                                project: project,
                            }];
                        }
                        if (projectOptionsLaterRef.current) {
                            projectOptionsLaterRef.current.push({
                                type: 'update',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectOptionsLaterRef.current = [{
                                type: 'update',
                                id: project.id,
                                project: project,
                            }];
                        }
                    } else {
                        if (project.attributes.managerId !== undefined) {
                            projectDispatch({
                                type: 'update',
                                id: project.id,
                                project: project,
                            });
                        }
                        projectOptionsDispatch({
                            type: 'update',
                            id: project.id,
                            project: project,
                        });
                    }
                });
        
                subscription.on('delete', (project) => {
                    if (isCurrentEditing()) {
                        if (projectLaterRef.current) {
                            projectLaterRef.current.push({
                                type: 'delete',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectLaterRef.current = [{
                                type: 'delete',
                                id: project.id,
                                project: project,
                            }];
                        }
                        if (projectOptionsLaterRef.current) {
                            projectOptionsLaterRef.current.push({
                                type: 'delete',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectOptionsLaterRef.current = [{
                                type: 'delete',
                                id: project.id,
                                project: project,
                            }];
                        }
                    } else {
                        projectDispatch({
                            type: 'delete',
                            id: project.id,
                            project: project,
                        });
                        projectOptionsDispatch({
                            type: 'delete',
                            id: project.id,
                            project: project,
                        });
                    }
                });
    
                subscription.on('leave', (project) => {
                    if (isCurrentEditing()) {
                        if (projectLaterRef.current) {
                            projectLaterRef.current.push({
                                type: 'delete',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectLaterRef.current = [{
                                type: 'delete',
                                id: project.id,
                                project: project,
                            }];
                        }
                        if (projectOptionsLaterRef.current) {
                            projectOptionsLaterRef.current.push({
                                type: 'delete',
                                id: project.id,
                                project: project,
                            });
                        } else {
                            projectOptionsLaterRef.current = [{
                                type: 'delete',
                                id: project.id,
                                project: project,
                            }];
                        }
                    } else {
                        projectDispatch({
                            type: 'delete',
                            id: project.id,
                            project: project,
                        });
                        projectOptionsDispatch({
                            type: 'delete',
                            id: project.id,
                            project: project,
                        });
                    }
                });
    
                subscription.on('close', () => {
                    setProjectSubOn(false);
                });
            });
        }
    }

    const queryDatabaseProjects = () => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        const Project = Parse.Object.extend('Project');
        const query = new Parse.Query(Project);
        query.limit(999999);
        query.includeAll();
        query.ascending('projectId');
        query.find()
        .then(function(res) {
            projectDispatch({
                type: 'load',
                data: res,
            });
            projectOptionsDispatch({            
                type: 'load',
                data: res,
            });
            setIsProjectsLoaded(true);
            setShowLoadingProjects(false);
        }, (error) => {
            reportError('Task 4', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryStaff = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','staff').first().then(staff => { 
            const users = staff.getUsers().query();
            users.equalTo('status', 'Active');
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                for (let i = 0; i < userQueryRes.length; i++) {
                    if (currentUser.id === userQueryRes[i].id) {
                        var thisUser = userQueryRes.splice(i,1);
                        userQueryRes.unshift(thisUser[0]); 
                        break;
                    }
                }
                var options = userQueryRes.map(user => ({
                    "value" : user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setStaffOptions(options);
                var options2 = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setStaffFilterOptions(options2);
            }, (error) => {
                reportError('Task 5', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('Task 6', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
        })
    }

    const queryProjectManagers = () => {
        const query = new Parse.Query(Parse.Role);
        query.equalTo('name','staff').first().then(staff => { 
            const users = staff.getUsers().query();
            users.equalTo('status', 'Active');
            users.ascending('firstName');
            users.find()
            .then((userQueryRes) => {
                for (let i = 0; i < userQueryRes.length; i++) {
                    if (currentUser.id === userQueryRes[i].id) {
                        var thisUser = userQueryRes.splice(i,1);
                        userQueryRes.unshift(thisUser[0]);
                        break;
                    }
                }
                var options = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setProjectManagerOptions(options);
                var options2 = userQueryRes.map(user => ({
                    "value" :user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName,
                    "object" : user
                }));
                setProjectManagerFilterOptions(options2);
            }, (error) => {
                reportError('Task 7', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('Task 8', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
        })
    }
    
    const queryTasks = () => {
        
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        const Task = Parse.Object.extend('Task');

        const subQuery1 = new Parse.Query(Task);
        subQuery1.equalTo('complete', true);
        subQuery1.greaterThanOrEqualTo('completedOn', lastWeek);

        const subQuery2 = new Parse.Query(Task);
        subQuery2.equalTo('complete', false);

        const query = new Parse.Query.or(subQuery1, subQuery2);
        query.limit(999999);
        query.includeAll();
        query.ascending('deadline');
        query.find()
        .then(function(res) {
            taskDispatch({
                type: 'load',
                data: res,
            });
            setIsTasksLoaded(true);
        }, (error) => {
            reportError('Task 9', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
        });

        if (!taskSubOn) {
            query.subscribe().then(subscription => {
                subscription.on('open', () => {
                    setTaskSubOn(true);
                });
    
                subscription.on('create', (task) => {
                    if (isCurrentEditing()) {
                        if (taskLaterRef.current) {
                            taskLaterRef.current.push({
                                type: 'create',
                                task: task,
                            });
                        } else {
                            taskLaterRef.current = [{
                                type: 'create',
                                task: task,
                            }];
                        }
                    } else {
                        if (task.attributes.assignedTo !== undefined) {
                            taskDispatch({
                                type: 'create',
                                task: task,
                            });
                        }
                    }
                });
    
                subscription.on('enter', (task) => {
                    if (isCurrentEditing()) {
                        if (taskLaterRef.current) {
                            taskLaterRef.current.push({
                                type: 'create',
                                task: task,
                            });
                        } else {
                            taskLaterRef.current = [{
                                type: 'create',
                                task: task,
                            }];
                        }
                    } else {
                        if (task.attributes.assignedTo !== undefined) {
                            taskDispatch({
                                type: 'create',
                                task: task,
                            });
                        }
                    }
                });
        
                subscription.on('update', (task) => {
                    if (isCurrentEditing()) {
                        if (taskLaterRef.current) {
                            taskLaterRef.current.push({
                                type: 'update',
                                id: task.id,
                                task: task,
                            });
                        } else {
                            taskLaterRef.current = [{
                                type: 'update',
                                id: task.id,
                                task: task,
                            }];
                        }
                    } else {
                        if (task.attributes.assignedTo !== undefined) {
                            taskDispatch({
                                type: 'update',
                                id: task.id,
                                task: task,
                            });
                        }
                    }
                });
        
                subscription.on('delete', (task) => {
                    if (isCurrentEditing()) {
                        if (taskLaterRef.current) {
                            taskLaterRef.current.push({
                                type: 'delete',
                                id: task.id,
                                task: task,
                            });
                        } else {
                            taskLaterRef.current = [{
                                type: 'delete',
                                id: task.id,
                                task: task,
                            }];
                        }
                    } else {
                        taskDispatch({
                            type: 'delete',
                            id: task.id,
                            task: task,
                        });
                    }
                });
    
                subscription.on('leave', (task) => {
                    if (isCurrentEditing()) {
                        if (taskLaterRef.current) {
                            taskLaterRef.current.push({
                                type: 'delete',
                                id: task.id,
                                task: task,
                            });
                        } else {
                            taskLaterRef.current = [{
                                type: 'delete',
                                id: task.id,
                                task: task,
                            }];
                        }
                    } else {
                        taskDispatch({
                            type: 'delete',
                            id: task.id,
                            task: task,
                        });
                    }
                });
    
                subscription.on('close', () => {
                    setTaskSubOn(false);
                });
            });
        }
    }

    const getTaskData = () => {

        var data2 = [];

        for (var i = 0; i < tasks.length; i++) {
            var task = tasks[i];
            var recurringString = '';
            var recurring = task.get('recurring');
            if (recurring) {
                recurringString = task.get('recurringType');
            }
            var star = 'false';
            if (task.get('star') === true) {
                star = 'true';
            }
            var actionIs = task.get('action');
            var actionString = actionIs;
            var link = 'false';
            var url = task.get('url');
            if ((task.get('link') === true) && (url != null)) {
                link = 'true';
                actionString = <a href={url} target='_blank'>{actionIs}</a>;
            }
            var completeDate = 0;
            var completeString = '';
            var complete = task.get('complete');
            var completeTest = '';
            if (complete) {
                completeString = moment(task.get('completedOn')).locale('en-au').calendar({
                    // sameDay: '[Today]',
                    // nextDay: '[Tomorrow]',
                    nextWeek: 'ddd DD MMM h:mm A',
                    // lastDay: '[Yesterday]',
                    lastWeek: 'ddd DD MMM h:mm A',
                    sameElse: 'ddd DD MMM h:mm A' 
                });

                completeTest = 'true';
            } else {
                if (recurring === true) {
                    if (task.get('recurringCompleteOnce') === true) {
                        completeTest = 'both';
                        var outcomes = task.get('recurringOutcomes');
                        var lastOutcome = outcomes[outcomes.length - 1].date;
                        completeDate = lastOutcome;
                        completeString = moment(lastOutcome).locale('en-au').calendar({
                            // sameDay: '[Today]',
                            // nextDay: '[Tomorrow]',
                            nextWeek: 'ddd DD MMM h:mm A',
                            // lastDay: '[Yesterday]',
                            lastWeek: 'ddd DD MMM h:mm A',
                            sameElse: 'ddd DD MMM h:mm A' 
                        });
                    } else {
                        completeTest = 'false';
                    }
                } else {
                    completeTest = 'false';
                }
            }
            var completedOn = task.get('completedOn');
            if (completedOn) {
                completeDate = completedOn;
            }
            var deadlineValDisp = task.get('deadline');
            var deadlineDisp = moment(task.get('deadline')).locale('en-au').calendar({
                // sameDay: '[Today]',
                // nextDay: '[Tomorrow]',
                nextWeek: 'ddd DD MMM h:mm A',
                // lastDay: '[Yesterday]',
                lastWeek: 'ddd DD MMM h:mm A',
                sameElse: 'ddd DD MMM h:mm A' 
            });

            // define all variables to be set
            // define test variables
            // if single test is undefined
                // fetch and then set and then display
            // else
                // set and then display

            var proj = '';
            var projObjId = '';   
            var projId = '';      
            var projString = '';    
            var assignedBy = '';
            var assignedId = '';
            var assignedTo, person, personNameDisp, createdBy;
            var testProject = task.get('project');
            var testCreatedBy = task.get('createdBy');
            var testAssignedTo = task.get('assignedTo');
            if ((testAssignedTo === undefined) || (testCreatedBy === undefined) || (testProject === undefined)) {
                task.fetch().then((updatedTask) => {
                    person = updatedTask.get('assignedTo').id;
                    assignedTo = updatedTask.get('assignedTo');
                    createdBy = updatedTask.get('createdBy');
                    personNameDisp = assignedTo.attributes.firstName + ' ' + assignedTo.attributes.lastName;
                    if (createdBy.id !== person) {
                        assignedBy = createdBy.attributes.firstName + ' ' + createdBy.attributes.lastName;
                        assignedId = createdBy.id;
                    }
                    if (task.has('project')) {
                        projObjId = updatedTask.get('project').id;
                        projId = updatedTask.get('project').attributes.projectId;
                        proj = <Link target='_blank' to={'/staff/' + projId}>{projId + ' — ' + updatedTask.get('project').attributes.name}</Link>;
                        projString = projId + ' — ' + updatedTask.get('project').attributes.name;
                    }
                    if (i === 0) {
                        data2.push({
                            id: i,
                            idDisplay: task.id,
                            projObjIdDisplay: projObjId,
                            projStringDisplay: projString,
                            personDisplay: person,
                            personNameDisplay: personNameDisp,
                            recurringDisplay: recurringString,
                            assignedDisplay: assignedBy,
                            assignedIdDisplay: assignedId,
                            actionDisplay: actionString,
                            deadlineValueDisplay: deadlineValDisp,
                            completeValueDisplay: completeDate,
                            deadlineDisplay: deadlineDisp,
                            projectDisplay: proj,
                            starDisplay: star,
                            linkDisplay: link,
                            urlDisplay: url,
                            actionTextDisplay: actionIs,
                            completeDisplay: completeString,
                            isComplete: completeTest,
                            isRecurring: recurring,
                        });
                    } else if (task.id !== tasks[i-1].id) {
                        data2.push({
                            id: i,
                            idDisplay: task.id,
                            projObjIdDisplay: projObjId,
                            projStringDisplay: projString,
                            personDisplay: person,
                            personNameDisplay: personNameDisp,
                            recurringDisplay: recurringString,
                            assignedDisplay: assignedBy,
                            assignedIdDisplay: assignedId,
                            actionDisplay: actionString,
                            deadlineValueDisplay: deadlineValDisp,
                            completeValueDisplay: completeDate,
                            deadlineDisplay: deadlineDisp,
                            projectDisplay: proj,
                            starDisplay: star,
                            linkDisplay: link,
                            urlDisplay: url,
                            actionTextDisplay: actionIs,
                            completeDisplay: completeString,
                            isComplete: completeTest,
                            isRecurring: recurring,
                        });
                    }
                }, (error) => {
                    reportError('Task 10', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                });
            } else {
                person = testAssignedTo.id;
                assignedTo = testAssignedTo;
                createdBy = testCreatedBy;
                personNameDisp = assignedTo.attributes.firstName + ' ' + assignedTo.attributes.lastName;
                if (createdBy.id !== person) {
                    assignedBy = createdBy.attributes.firstName + ' ' + createdBy.attributes.lastName;
                    assignedId = createdBy.id;
                }
                if (task.has('project')) {
                    projObjId = task.get('project').id;
                    projId = task.get('project').attributes.projectId;
                    proj = <Link target='_blank' to={'/staff/' + projId}>{projId + ' — ' + task.get('project').attributes.name}</Link>;
                    projString = projId + ' — ' + task.get('project').attributes.name;
                }
                if (i === 0) {
                    data2.push({
                        id: i,
                        idDisplay: task.id,
                        projObjIdDisplay: projObjId,
                        projStringDisplay: projString,
                        personDisplay: person,
                        personNameDisplay: personNameDisp,
                        recurringDisplay: recurringString,
                        assignedDisplay: assignedBy,
                        assignedIdDisplay: assignedId,
                        actionDisplay: actionString,
                        deadlineValueDisplay: deadlineValDisp,
                        completeValueDisplay: completeDate,
                        deadlineDisplay: deadlineDisp,
                        projectDisplay: proj,
                        starDisplay: star,
                        linkDisplay: link,
                        urlDisplay: url,
                        actionTextDisplay: actionIs,
                        completeDisplay: completeString,
                        isComplete: completeTest,
                        isRecurring: recurring,
                    });
                } else if (task.id !== tasks[i-1].id) {
                    data2.push({
                        id: i,
                        idDisplay: task.id,
                        projObjIdDisplay: projObjId,
                        projStringDisplay: projString,
                        personDisplay: person,
                        personNameDisplay: personNameDisp,
                        recurringDisplay: recurringString,
                        assignedDisplay: assignedBy,
                        assignedIdDisplay: assignedId,
                        actionDisplay: actionString,
                        deadlineValueDisplay: deadlineValDisp,
                        completeValueDisplay: completeDate,
                        deadlineDisplay: deadlineDisp,
                        projectDisplay: proj,
                        starDisplay: star,
                        linkDisplay: link,
                        urlDisplay: url,
                        actionTextDisplay: actionIs,
                        completeDisplay: completeString,
                        isComplete: completeTest,
                        isRecurring: recurring,
                    });
                }
            }
        }
        return data2;
    }

    const [taskData, setTaskData] = React.useState(React.useMemo(() => getTaskData, [isTasksLoaded]));
    useEffect(() => {
        if (tasks.length > 0) {
            setTaskData(getTaskData);
        }
    },[tasks]);

    const taskCols = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'Proj Obj Id',
                accessor: 'projObjIdDisplay',
                show: false,
            },
            {
                Header: 'Proj String',
                accessor: 'projStringDisplay',
                show: false,
            },
            {
                Header: 'Assigned Id',
                accessor: 'assignedIdDisplay',
                show: false,
            },
            {
                Header: 'Responsible Id',
                accessor: 'personDisplay',
                show: false,
                Filter: FakeColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Recurring Type',
                accessor: 'recurringDisplay',
                show: false,
            },
            {
                Header: 'Deadline Value',
                accessor: 'deadlineValueDisplay',
                show: false,
            },
            {
                Header: 'Complete Value',
                accessor: 'completeValueDisplay',
                show: false,
            },
            {
                Header: 'Is Complete',
                accessor: 'isComplete',
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                show: false,
            },
            {
                Header: 'Is Recurring',
                accessor: 'isRecurring',
                show: false,
            },
            {
                Header: 'Starred',
                accessor: 'starDisplay',
                show: false,
            },
            {
                Header: 'Link',
                accessor: 'linkDisplay',
                show: false,
            },
            {
                Header: 'URL',
                accessor: 'urlDisplay',
                show: false,
            },
            {
                Header: 'Action Text',
                accessor: 'actionTextDisplay',
                show: false,
            },
            {
                Header: 'Assignor',
                accessor: 'assignedDisplay',
                show: false,
            },
            {
                Header: " ",
                id: "flagstar",
                accessor: "flagstarDisplay",
                Cell: ({ cell }) => <>
                    <StarTask
                        row={cell.row}
                        taskData={[...taskData]}
                        setTaskData={setTaskData}
                    />
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Responsible</span>,
                accessor: 'personNameDisplay',
                show: showResponsible,
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Project</span>,
                accessor: 'projectDisplay',
                sortType: sortProjectString,
                sortDescFirst: true,
                show: !slimView,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Task</span>,
                accessor: 'actionDisplay',
                sortType: sortAction,
                Cell: ({ cell }) => <>
                    <EditTaskAction
                        row={cell.row}
                        cell={cell.value}
                        taskData={[...taskData]}
                        setTaskData={setTaskData}
                        slimView={slimView}
                        handleExit={handleExit}
                    />
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Deadline</span>,
                accessor: 'deadlineDisplay',
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                sortType: sortDeadlineString,
                Cell: ({ cell }) => <>
                    <EditTaskDeadline 
                        row={cell.row}
                        cell={cell.value}
                        taskData={[...taskData]}
                        setTaskData={setTaskData}
                        completeFilter={completeFilter}
                        handleExit={handleExit}
                    />
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Completed</span>,
                accessor: 'completeDisplay',
                sortType: sortCompleteString,
                sortDescFirst: true,
                show: completeFilter,
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--success)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header: " ",
                id: "update",
                accessor: "updateDisplay",
                Cell: ({ cell }) => <>
                    <EditTask
                        row={cell.row}
                        staffOptions={staffOptions}
                        projectOptions={projectOptions}
                        currentUser={currentUser}
                        taskData={[...taskData]}
                        setTaskData={setTaskData}
                        handleExit={handleExit}
                    />
                    <span>&nbsp;</span>
                    <CompleteTask
                        row={cell.row}
                        taskData={[...taskData]}
                        setTaskData={setTaskData}
                        handleCelebrate={handleCelebrate}
                        handleExit={handleExit}
                    />
                    <span>&nbsp;</span>
                    <RecurringHistory
                        row={cell.row}
                        taskData={[...taskData]}
                        insideComplete={false}
                        handleExit={handleExit}
                    />
                </>
            }
        ],
        [taskData, staffOptions, projectOptions, showResponsible, completeFilter, slimView]
    );

    const getProjectData = () => {

        var data2 = [];

        for (var i = 0; i < projects.length; i++) {
            var project = projects[i];
            var proposalId = project.get('proposalId');
            var propNum = proposalId.slice(1,4);
            var projId = project.get('projectId');
            var projNum = projId.slice(-3);
            var statusIs = project.get('status');
            var commentString = ''
            if (project.get('comment')) {
                commentString = project.get('comment');
            }
            var year = '';
            if (project.get('year')) {
                year = project.get('year').toString();
            }
            var fee = '';
            if (project.get('fee') || (project.get('fee') === 0)) {
                fee = project.get('fee').toString();
            }
            var invoiced = '';
            if (project.get('invoiced') || (project.get('invoiced') === 0)) {
                invoiced = project.get('invoiced').toString();
            }
            var documents = '';
            if (project.get('document')) {
                documents = project.get('document').toString();
            }
            var commencedOn = '';
            if (project.get('commencedOn')) {
                commencedOn = project.get('commencedOn');
            }
            var completedOn = '';
            if (project.get('completedOn')) {
                completedOn = project.get('completedOn');
            }
            var nameDisp = project.get('name');
            var projDisp = <Link target='_blank' to={'/staff/' + projId}>{projId} — {project.get('name')}</Link>;
            var statusDisp = statusIs;

            var star = 'false';
            if (project.get('star') === true) {
                star = 'true';
            }
            var deadlineValDisp = project.get('deadline');
            var updatedValDisp = project.get('updatedAt');
            var createdValDisp = project.get('createdAt');
            var deadlineDisp = moment(project.get('deadline')).locale('en-au').calendar({
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'DD MMM',
                lastDay: '[Yesterday]',
                lastWeek: 'DD MMM',
                sameElse: 'DD MMM' 
            });
            var updateDisp = moment(project.get('updatedAt')).locale('en-au').calendar({
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'DD MMM',
                lastDay: '[Yesterday]',
                lastWeek: 'DD MMM',
                sameElse: 'DD MMM' 
            });
            var createDisp = moment(project.get('createdAt')).locale('en-au').calendar({
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'DD MMM',
                lastDay: '[Yesterday]',
                lastWeek: 'DD MMM',
                sameElse: 'DD MMM' 
            });
            
            var org, orgName, manager, personDisp, personNameDisp, second, secondDisp, secondNameDisp, teamDisp, isManagerDisp, isSecondDisp;
            var thisOrg = project.get('organisation');
            var thisManager = project.get('managerId');
            var thisSecond = project.get('leadId');
            isManagerDisp = 'false';
            // isSecondDisp = 'false';
            if ((thisManager === undefined) || (thisOrg === undefined) || (thisSecond === undefined)) {
                project.fetch().then((updateProject) => {
                    org = updateProject.get('organisation');
                    orgName = updateProject.get('organisation').attributes.name;
                    manager = updateProject.get('managerId');
                    second = updateProject.get('leadId');
                    personDisp = manager.id;
                    personNameDisp = manager.attributes.firstName + ' ' + manager.attributes.lastName;
                    secondDisp = second.id;
                    secondNameDisp = second.attributes.firstName + ' ' + second.attributes.lastName;
                    teamDisp = personDisp + ' ,' + secondDisp;
                    if (personDisp === userFilterArray[0]) {
                        isManagerDisp = 'true';
                    }
                    // if (secondDisp === userFilterArray[0]) {
                    //     isSecondDisp = 'true';
                    // }
                    if (i === 0) {
                        data2.push({
                            id: i,
                            idDisplay: project.id,
                            proposalNumberDisplay: propNum,
                            projectNumberDisplay: projNum,
                            projectNameDisplay: nameDisp,
                            personDisplay: personDisp,
                            personNameDisplay: personNameDisp,
                            secondDisplay: secondDisp,
                            secondNameDisplay: secondNameDisp,
                            teamDisplay: teamDisp,
                            isManagerDisplay: isManagerDisp,
                            // isSecondDisplay: isSecondDisp,
                            projectDisplay: projDisp,
                            statusDisplay: statusDisp,
                            commentDisplay: commentString,
                            orgDisplay: org,
                            orgNameDisplay: orgName,
                            yearDisplay: year,
                            feeDisplay: fee,
                            invoicedDisplay: invoiced,
                            documentDisplay: documents,
                            commencedOnDisplay: commencedOn,
                            completedOnDisplay: completedOn,
                            starDisplay: star,
                            deadlineValueDisplay: deadlineValDisp,
                            deadlineDisplay: deadlineDisp,
                            updatedValueDisplay: updatedValDisp,
                            updateDisplay: updateDisp,
                            createdValueDisplay: createdValDisp,
                            createDisplay: createDisp,
                        });
                    } else if (project.id !== projects[i-1].id) {
                        data2.push({
                            id: i,
                            idDisplay: project.id,
                            proposalNumberDisplay: propNum,
                            projectNumberDisplay: projNum,
                            projectNameDisplay: nameDisp,
                            personDisplay: personDisp,
                            personNameDisplay: personNameDisp,
                            secondDisplay: secondDisp,
                            secondNameDisplay: secondNameDisp,
                            teamDisplay: teamDisp,
                            isManagerDisplay: isManagerDisp,
                            // isSecondDisplay: isSecondDisp,
                            projectDisplay: projDisp,
                            statusDisplay: statusDisp,
                            commentDisplay: commentString,
                            orgDisplay: org,
                            orgNameDisplay: orgName,
                            yearDisplay: year,
                            feeDisplay: fee,
                            invoicedDisplay: invoiced,
                            documentDisplay: documents,
                            commencedOnDisplay: commencedOn,
                            completedOnDisplay: completedOn,
                            starDisplay: star,
                            deadlineValueDisplay: deadlineValDisp,
                            deadlineDisplay: deadlineDisp,
                            updatedValueDisplay: updatedValDisp,
                            updateDisplay: updateDisp,
                            createdValueDisplay: createdValDisp,
                            createDisplay: createDisp,
                        });
                    }
                }, (error) => {
                    reportError('Task 11', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                });
            } else {
                org = thisOrg;
                orgName = thisOrg.attributes.name;
                manager = thisManager;
                personDisp = manager.id;
                personNameDisp = manager.attributes.firstName + ' ' + manager.attributes.lastName;
                second = thisSecond;
                secondDisp = second.id;
                secondNameDisp = second.attributes.firstName + ' ' + second.attributes.lastName;
                teamDisp = personDisp + ' ,' + secondDisp;
                if (personDisp === userFilterArray[0]) {
                    isManagerDisp = 'true';
                }
                // if (secondDisp === userFilterArray[0]) {
                //     isSecondDisp = 'true';
                // }
                if (i === 0) {
                    data2.push({
                        id: i,
                        idDisplay: project.id,
                        proposalNumberDisplay: propNum,
                        projectNumberDisplay: projNum,
                        projectNameDisplay: nameDisp,
                        personDisplay: personDisp,
                        personNameDisplay: personNameDisp,
                        secondDisplay: secondDisp,
                        secondNameDisplay: secondNameDisp,
                        teamDisplay: teamDisp,
                        isManagerDisplay: isManagerDisp,
                        // isSecondDisplay: isSecondDisp,
                        projectDisplay: projDisp,
                        statusDisplay: statusDisp,
                        commentDisplay: commentString,
                        orgDisplay: org,
                        orgNameDisplay: orgName,
                        yearDisplay: year,
                        feeDisplay: fee,
                        invoicedDisplay: invoiced,
                        documentDisplay: documents,
                        commencedOnDisplay: commencedOn,
                        completedOnDisplay: completedOn,
                        starDisplay: star,
                        deadlineValueDisplay: deadlineValDisp,
                        deadlineDisplay: deadlineDisp,
                        updatedValueDisplay: updatedValDisp,
                        updateDisplay: updateDisp,
                        createdValueDisplay: createdValDisp,
                        createDisplay: createDisp,
                    });
                } else if (project.id !== projects[i-1].id) {
                    data2.push({
                        id: i,
                        idDisplay: project.id,
                        proposalNumberDisplay: propNum,
                        projectNumberDisplay: projNum,
                        projectNameDisplay: nameDisp,
                        personDisplay: personDisp,
                        personNameDisplay: personNameDisp,
                        secondDisplay: secondDisp,
                        secondNameDisplay: secondNameDisp,
                        teamDisplay: teamDisp,
                        isManagerDisplay: isManagerDisp,
                        // isSecondDisplay: isSecondDisp,
                        projectDisplay: projDisp,
                        statusDisplay: statusDisp,
                        commentDisplay: commentString,
                        orgDisplay: org,
                        orgNameDisplay: orgName,
                        yearDisplay: year,
                        feeDisplay: fee,
                        invoicedDisplay: invoiced,
                        documentDisplay: documents,
                        commencedOnDisplay: commencedOn,
                        completedOnDisplay: completedOn,
                        starDisplay: star,
                        deadlineValueDisplay: deadlineValDisp,
                        deadlineDisplay: deadlineDisp,
                        updatedValueDisplay: updatedValDisp,
                        updateDisplay: updateDisp,
                        createdValueDisplay: createdValDisp,
                        createDisplay: createDisp,
                    });
                }
            }
        }
        return data2;
    }

    const [projectData, setProjectData] = React.useState(React.useMemo(() => getProjectData, [isProjectsLoaded]));
    useEffect(() => {
        if (projects.length > 0) {
            setProjectData(getProjectData);
            var projectNumbers = projects.filter((p) => (p.attributes.projectId.slice(0,6) === ('RCE-' + thisYear2Digit) && p.attributes.projectId.slice(-3) < 1000));
            if (projectNumbers.length > 0) {
                setNextProjectNumber(parseInt(projectNumbers[projectNumbers.length - 1].attributes.projectId.slice(-3)) + 1);
            }
        }
    },[projects]);

    const projectCols = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'idDisplay',
                show: false,
            },
            {
                Header: 'Num',
                accessor: 'proposalNumberDisplay',
                show: false,
            },
            {
                Header: 'Num',
                accessor: 'projectNumberDisplay',
                show: false,
            },
            {
                Header: 'Org',
                accessor: 'orgDisplay',
                show: false,
            },
            {
                Header: 'Org Name',
                accessor: 'orgNameDisplay',
                show: false,
            },
            {
                Header: 'Name',
                accessor: 'projectNameDisplay',
                show: false,
            },
            {
                Header: 'Manager Id',
                accessor: 'personDisplay',
                show: false,
                Filter: FakeColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Second Id',
                accessor: 'secondDisplay',
                show: false,
                Filter: FakeColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'Team',
                accessor: 'teamDisplay',
                show: false,
                Filter: FakeColumnFilter,
                filter: 'includesSome',
            },
            {
                Header: 'is manager',
                accessor: 'isManagerDisplay',
                show: false,
            },
            // {
            //     Header: 'is second',
            //     accessor: 'isSecondDisplay',
            //     show: false,
            // },
            {
                Header: 'Starred',
                accessor: 'starDisplay',
                show: false,
            },
            {
                Header: 'Year',
                accessor: 'yearDisplay',
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                show: false,
            },
            {
                Header: 'Location',
                accessor: 'locationDisplay',
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                show: false,
            },
            {
                Header: 'document',
                accessor: 'documentDisplay',
                show: false,
            },
            {
                Header: 'commenced on',
                accessor: 'commencedOnDisplay',
                show: false,
            },
            {
                Header: 'completed on',
                accessor: 'completedOnDisplay',
                show: false,
            },
            {
                Header: 'Deadline Value',
                accessor: 'deadlineValueDisplay',
                show: false,
            },
            {
                Header: "",
                id: "update0",
                accessor: "updateDisplay0",
                Cell: ({ cell }) => <>
                    <Stack direction="horizontal" gap={1} >
                        <div>
                            <StarProject
                                row={cell.row}
                                projectData={[...projectData]}
                                setProjectData={setProjectData}
                            />
                        </div>
                        <div>
                            <ProjectTasks
                                row={cell.row}
                                taskData={[...taskData]}
                                pipeSwitch={pipeSwitch}
                                databaseSwitch={databaseSwitch}
                                staffOptions={staffOptions}
                                projectManagerOptions={projectManagerOptions}
                                projectOptions={projectOptions}
                                currentUser={currentUser}
                                setTaskData={setTaskData}
                                projectData={[...projectData]}
                                dummyOrg={dummyOrg}
                                dummyUser={dummyUser}
                                nextProjectNumber={nextProjectNumber}
                                handleExit={handleExit}
                                progressMode={false}
                            />
                        </div>
                        <div>
                            <ProjectClient
                                row={cell.row}
                                handleExit={handleExit}
                                progressMode={false}
                                users={users}
                                setUsers={setUsers}
                                userOptions={userOptions}
                                setUserOptions={setUserOptions}
                                orgs={orgs}
                                setOrgs={setOrgs}
                                orgOptions={orgOptions}
                                setOrgOptions={setOrgOptions}
                                projectData={[...projectData]}
                                setProjectData={setProjectData}
                            />
                        </div>
                    </Stack>
                </>
            },
            {
                Header: "",
                id: "pilot0",
                accessor: "pilotDisplay0",
                Cell: ({ cell }) => <>
                    <IndicateManager
                        row={cell.row}
                        user={userFilterArray[0]}
                        hide={showManager}
                    />
                </>
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>
                    {pipeSwitch && (
                        <span>Proposal</span>
                    )}{!pipeSwitch && (
                        <span>Project</span>
                    )}</span>,
                accessor: 'projectDisplay',
                sortType: sortProjectString,
                sortDescFirst: false,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Allocated</span>,
                accessor: 'personNameDisplay',
                show: (showManager && !pipeSwitch),
                Cell: ({ cell }) => <>
                    <EditProjectManager
                        row={cell.row}
                        cell={cell.value}
                        projectManagerOptions={projectManagerOptions}
                        currentUser={currentUser}
                        projectData={[...projectData]}
                        setProjectData={setProjectData}
                        handleExit={handleExit}
                    />
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Lead</span>,
                accessor: 'secondNameDisplay',
                show: showManager,
                Cell: ({ cell }) => <>
                    <EditProjectManager
                        row={cell.row}
                        cell={cell.value}
                        projectManagerOptions={projectManagerOptions}
                        currentUser={currentUser}
                        projectData={[...projectData]}
                        setProjectData={setProjectData}
                        handleExit={handleExit}
                    />
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Fee</span>,
                accessor: 'feeDisplay',
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>
                        <NumberFormat
                            value={cell.value}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Invoiced</span>,
                accessor: 'invoicedDisplay',
                show: (databaseSwitch || currentSwitch),
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>
                        <NumberFormat
                            value={cell.value}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Status</span>,
                accessor: 'statusDisplay',
                // show: (databaseSwitch || currentSwitch),
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                // show: databaseSwitch,
                Cell: ({ cell }) => <>
                    <span style={{color:'var(--text)', fontSize:'1em'}}>{cell.value}</span>
                </>,
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Comment</span>,
                accessor: 'commentDisplay',
                show: !databaseSwitch,
                Cell: ({ cell }) => {
                    return <>
                        <EditProjectComment
                            row={cell.row}
                            cell={cell.value}
                            projectData={[...projectData]}
                            setProjectData={setProjectData}
                            handleExit={handleExit}
                        />
                    </>
                },
            },
            {
                Header:()=><span style={{color:'var(--text)', fontSize:'1.2em'}}>Deadline</span>,
                accessor: 'deadlineDisplay',
                show: !databaseSwitch,
                Filter: FakeColumnFilter,
                filter: 'includesSome',
                sortType: sortDeadlineString,
                Cell: ({ cell }) => <>
                    <EditProjectDeadline 
                        row={cell.row}
                        cell={cell.value}
                        projectData={[...projectData]}
                        setProjectData={setProjectData}
                        handleExit={handleExit}
                    />
                </>
            },
            {
                Header: "",
                id: "update1",
                accessor: "updateDisplay1",
                Cell: ({ cell }) => <>
                    <Stack direction="horizontal" gap={1} >
                        {/* <div>
                            <ProjectStatus
                                row={cell.row}
                                databaseSwitch={databaseSwitch}
                            />
                        </div> */}
                        <div>
                            <EditProject
                                row={cell.row}
                                projectManagerOptions={projectManagerOptions}
                                currentUser={currentUser}
                                projectData={[...projectData]}
                                setProjectData={setProjectData}
                                handleExit={handleExit}
                            />
                        </div>
                        <div>
                            <ProgressProject
                                row={cell.row}
                                pipeSwitch={pipeSwitch}
                                databaseSwitch={databaseSwitch}
                                currentUser={currentUser}
                                projectManagerOptions={projectManagerOptions}
                                projectOptions={projectOptions}
                                handleCelebrate={handleCelebrate}
                                taskData={[...taskData]}
                                staffOptions={staffOptions}
                                projectData={[...projectData]}
                                setProjectData={setProjectData}
                                dummyOrg={dummyOrg}
                                dummyUser={dummyUser}
                                nextProjectNumber={nextProjectNumber}
                                handleExit={handleExit}
                                users={users}
                                setUsers={setUsers}
                                userOptions={userOptions}
                                setUserOptions={setUserOptions}
                                orgs={orgs}
                                setOrgs={setOrgs}
                                orgOptions={orgOptions}
                                setOrgOptions={setOrgOptions}
                            />
                        </div>
                    </Stack>
                </>
            },
        ],
        [projectData, projectManagerOptions, showManager, databaseSwitch, currentSwitch, pipeSwitch, projectOptions, staffOptions]
    )

    const TaskTable = () => {
        if(isTasksLoaded) {
            return <>
                <TableView columns={taskCols} data={taskData} tableType='task' getRowProps={colourRowTask}/>
            </>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading tasks...</em></td></tr></tbody></Table>
        }
    }

    const ProjectTable = () => {
        if (showLoadingProjects) {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading projects...</em></td></tr></tbody></Table>
        } else if(isProjectsLoaded) {
            return <>
                <TableView columns={projectCols} data={projectData} tableType='project' getRowProps={colourRowProject}/>
            </>
        } else {
            return <Table><tbody><tr><td colSpan={1} style={{textAlign:'center', color:'var(--text)'}}><em>Loading projects...</em></td></tr></tbody></Table>
        }
    }

    const colourRowTask = (row) => {
        return ({style: {background: 'var(--table-row)',},})
    }

    const colourRowProject = (row) => {
        return ({style: {background: 'var(--table-row)',},})
    }

    function GlobalFilter({
        globalFilter,
        setGlobalFilter,
    }) {
        const [value, setValue] = React.useState(globalFilter);
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined);
            if (value !== '') {
                globalFilterRef.current = true;
            } else {
                globalFilterRef.current = false;
            }
        }, 200)
      
        return (
            <span>
                <input
                    id='table-search'
                    value={value || ''}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={'Search'}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                        width: '100%',
                    }}
                />
            </span>
        )
    }

    function sortProjectString (rowA, rowB, id, desc) {
        var a = 0;
        var b = 0;
        if (rowA.values[id].props) {
            const strA = rowA.values[id].props.children;
            a = Number.parseInt(strA.slice(0,6));
        } else {
            a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (rowB.values[id].props) {
            const strB = rowB.values[id].props.children;
            b = Number.parseInt(strB.slice(0,6));
        } else {
            b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (a > b) {
            return 1; 
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }

    function sortAction (rowA, rowB, id, desc) {
        var a = rowA.values.actionTextDisplay.toUpperCase();
        var b = rowB.values.actionTextDisplay.toUpperCase();
        if (a > b) {
            return 1; 
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }

    function sortDeadlineString (rowA, rowB, id, desc) {
        return new Date(rowA.values.deadlineValueDisplay) - new Date(rowB.values.deadlineValueDisplay);
    }

    function sortCompleteString (rowA, rowB, id, desc) {
        return new Date(rowA.values.completeValueDisplay) - new Date(rowB.values.completeValueDisplay);
    }

    function handleUserFilter(e) {

        setUserFilter(e);   
        // setUserFilter2(e);
        var data2 = [];
        e.forEach(item => {
            data2.push(item.value);
        });
        setUserFilterArray(data2);

        setShowManagerFilter(false);

        if (e.length === 1) {
            setShowManager(false);
            setShowResponsible(false);
        } else {
            setShowManager(true);
            setShowResponsible(true);
        }
    }

    // function handleAssignedFilter(e) {
    //     setAssignedView(e);
    //     setShowResponsible(e);
    // }

    function handleStarFilter(e) {
        setStarFilter(e);
    }

    function handleCompleteFilter(e) {
        if (e === true) {
            setCompleteFilterArray(['true', 'both']);
            setTableSortArray([{
                id: "completeDisplay",
                desc: true
            }]);
            // setFlagFilter(false);
        } else {
            setCompleteFilterArray(['false', 'both']);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        }
        setCompleteFilter(e);
    }


    function handleIsManagerFilter(e) {
        setIsManagerFilter(e);
    }

    function handlePipeFilter() {
        if (!pipeSwitch) {
            setPipeSwitch(true);
            setCurrentSwitch(false);
            setDatabaseSwitch(false);
            setStatusFilterOptions([
                {"value" : "Proposal", "label" : "Proposal"}, 
                {"value" : "Issued", "label" : "Issued"},
            ]);
            setStatusFilterArray(['Proposal', 'Issued']);
            setStatusFilter([]);
            setShowDetailFilters(false);
            setDetailFilterButtonString(<i className="fas fa-angle-down"></i>);
            setYearFilterArray([]);
            setYearFilter([]);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        }
    }

    function handleCurrentFilter() {
        if (!currentSwitch) {
            setPipeSwitch(false);
            setCurrentSwitch(true);
            setDatabaseSwitch(false);
            setStatusFilterOptions([
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
            ]);
            setStatusFilterArray(['Allocated', 'Quality Assurance', 'Draft Sent', 'To Invoice']);
            setStatusFilter([]);

            setShowDetailFilters(false);
            setDetailFilterButtonString(<i className="fas fa-angle-down"></i>);
            setYearFilterArray([]);
            setYearFilter([]);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        }
    }

    function handleDatabaseFilter() {
        if (!databaseSwitch) {
            if (!allProjectsLoaded) {
                setShowLoadingProjects(true);
                queryDatabaseMode();
            }
            setPipeSwitch(false);
            setCurrentSwitch(false);
            setDatabaseSwitch(true);
            setStatusFilterOptions([
                {"value" : "Proposal", "label" : "Proposal"},
                {"value" : "Issued", "label" : "Issued"}, 
                {"value" : "Allocated", "label" : "Allocated"},
                {"value" : "Quality Assurance", "label" : "Quality Assurance"},
                {"value" : "Draft Sent", "label" : "Draft Sent"},
                {"value" : "To Invoice", "label" : "To Invoice"},
                {"value" : "Invoiced", "label" : "Invoiced"}, 
                {"value" : "Loss", "label" : "Loss"},
            ]);
            setStatusFilterArray(['Proposal', 'Issued', 'Allocated', 'Quality Assurance', 'Draft Sent', 'To Invoice', 'Invoiced', 'Loss']);
            setStatusFilter([]);

            handleUserFilter([]);
            setShowDetailFilters(true);
            setDetailFilterButtonString(<i className="fas fa-angle-up"></i>);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        }
    }

    function handleDetailFilterButton () {
        if (showDetailFilters) {
            setShowDetailFilters(false);
            setDetailFilterButtonString(<i className="fas fa-angle-down"></i>);
            setYearFilterArray([]);
            setYearFilter([]);
            handleExit();
        } else {
            setShowDetailFilters(true);
            setDetailFilterButtonString(<i className="fas fa-angle-up"></i>);
        }
    }

    function handleStatusFilter(e) {
        setStatusFilter(e);
        if (e.length !== 0) {
            var data2 = [];
            e.forEach(item => {
                data2.push(item.value);
            });
            setStatusFilterArray(data2);
        // } else if (pipeSwitch) {
        //     setStatusFilterArray(['Quoting', 'Undecided', 'Lead', 'FP on hold']);
        // } else if (currentSwitch) {
        //     setStatusFilterArray(['Active', 'Site Inspection', 'Internal Review', 'C10 Review', 'SD Review', 'On hold']);
        // } else {
            setStatusFilterArray([]);
        }
    }

    function handleYearFilter(e) {
        setYearFilter(e);   
        var data2 = [];
        e.forEach(item => {
            data2.push(item.value);
        });
        setYearFilterArray(data2);
    }

    function handleDocumentFilter(e) {
        setDocumentFilter(e);   
        var data2 = [];
        e.forEach(item => {
            data2.push(item.value);
        });
        setDocumentFilterArray(data2);
    }

    function FakeColumnFilter({}) {      
        return (
            <span></span>
        )
    }

    const defaultPropGetter = () => ({})

    function TableView ({ columns, data, tableType, getRowProps = defaultPropGetter}) {

        var defState; 

        defState = {
            pageIndex: 0,
            pageSize: 200,
            hiddenColumns: columns.map(column => {
                if (column.show === false) {
                    return column.accessor || column.id;
                }
            }),
            sortBy: tableSortArray,
        };

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex },
            state,
            setFilter,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data,
                initialState: defState,       
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,
        )

        useEffect(() => {
            if (tableType === 'task') {
            //     if (assignedView) {
            //         if (userFilterArray.length < 2) {
            //             setFilter("assignedIdDisplay", userFilterArray);
            //         } else {
            //             setAssignedView(false);
            //             setFilter("personDisplay", userFilterArray);
            //         }
            //     } else {
                    setFilter("personDisplay", userFilterArray);
                // }
                setFilter("isComplete", completeFilterArray);
                setFilter("starDisplay", starFilter);
            } else if (tableType === 'project') {
                // setFilter("starDisplay", starFilter2);
                setFilter("isManagerDisplay", isManagerFilter);
                // setFilter("isSecondDisplay", isSecondFilter);
                setFilter("teamDisplay", userFilterArray);
                setFilter("statusDisplay", statusFilterArray);
                setFilter("yearDisplay", yearFilterArray);
                setFilter("documentDisplay", documentFilterArray);
            }
        }, [tableType, setFilter]);
        // }, [tableType, setFilter, assignedView]);

        function FilterView () {

            if (tableType === 'task' && userFilterArray.length !== 1) {
                return <>
                    <div>&nbsp;</div>
                    <Row className='d-flex align-items-center'>
                        <Col md={4}>
                            <Select 
                                styles={customStyles}
                                options={staffFilterOptions}
                                value={userFilter}
                                onChange={(e)=>handleUserFilter(e)}
                                placeholder='Team member'
                                isMulti
                            />
                        </Col>
                        <Col>
                            <Stack direction="horizontal" gap={3}>
                                {/* <Form.Group >
                                    <Form.Check
                                        type="switch"
                                        className='help-switch'
                                        label="Help"
                                        checked={flagFilter}
                                        onChange={(e) => handleFlagFilter(e.target.checked)}
                                    />
                                </Form.Group> */}
                                <Form.Group className="ms-auto">
                                    <Form.Check
                                        type="switch"
                                        className='star-switch'
                                        label={<span className='star'><i className="fas fa-star"></i></span>}
                                        checked={starFilter}
                                        onChange={(e) => handleStarFilter(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        className='complete-switch'
                                        label="Complete"
                                        checked={completeFilter}
                                        onChange={(e) => handleCompleteFilter(e.target.checked)}
                                    />
                                </Form.Group>
                                <AddTask
                                    currentUser={currentUser}
                                    staffOptions={staffOptions}
                                    projectManagerOptions={projectManagerOptions}
                                    projectOptions={projectOptions}
                                    projectMode={false}
                                    noText={true}
                                    projectData={[...projectData]}
                                    dummyOrg={dummyOrg}
                                    dummyUser={dummyUser}
                                    nextProjectNumber={nextProjectNumber}
                                    handleExit={handleExit}
                                    taskData={[...taskData]}
                                    setTaskData={setTaskData}
                                />
                            </Stack>
                        </Col>
                    </Row>
                    <div>&nbsp;</div>
                </>
            } else if (tableType === 'task' && userFilterArray.length === 1) {
                return <>
                    <div>&nbsp;</div>
                    <Row className='d-flex align-items-center'>
                        <Col md={4}>
                            <Select 
                                styles={customStyles}
                                options={staffFilterOptions}
                                value={userFilter}
                                onChange={(e)=>handleUserFilter(e)}
                                placeholder='Team member'
                                isMulti
                            />
                        </Col>
                        <Col>
                            <Stack direction="horizontal" gap={3}>
                                {/* <Form.Group className="ms-auto">
                                    <Form.Check
                                        type="switch"
                                        label="Assigned View"
                                        checked={assignedView}
                                        onChange={(e) => handleAssignedFilter(e.target.checked)}
                                    />
                                </Form.Group> */}
                                {/* <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        className='help-switch'
                                        label="Help"
                                        checked={flagFilter}
                                        onChange={(e) => handleFlagFilter(e.target.checked)}
                                    />
                                </Form.Group> */}
                                <Form.Group className="ms-auto">
                                    <Form.Check
                                        type="switch"
                                        className='star-switch'
                                        label={<span className='star'><i className="fas fa-star"></i></span>}
                                        checked={starFilter}
                                        onChange={(e) => handleStarFilter(e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        type="switch"
                                        className='complete-switch'
                                        label="Complete"
                                        checked={completeFilter}
                                        onChange={(e) => handleCompleteFilter(e.target.checked)}
                                    />
                                </Form.Group>
                                <AddTask
                                    currentUser={currentUser}
                                    staffOptions={staffOptions}
                                    projectManagerOptions={projectManagerOptions}
                                    projectOptions={projectOptions}
                                    projectMode={false}
                                    noText={true}
                                    projectData={[...projectData]}
                                    dummyOrg={dummyOrg}
                                    dummyUser={dummyUser}
                                    nextProjectNumber={nextProjectNumber}
                                    handleExit={handleExit}
                                    taskData={[...taskData]}
                                    setTaskData={setTaskData}
                                />
                            </Stack>
                        </Col>
                    </Row>
                    <div>&nbsp;</div>
                </>
            } else if (tableType === 'project') {
                return <>
                    <div>&nbsp;</div>
                    <Row className='d-flex align-items-center'>
                        <Col md={4}>
                            <Select 
                                styles={customStyles}
                                options={projectManagerFilterOptions}
                                value={userFilter}
                                onChange={(e)=>handleUserFilter(e)}
                                placeholder='Team member'
                                isMulti
                            />
                        </Col>
                        <Col md={2}>
                            <Select 
                                styles={customStyles}
                                options={statusFilterOptions}
                                value={statusFilter}
                                onChange={(e)=>handleStatusFilter(e)}
                                placeholder='Status'
                                isMulti
                            />
                        </Col>
                        {/* <Col md={2}>
                            <Stack direction="horizontal" gap={3}>
                                {(showManagerFilter) && (
                                    <Form.Group>
                                        <Form.Check
                                            type="switch"
                                            label={<i className="fas fa-scroll"></i>}
                                            checked={isManagerFilter}
                                            onChange={(e) => handleIsManagerFilter(e.target.checked)}
                                        />
                                    </Form.Group>
                                )}
                            </Stack>
                        </Col> */}
                        <Col>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Group className="ms-auto">
                                        <ButtonGroup>
                                            <ToggleButton
                                                variant="outline-dark"
                                                className='cerulean-button'
                                                type="radio"
                                                checked={pipeSwitch}
                                                onClick={() => handlePipeFilter()}
                                            >
                                                Proposals
                                            </ToggleButton>
                                            <ToggleButton
                                                variant="outline-dark"
                                                className='cerulean-button'
                                                type="radio"
                                                checked={currentSwitch}
                                                onClick={() => handleCurrentFilter()}
                                            >
                                                Projects
                                            </ToggleButton>
                                            <ToggleButton
                                                variant="outline-dark"
                                                className='cerulean-button'
                                                type="radio"
                                                checked={databaseSwitch}
                                                onClick={() => handleDatabaseFilter()}
                                            >
                                                Database
                                            </ToggleButton>
                                        </ButtonGroup>
                                </Form.Group>
                                <span>&nbsp;</span>
                                <Form.Group>
                                    <Button
                                        variant="outline-secondary"
                                        className='square-button'
                                        onClick={handleDetailFilterButton}
                                    >
                                        {detailFilterButtonString}
                                    </Button>
                                </Form.Group>
                                <AddProject 
                                    projectData={[...projectData]}
                                    currentUser={currentUser}
                                    dummyOrg={dummyOrg}
                                    dummyUser={dummyUser}
                                    projectManagerOptions={projectManagerOptions}
                                    nextProjectNumber={nextProjectNumber}
                                    taskMode={false}
                                    noText={true}
                                    handleExit={handleExit}
                                />
                            </Stack>
                        </Col>
                    </Row>
                    {showDetailFilters && (
                        <div>
                            <Row className='d-flex align-items-center mt-4'>
                                <Col md={2}>
                                    <Select 
                                        styles={customStyles}
                                        options={yearOptions}
                                        value={yearFilter}
                                        onChange={(e)=>handleYearFilter(e)}
                                        placeholder='Year'
                                        isMulti
                                    />
                                </Col>    
                                <Col md={2}>
                                    <Select 
                                        styles={customStyles}
                                        options={documentOptions}
                                        value={documentFilter}
                                        onChange={(e)=>handleDocumentFilter(e)}
                                        placeholder='Document'
                                        isMulti
                                    />
                                </Col>                        
                            </Row>
                        </div>
                    )}
                    <div>&nbsp;</div>
                </>
            } else {
                return <>
                    <div>&nbsp;</div>
                </>
            }
        }

        function SyncIndicator () {
            if (tableType === 'task') {
                return <>
                    {(taskSubOn) && (
                        <span style={{color:'var(--success)'}}><i className="fas fa-sync-alt"></i></span>
                    )}
                    {(!taskSubOn) && (
                        <span style={{color:'var(--danger)'}}><i className="fas fa-sync-alt"></i></span>
                    )}
                </>
            } else if (tableType === 'project') {
                return <>
                    {(projectSubOn) && (
                        <span style={{color:'var(--success)'}}><i className="fas fa-sync-alt"></i></span>
                    )}
                    {(!projectSubOn) && (
                        <span style={{color:'var(--danger)'}}><i className="fas fa-sync-alt"></i></span>
                    )}
                </>
            } else {
                return <>
                    <div>&nbsp;</div>
                </>
            }
        }

        return (
            <Container>
                <FilterView />
                <Table {...getTableProps()}> 
                    <thead>
                        <tr>
                            <td>
                                <SyncIndicator />
                            </td>
                            <td colSpan={20}>
                                <GlobalFilter
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </td>
                        </tr>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>&nbsp;
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="fas fa-sort-down"></i>
                                        : <i className="fas fa-sort-up"></i>
                                    : ''}
                                </span>
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="pagination">
                    <span>
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-double-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right"></i>
                        </Button>
                        &nbsp;
                        <Button variant="outline-secondary" className='square-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <i className="fas fa-angle-double-right"></i>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            Page&nbsp;
                                <strong>{pageIndex + 1}</strong> of {pageOptions.length}
                        </span>
                    </span>
                </div>
            </Container>
        )
    }   

    let emojiProps = {
        elementCount: 30,
        elementSize: 50,
        angle: 90,
        spread: 45,
        startVelocity: 35,
        decay: 0.94,
        lifetime: 2000,
        emoji: ['🥳'],
    }

    const {reward: emojiReward} = useReward('emojiReward', 'emoji', emojiProps);

    let balloonProps = {
        lifetime: 600,
        spread: 45,
        elementSize: 25,
        startVelocity: 10,
        colors: celebrationColours,
    }

    const {reward: balloonsReward} = useReward('balloonsReward', 'balloons', balloonProps);

    const closeFireworks = () => {
        if (!isModalOpen()) {
            setShowFireworks(false);
        }
    }

    const closeConfetti = () => {
        if (!isModalOpen()) {
            setShowConfetti(false);
        }
    }

    const handleCelebrate = () => {

        var funNum1 = Math.floor(Math.random() * 100);
        if (funNum1 < 50) {
            setCelebrationColours(['#033f57', '#f47a63']);
        } else if (funNum1 < 80) {
            setCelebrationColours(['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3',
            '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B',
            '#FFC107', '#FF9800', '#FF5722', '#795548']);
        } else if (funNum1 < 98) {
            setCelebrationColours(['#ff0000', '#ff7f00', '#ffff00', '#00ff00', '#0000ff', '#4b0082', '#9400d3']);
        } else {
            setCelebrationColours(['#ffffff', '#000000']);
        }

        var funNum2 = Math.floor(Math.random() * 100);
        if (funNum2 < 37) {
            setShowFireworks(true);
            setTimeout(()=> {
                closeFireworks();
            }, 3000);
        } else if (funNum2 < 75) {
            setShowConfetti(true);
            setTimeout(()=> {
                closeConfetti();
            }, 3000);
        } else if (funNum2 < 97) {
            balloonsReward();
        } else {
            emojiReward();
        }
    }

    let fireworkProps = {
        count: 1,
        interval: 200,
        colors: celebrationColours,
        calc: (props, i) => ({
            ...props,
            x: Math.random()*(window.innerWidth / 2) + (window.innerWidth / 4),
            y: Math.random()*(window.innerHeight / 2) + (window.innerHeight / 4),
            bubbleSizeMinimum: 5,
            bubbleSizeMaximum: 10,
            bubbleSpeedMinimum: 5,
            bubbleSpeedMaximum: 25,
        })
    }

    let confettiProps = {
        width: window.innerWidth,
        height: window.innerHeight,
        numberOfPieces: 300,
        confettiSource: {
            x: 0,
            y: 0,
            w: window.innerWidth,
            h: 0,
        },
        friction: 0.99,
        wind: 0,
        gravity: 0.1,
        initialVelocityX: 4,
        initialVelocityY: 10,
        colors: celebrationColours,
        tweenDuration: 5000,
    }

    const handleTabSwitch = (k) => {
        setKey(k);
        if (k === 'tasks') {
            setShowAddTask(true);
            setShowAddProject(false);
            setCompleteFilterArray(['false', 'both']);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        } else if (k === 'projects') {
            setShowAddTask(false);
            setShowAddProject(true);
            setTableSortArray([{
                id: "deadlineDisplay",
                desc: false
            }]);
        }
        handleExit();
    }

    const [userFilter2, setUserFilter2] = useState({
        "value" : currentUser.id,
        "label" : currentUser.attributes.firstName + ' ' + currentUser.attributes.lastName,
    });

    function handleUserFilter2(e) {
        setUserFilter(e);
        var data2 = [];
        setUserFilterArray(data2);
        setUserFilter2(e);
    }

    function hideLastPopover() {
        if (document.getElementsByClassName("show popover").length > 0) {
            document.getElementsByClassName("show popover")[0].classList.remove("show");
        }
    }

    const TaskCard = (props) => {

        var parentofItem = {
            values: []
        };
        parentofItem.values = props.item;

        var cardRow, cardContents, actionRow, cardPopover;

        if (props.item.isRecurring) {
            actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}&nbsp;</span><i style={{color:'var(--teal)'}} className="fas fa-history"></i></div>; 
        } else {
            actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}</span></div>;
        }
            
        switch (props.assigned) {
            case 'to':
                cardContents =
                    <div>
                        {actionRow}
                        <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</div>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>From: {props.item.assignedDisplay}</div>
                    </div>;
                break;
            case 'by':
                cardContents =
                    <div>
                        {actionRow}
                        <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</div>
                        <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>Assigned to: {props.item.personNameDisplay}</div>
                    </div>;
                break;
            default:
                cardContents =
                    <div>
                        {actionRow}
                        <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</div>
                    </div>;
                break;
        }

        cardPopover = 
        <Popover id="popover-basic" className='home-pop'>
            <Popover.Header as="h3">Due: {props.item.deadlineDisplay}</Popover.Header>
            <Popover.Body>
                <Stack direction='horizontal' gap={1}>
                    <EditTask
                        row={parentofItem}
                        staffOptions={staffOptions}
                        projectOptions={projectOptions}
                        currentUser={currentUser}
                        hideLastPopover={hideLastPopover}
                        fromDashboard={true}
                        handleExit={handleExit}
                    />
                    <CompleteTask
                        row={parentofItem}
                        hideLastPopover={hideLastPopover}
                        fromDashboard={true}
                        handleCelebrate={handleCelebrate}
                        handleExit={handleExit}
                    />
                    <RecurringHistory
                        row={parentofItem}
                        insideComplete={false}
                        hideLastPopover={hideLastPopover}
                        fromDashboard={true}
                        handleExit={handleExit}
                    />
                </Stack>
            </Popover.Body>
        </Popover>;

        cardRow = 
            <Row className='mb-2'>
                <OverlayTrigger
                    placement="right"
                    trigger={'click'}
                    overlay={cardPopover}
                    onEnter={hideLastPopover}
                >
                    <Card className='taskCard'>
                        {cardContents}
                    </Card>
                </OverlayTrigger>
            </Row>
        
        return <>
            {cardRow}
        </>
    }

    const ProjectCard = (props) => {
        var parentofItem = {
            values: []
        };
        parentofItem.values = props.item;

        var iconString = '';

        if (props.item.personDisplay === userFilter2.value) {
            // iconString = <i style={{color:'var(--text-muted)'}} className="fas fa-scroll"></i>;
            iconString = '';
        } else if (props.item.secondDisplay === userFilter2.value) {
            iconString = <i style={{color:'var(--text-muted)'}} className="fas fa-user-friends"></i>;
        }

        var cardPopover;

        cardPopover = 
            <Popover id={`po-${props.item.id}`} className='home-pop'>
                <Popover.Header as="h6">
                    Allocated: {props.item.personNameDisplay}, Lead: {props.item.secondNameDisplay}, Status: {props.item.statusDisplay}, Due: {props.item.deadlineDisplay}
                </Popover.Header>
                <Popover.Body>
                    <Row>
                        <Col md={12}>
                            {props.item.commentDisplay}
                        </Col> 
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Stack direction='horizontal' gap={1}>
                            <EditProject
                                row={parentofItem}
                                projectManagerOptions={projectManagerOptions}
                                currentUser={currentUser}
                                projectData={[...projectData]}
                                setProjectData={setProjectData}
                                hideLastPopover={hideLastPopover}
                                fromDashboard={true}
                                handleExit={handleExit}
                            />
                            <ProjectTasks
                                row={parentofItem}
                                taskData={[...taskData]}
                                pipeSwitch={pipeSwitch}
                                databaseSwitch={databaseSwitch}
                                staffOptions={staffOptions}
                                projectManagerOptions={projectManagerOptions}
                                projectOptions={projectOptions}
                                currentUser={currentUser}
                                setTaskData={setTaskData}
                                projectData={[...projectData]}
                                dummyOrg={dummyOrg}
                                dummyUser={dummyUser}
                                nextProjectNumber={nextProjectNumber}
                                handleExit={handleExit}
                                hideLastPopover={hideLastPopover}
                                fromDashboard={true}
                                progressMode={false}
                            />
                        </Stack>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>;

        return <>
            <Row className='mb-2'>
                <OverlayTrigger
                    placement="left"
                    trigger={'click'}
                    overlay={cardPopover}
                    onEnter={hideLastPopover}
                    >
                    <Card className='taskCard'>
                        <div>
                            <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay} {iconString}</div>
                        </div>
                    </Card>
                </OverlayTrigger>
            </Row>
        </>
    }

    const HomeSpecialTasks = () => {
        if(isTasksLoaded) {

            const myStarred = taskData.filter((item) => (item.starDisplay === 'true' && item.isComplete !== 'true' 
                && item.personDisplay === userFilter2.value));
            const starredItems = myStarred.map(item => <TaskCard item={item} assigned={false}/>);

            const assingedTasks = taskData.filter((item) => (item.assignedIdDisplay.length > 0 && item.isComplete !== 'true'));

            const assingedToMe = assingedTasks.filter((item) => item.personDisplay === userFilter2.value);
            const assignedToMeItems = assingedToMe.map(item => <TaskCard item={item} assigned={'to'}/>);

            const assingedByMe = assingedTasks.filter((item) => item.assignedIdDisplay === userFilter2.value);
            const assignedByMeItems = assingedByMe.map(item => <TaskCard item={item} assigned={'by'}/>);

            return <>
                <Card className='mb-4 star-card'>
                    <Card.Header as="h5"><i className="fas fa-star"></i> Tasks</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {starredItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                
                <Card className='mb-4 highlight-card-3'>
                    <Card.Header as="h5">Team <i className="fas fa-arrow-right"></i> Me</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {assignedToMeItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-1'>
                    <Card.Header as="h5">Me <i className="fas fa-arrow-right"></i> Team</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {assignedByMeItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        } else {
            return <>
                <em>Tasks loading...</em>
            </>
        }
    }

    const HomeTasks = () => {
        if(isTasksLoaded) {
            const today = new Date();
            const twentyfour = new Date(today);
            twentyfour.setDate(twentyfour.getDate() + 1);
            const fourtyeight = new Date(today);
            fourtyeight.setDate(fourtyeight.getDate() + 2);
            const minustwentyfour = new Date(today);
            minustwentyfour.setDate(minustwentyfour.getDate() - 1);
            const myTasks = taskData.filter((item) => (item.personDisplay === userFilter2.value && item.isComplete !== 'true'));

            const todayTasks = myTasks.filter((item) => (item.deadlineValueDisplay < twentyfour));
            const todayItems = todayTasks.map(item => <TaskCard item={item} assigned={false}/>);

            const completeTasks = taskData.filter((item) => ((item.personDisplay === userFilter2.value) && 
                (item.isComplete === 'true' || item.isComplete === 'both') && (item.completeValueDisplay > minustwentyfour)));
            const completeItems = completeTasks.map(item => <TaskCard item={item} assigned={false}/>);

            const tomorrowTasks = myTasks.filter((item) => (item.deadlineValueDisplay >= twentyfour && item.deadlineValueDisplay < fourtyeight));
            const tomorrowItems = tomorrowTasks.map(item => <TaskCard item={item} assigned={false}/>);

            return <>
                 <Card className='mb-4 highlight-card-1'>
                     <Card.Header className='inline-button-container' as="h5">
                         Today Tasks
                         <AddTask
                            currentUser={currentUser}
                            staffOptions={staffOptions}
                            projectManagerOptions={projectManagerOptions}
                            projectOptions={projectOptions}
                            projectMode={true}
                            noText={true}
                            projectData={[...projectData]}
                            dummyOrg={dummyOrg}
                            dummyUser={dummyUser}
                            nextProjectNumber={nextProjectNumber}
                            handleExit={handleExit}
                            taskData={[...taskData]}
                            setTaskData={setTaskData}
                        />
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {todayItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-2'>
                    <Card.Header as="h5">
                        Completed Tasks
                        </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {completeItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-3'>
                    <Card.Header as="h5">
                        Tomorrow Tasks
                        </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {tomorrowItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        } else {
            return <>
                
            </>
        }
    }

    const HomeFees = () => {
        if(isProjectsLoaded) {
            const today = new Date();
            const fourtyeight = new Date(today);
            fourtyeight.setDate(fourtyeight.getDate() + 2);

            const myFees = projectData.filter((item) => (((item.personDisplay === userFilter2.value) || (item.secondDisplay === userFilter2.value)) && 
                (item.statusDisplay === 'Proposal' || item.statusDisplay === 'Issued')));

            myFees.sort(
                (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);

            myFees.sort(
                (p1, p2) => (p1.isManagerDisplay < p2.isManagerDisplay) ? 1 : (p1.isManagerDisplay > p2.isManagerDisplay) ? -1 : 0);

            const quotingFees = myFees.filter((item) => (item.statusDisplay === 'Proposal'));
            const quotingItems = quotingFees.map(item => <ProjectCard item={item}/>);
            
            const followupFees = myFees.filter((item) => (item.statusDisplay === 'Issued'));
            const followupItems = followupFees.map(item => <ProjectCard item={item}/>);

    
            return <>
                <Card className='mb-4 highlight-card-3'>
                    <Card.Header className='inline-button-container' as="h5">
                        Proposals
                        <AddProject 
                            projectData={[...projectData]}
                            currentUser={currentUser}
                            dummyOrg={dummyOrg}
                            dummyUser={dummyUser}
                            projectManagerOptions={projectManagerOptions}
                            nextProjectNumber={nextProjectNumber}
                            taskMode={false}
                            noText={true}
                            fromDashboard={true}
                            handleExit={handleExit}
                        /> 
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {quotingItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-1'>
                    <Card.Header as="h5">Issued Proposals</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {followupItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        } else {
            return <>
                <em>Projects loading...</em>
            </>
        }
    }

    const HomeProjects = () => {
        if(isProjectsLoaded) {
            const today = new Date();

            const myProjects = projectData.filter((item) => (((item.personDisplay === userFilter2.value) || (item.secondDisplay === userFilter2.value)) && 
                (item.statusDisplay === 'Allocated' || item.statusDisplay === 'Quality Assurance' || item.statusDisplay === 'Draft Sent' 
                || item.statusDisplay === 'To Invoice')));  

            myProjects.sort(
                (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);

            myProjects.sort(
                (p1, p2) => (p1.isManagerDisplay < p2.isManagerDisplay) ? 1 : (p1.isManagerDisplay > p2.isManagerDisplay) ? -1 : 0);

            
            const allocatedProjects = myProjects.filter((item) => (item.statusDisplay === 'Allocated'));
            const allocatedItems = allocatedProjects.map(item => <ProjectCard item={item}/>);

            const QAProjects = myProjects.filter((item) => (item.statusDisplay === 'Quality Assurance'));
            const QAItems = QAProjects.map(item => <ProjectCard item={item}/>);

            const draftProjects = myProjects.filter((item) => (item.statusDisplay === 'Draft Sent'));
            const draftItems = draftProjects.map(item => <ProjectCard item={item}/>);

            const toProjects = myProjects.filter((item) => (item.statusDisplay === 'To Invoice'));
            const toItems = toProjects.map(item => <ProjectCard item={item}/>);
            
            return <>
                <Card className='mb-4 highlight-card-1'>
                    <Card.Header as="h5">Allocated Projects</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {allocatedItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-3'>
                    <Card.Header as="h5">QA Projects</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {QAItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-1'>
                    <Card.Header as="h5">Draft Sent Projects</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {draftItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='mb-4 highlight-card-2'>
                    <Card.Header as="h5">To Invoice Projects</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {toItems}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        } else {
            return <>
                
            </>
        }
    }

    const HomeDashboard = () => {
        return <>
            <Row>
                &nbsp;
            </Row>
            <Row>
                &nbsp;
            </Row>
            <Row>
                <Col md={3}>
                    <HomeSpecialTasks />
                </Col>
                <Col md={3}>
                    <HomeTasks />
                </Col>
                <Col md={3}>
                    <HomeFees />
                </Col>
                <Col md={3}>
                    <HomeProjects />
                </Col>
            </Row>
            <Row>
                &nbsp;
            </Row>
            <Row>
                <Col md={4}>
                    <Select 
                        styles={customStyles}
                        menuPlacement="top"
                        options={projectManagerOptions}
                        value={userFilter2}
                        onChange={(e)=>handleUserFilter2(e)}
                        placeholder='Team member'
                    />
                </Col>
            </Row>
        </>
    }

    // const [showTeamProjects, setShowTeamProjects] = useState(true);
    // const [showTeamFees, setShowTeamFees] = useState(false);
    // const [showTeamTasks, setShowTeamTasks] = useState(false);

    // let defaultTeam = getDefaultTeam();

    // const [teamFilter, setTeamFilter] = useState(defaultTeam);

    // function handleTeamFilter(e) {
    //     setTeamFilter(e);
    // }

    // const TeamTaskCard = (props) => {
    //     var cardRow, cardContents, actionRow;

    //     if (props.item.isRecurring) {
    //         actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}&nbsp;</span><i style={{color:'var(--teal)'}} className="fas fa-history"></i></div>; 
    //     } else {
    //         actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}</span></div>;
    //     }
            
    //     cardContents =
    //         <div>
    //             <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.personNameDisplay}</div>
    //             {actionRow}
    //             <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</div>
    //         </div>;

    //     cardRow = 
    //         <Row className='mb-2'>
    //             <OverlayTrigger
    //                 placement={props.toolpos}
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={
    //                     <Tooltip id={`tt-${props.item.id}`} className='team-tool'>
    //                         {props.item.deadlineDisplay}
    //                     </Tooltip>}
    //                 >
    //                 <Card className='taskCard'>
    //                     {cardContents}
    //                 </Card>
    //             </OverlayTrigger>
    //         </Row>
        
    //     return <>
    //         {cardRow}
    //     </>
    // }

    // const TeamProjectCard = (props) => {
    //     const today = new Date();

    //     var cardClass = 'taskCard';

    //     if (props.item.deadlineValueDisplay < today) {
    //         cardClass = 'taskCard overdueCard'
    //     }

    //     const pmString = props.item.personNameDisplay.split(" ").map((n)=>n[0]).join("");
    //     const secondString = props.item.secondNameDisplay.split(" ").map((n)=>n[0]).join("");
    //     // const pmString = props.item.personNameDisplay.split(" ")[0];
    //     // const secondString = props.item.secondNameDisplay.split(" ")[0];

    //     return <>
    //         <Row className='mb-2'>
    //             <OverlayTrigger
    //                 placement={props.toolpos}
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={
    //                     <Popover id={`po-${props.item.id}`} className='team-pop'>
    //                         <Popover.Header as="h6">
    //                             Pilot: {props.item.personNameDisplay}, Copilot: {props.item.secondNameDisplay}, Due: {props.item.deadlineDisplay}
    //                         </Popover.Header>
    //                         <Popover.Body>
    //                             {props.item.commentDisplay}
    //                         </Popover.Body>
    //                     </Popover>}
    //                 >
    //                 <Card className={cardClass}>
    //                     <div>
    //                         <div style={{color:'var(--text)', fontSize:'0.8em'}}>{pmString} <span style={{color:'var(--text-muted)'}}>{secondString}</span> {props.item.projectDisplay}</div>
    //                     </div>
    //                 </Card>
    //             </OverlayTrigger>
    //         </Row>
    //     </>
    // }

    // const TeamProjects = () => {
    //     if(isProjectsLoaded) {

    //         let thisTeamProjects;

    //         switch (teamFilter.value) {
    //             case 'fire':
    //                 thisTeamProjects = projectData.filter((item) => (fire.includes(item.personDisplay)));
    //                 break;
    //             case 'security':
    //                 thisTeamProjects = projectData.filter((item) => (security.includes(item.personDisplay)));
    //                 break;
    //             default:
    //                 thisTeamProjects = projectData;
    //                 break;
    //         }

    //         const teamProjects = thisTeamProjects.filter((item) => ((item.statusDisplay === 'Active' || item.statusDisplay === 'Site Inspection' || item.statusDisplay === 'Internal Review' 
    //             || item.statusDisplay === 'C10 Review' || item.statusDisplay === 'SD Review' || item.statusDisplay === 'On hold')));  

    //         teamProjects.sort(
    //             (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);
                
    //         const activeProjects = teamProjects.filter((item) => (item.statusDisplay === 'Active'));

    //         let oneThird = Math.ceil(activeProjects.length / 3);
    //         let twoThird = oneThird * 2;
    //         let active1 = activeProjects.slice(0, oneThird).map(item => <TeamProjectCard item={item} toolpos={'right'}/>);
    //         let active2 = activeProjects.slice(oneThird, twoThird).map(item => <TeamProjectCard item={item} toolpos={'right'}/>);
    //         let active3 = activeProjects.slice(twoThird, activeProjects.length).map(item => <TeamProjectCard item={item} toolpos={'left'}/>);

    //         const holdProjects = teamProjects.filter((item) => (item.statusDisplay === 'On hold'));

    //         let oneThirdH = Math.ceil(holdProjects.length / 3);
    //         let twoThirdH = oneThirdH * 2;
    //         let hold1 = holdProjects.slice(0, oneThirdH).map(item => <TeamProjectCard item={item} toolpos={'right'}/>);
    //         let hold2 = holdProjects.slice(oneThirdH, twoThirdH).map(item => <TeamProjectCard item={item} toolpos={'right'}/>);
    //         let hold3 = holdProjects.slice(twoThirdH, holdProjects.length).map(item => <TeamProjectCard item={item} toolpos={'left'}/>);
    //         // const holdItems = holdProjects.map(item => <TeamProjectCard item={item} toolpos={'right'}/>);

    //         return <>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">Active Projects (1)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {active1}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">Active Projects (2)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {active2}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">Active Projects (3)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {active3}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">Projects on Hold (1)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {hold1}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">Projects On Hold (2)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {hold2}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">Projects On Hold (3)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {hold3}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects loading...</em>
    //         </>
    //     }
    // }

    // const TeamReviewCard = (props) => {
    //     const today = new Date();

    //     var cardClass = 'taskCard';

    //     if (props.item.deadlineValueDisplay < today) {
    //         cardClass = 'taskCard overdueCard'
    //     }

    //     const pmString = props.item.personNameDisplay.split(" ")[0];
    //     const secondString = props.item.secondNameDisplay.split(" ")[0];

    //     var parentofItem = {
    //         values: []
    //     };
    //     parentofItem.values = props.item;

    //     return <>
    //         <Row className='mb-2'>
    //             <Card className={cardClass}>
    //                 <Row>
    //                     <Col md={10}>
    //                         <div>
    //                             <div style={{color:'var(--text)', fontSize:'0.8em'}}><strong>{pmString}</strong> {props.item.projectDisplay}</div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>{secondString}
    //                                 &nbsp;&nbsp;&nbsp;&nbsp;Due: {props.item.deadlineDisplay}</div>
    //                         </div>
    //                     </Col>
    //                     <Col md={2}>
    //                         <EditProjectBid
    //                             row={parentofItem}
    //                             projectManagerOptions={projectManagerOptions}
    //                             currentUser={currentUser}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col md={12}>
    //                         <EditProjectComment
    //                             row={parentofItem}
    //                             cell={props.item.commentDisplay}
    //                             fromDashboard={true}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </Row>
    //     </>
    // }

    // const TeamReview = () => {
    //     if(isProjectsLoaded) {

    //         const reviewProjects = projectData.filter((item) => ((item.statusDisplay === 'Internal Review' 
    //             || item.statusDisplay === 'C10 Review' || item.statusDisplay === 'SD Review')));  

    //         reviewProjects.sort(
    //             (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);
                
    //         const internalProjects = reviewProjects.filter((item) => (item.statusDisplay === 'Internal Review'));  
    //         const internalItems = internalProjects.map(item => <TeamReviewCard item={item}/>);

    //         const c10Projects = reviewProjects.filter((item) => (item.statusDisplay === 'C10 Review'));  
    //         const c10Items = c10Projects.map(item => <TeamReviewCard item={item}/>);

    //         const sdProjects = reviewProjects.filter((item) => (item.statusDisplay === 'SD Review'));  
    //         const sdItems = sdProjects.map(item => <TeamReviewCard item={item}/>);

    //         return <>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">Internal Review</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {internalItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">C10 Review</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {c10Items}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">SD Review</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {sdItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects loading...</em>
    //         </>
    //     }
    // }

    // const TeamFees = () => {
    //     if(isProjectsLoaded) {

    //         let thisTeamProjects;

    //         switch (teamFilter.value) {
    //             case 'fire':
    //                 thisTeamProjects = projectData.filter((item) => (fire.includes(item.personDisplay)));
    //                 break;
    //             case 'security':
    //                 thisTeamProjects = projectData.filter((item) => (security.includes(item.personDisplay)));
    //                 break;
    //             default:
    //                 thisTeamProjects = projectData;
    //                 break;
    //         }

    //         const teamFees = thisTeamProjects.filter((item) => ((item.statusDisplay === 'Quoting' || item.statusDisplay === 'Undecided' 
    //         || item.statusDisplay === 'Lead' || item.statusDisplay === 'FP on hold')));  

    //         teamFees.sort(
    //             (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);
                
    //         const quotingFees = teamFees.filter((item) => (item.statusDisplay === 'Quoting' || item.statusDisplay === 'Lead'));
    //         const quotingItems = quotingFees.map(item => <TeamProjectCard item={item} toolpos={'right'}/>);

    //         const undecidedFees = teamFees.filter((item) => (item.statusDisplay === 'Undecided'));
    //         const undecidedItems = undecidedFees.map(item => <TeamProjectCard item={item} toolpos={'left'}/>);

    //         let halfwayThrough = Math.ceil(undecidedItems.length / 2)
    //         let undecidedFirstHalf = undecidedItems.slice(0, halfwayThrough);
    //         let undecidedSecondHalf = undecidedItems.slice(halfwayThrough, undecidedItems.length);

    //         const holdFees = teamFees.filter((item) => (item.statusDisplay === 'FP on hold'));
    //         const holdItems = holdFees.map(item => <TeamProjectCard item={item} toolpos={'right'}/>);

    //         return <>
    //             <Col md={4}>
    //                 <Card className='mb-4 star-card'>
    //                     <Card.Header as="h5">Quoting</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {quotingItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //                 <Card className='mb-4 highlight-card-3'>
    //                     <Card.Header as="h5">Fees on hold</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {holdItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //             <Col md={4}>
    //                 <Card className='mb-4 highlight-card-2'>
    //                     <Card.Header as="h5">Undecided Fees (1)</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {undecidedFirstHalf}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //             <Col md={4}>
    //                 <Card className='mb-4 highlight-card-1'>
    //                     <Card.Header as="h5">Undecided Fees (2)</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {undecidedSecondHalf}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Loading projects...</em>
    //         </>
    //     }
    // }

    // const TeamTasks = () => {
    //     if(isTasksLoaded) {

    //         let thisTeamTasks;

    //         switch (teamFilter.value) {
    //             case 'fire':
    //                 thisTeamTasks = taskData.filter((item) => (fire.includes(item.personDisplay)));
    //                 break;
    //             case 'security':
    //                 thisTeamTasks = taskData.filter((item) => (security.includes(item.personDisplay)));
    //                 break;
    //             default:
    //                 thisTeamTasks = taskData;
    //                 break;
    //         }

    //         const starred = thisTeamTasks.filter((item) => (item.starDisplay === 'true' && item.isComplete !== 'true'));
    //         const starredItems = starred.map(item => <TeamTaskCard item={item} toolpos={'left'} />);

    //         const today = new Date();
    //         const twentyfour = new Date(today);
    //         twentyfour.setDate(twentyfour.getDate() + 1);
    //         const fourtyeight = new Date(today);
    //         fourtyeight.setDate(fourtyeight.getDate() + 2);

    //         const teamTasks = thisTeamTasks.filter((item) => (item.isComplete !== 'true'));

    //         const overdueTasks = teamTasks.filter((item) => item.deadlineValueDisplay < today);
    //         const overdueItems = overdueTasks.map(item => <TeamTaskCard item={item} assigned={false} toolpos={'right'}/>);

    //         const todayTasks = teamTasks.filter((item) => (item.deadlineValueDisplay >= today && item.deadlineValueDisplay < twentyfour));
    //         const todayItems = todayTasks.map(item => <TeamTaskCard item={item} assigned={false} toolpos={'right'}/>);

    //         const tomorrowTasks = teamTasks.filter((item) => (item.deadlineValueDisplay >= twentyfour && item.deadlineValueDisplay < fourtyeight));
    //         const tomorrowItems = tomorrowTasks.map(item => <TeamTaskCard item={item} assigned={false} toolpos={'left'}/>);

    //         return <>
    //             <Col md={4}>
    //                 <Card className='mb-4 overdue-parent-card'>
    //                     <Card.Header as="h5">Overdue Tasks</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {overdueItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //             <Col md={4}>
    //                 <Card className='mb-4 highlight-card-1'>
    //                     <Card.Header as="h5">Today Tasks</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {todayItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //             <Col md={4}>
    //                 <Card className='mb-4 star-card'>
    //                     <Card.Header as="h5"><i className="fas fa-star"></i> Tasks</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {starredItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //                 <Card className='mb-4 highlight-card-3'>
    //                     <Card.Header as="h5">Tomorrow Tasks</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {tomorrowItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Tasks loading...</em>
    //         </>
    //     }
    // }

    // function handleTeamProjects() {
    //     setShowTeamProjects(true);
    //     setShowTeamFees(false);
    //     setShowTeamTasks(false);
    // }

    // function handleTeamFees() {
    //     setShowTeamProjects(false);
    //     setShowTeamFees(true);
    //     setShowTeamTasks(false);
    // }

    // function handleTeamTasks() {
    //     setShowTeamProjects(false);
    //     setShowTeamFees(false);
    //     setShowTeamTasks(true);
    // }

    // const TeamDashboard = () => {

    //     return <>
    //         <Row>
    //             &nbsp;
    //         </Row>
    //         <Row className='mb-3'>
    //             <Col md={4}>
    //                 <Select 
    //                     styles={customStyles}
    //                     options={teamOptions}
    //                     value={teamFilter}
    //                     onChange={(e)=>handleTeamFilter(e)}
    //                     placeholder='Team Select'
    //                 />
    //             </Col>
    //             <Col md={4}>
    //                 <Stack direction="horizontal" gap={3}>
    //                     <Form.Group>
    //                         <ButtonGroup>
    //                             <ToggleButton
    //                                 variant="outline-dark"
    //                                 className='cerulean-button'
    //                                 type="radio"
    //                                 checked={showTeamProjects}
    //                                 onClick={() => handleTeamProjects()}
    //                             >
    //                                 Projects
    //                             </ToggleButton>
    //                             <ToggleButton
    //                                 variant="outline-dark"
    //                                 className='cerulean-button'
    //                                 type="radio"
    //                                 checked={showTeamFees}
    //                                 onClick={() => handleTeamFees()}
    //                             >
    //                                 Fees
    //                             </ToggleButton>
    //                             <ToggleButton
    //                                 variant="outline-dark"
    //                                 className='cerulean-button'
    //                                 type="radio"
    //                                 checked={showTeamTasks}
    //                                 onClick={() => handleTeamTasks()}
    //                             >
    //                                 Tasks
    //                             </ToggleButton>
    //                         </ButtonGroup>
    //                     </Form.Group>
    //                 </Stack>
    //             </Col>
    //             <Col md={4}>
    //                 <Stack direction="horizontal" gap={3}>
    //                     <div>
    //                         <AddTask
    //                             currentUser={currentUser}
    //                             staffOptions={staffOptions}
    //                             projectManagerOptions={projectManagerOptions}
    //                             projectOptions={projectOptions}
    //                             projectMode={false}
    //                             noText={false}
    //                             projectData={[...projectData]}
    //                             dummyOrg={dummyOrg}
    //                             dummyUser={dummyUser}
    //                             nextProjectNumber={nextProjectNumber}
    //                             handleExit={handleExit}
    //                             taskData={[...taskData]}
    //                             setTaskData={setTaskData}
    //                         />
    //                     </div>
    //                     <div className='ms-auto'>
    //                         <AddProject 
    //                             projectData={[...projectData]}
    //                             currentUser={currentUser}
    //                             dummyOrg={dummyOrg}
    //                             dummyUser={dummyUser}
    //                             projectManagerOptions={projectManagerOptions}
    //                             nextProjectNumber={nextProjectNumber}
    //                             taskMode={false}
    //                             noText={false}
    //                             handleExit={handleExit}
    //                         />
    //                     </div>
    //                 </Stack>
    //             </Col>
    //         </Row>
    //         {/* <Row> */}
    //             {showTeamProjects && (
    //                 <TeamProjects />
    //             )}
    //         {/* </Row> */}
    //         <Row>
    //             {showTeamFees && (
    //                 <TeamFees />
    //             )}
    //         </Row>
    //         <Row>
    //             {showTeamTasks && (
    //                 <TeamTasks />
    //             )}
    //         </Row>
    //     </>
    // }

    const AdminDashboard = () => {
        return <>
            <Row>
                Admin dashboard goes here
            </Row>
        </>
    }

    // const InspectionTaskCard = (props) => {
    //     var cardRow, cardContents, actionRow;

    //     if (props.item.isRecurring) {
    //         actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}&nbsp;<i style={{color:'var(--teal)'}} className="fas fa-history"></i>&nbsp;–&nbsp;{props.item.deadlineDisplay}</span></div>; 
    //     } else {
    //         actionRow = <div><span style={{color:'var(--text)', fontSize:'1em'}}>{props.item.actionDisplay}&nbsp;–&nbsp;{props.item.deadlineDisplay}</span></div>;
    //     }
            
    //     cardContents =
    //         <div>
    //             <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.personNameDisplay}</div>
    //             {actionRow}
    //             <div style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</div>
    //         </div>;

    //     cardRow = 
    //         <Row className='mb-2'>
    //             <OverlayTrigger
    //                 placement={props.toolpos}
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={
    //                     <Tooltip id={`tt-${props.item.id}`} className='team-tool'>
    //                         {props.item.deadlineDisplay}
    //                     </Tooltip>}
    //                 >
    //                 <Card className='taskCard'>
    //                     {cardContents}
    //                 </Card>
    //             </OverlayTrigger>
    //         </Row>
        
    //     return <>
    //         {cardRow}
    //     </>
    // }

    // const TeamInspection = () => {
    //     if(isProjectsLoaded && isTasksLoaded) {

    //         const inspectionProjects = projectData.filter((item) => ((item.statusDisplay === 'Site Inspection')));  

    //             inspectionProjects.sort(
    //             (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);
                
    //         const internalProjects = inspectionProjects.filter((item) => (item.statusDisplay === 'Site Inspection'));  
    //         const internalItems = internalProjects.map(item => <TeamReviewCard item={item}/>);

    //         const inspectionTasks2 = taskData.filter((item) => (item.isComplete !== 'true'));

    //         const inspectionTasks = inspectionTasks2.filter((item) => (item.actionTextDisplay.toLowerCase().includes('inspection')));
    //         const inspectionItems = inspectionTasks.map(item => <InspectionTaskCard item={item} toolpos={'left'} />);

    //         return <>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">Projects</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {internalItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">Tasks</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {inspectionItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects and tasks loading...</em>
    //         </>
    //     }
    // }

    // const InspectionDashboard = () => {
    //     return <>
    //         <Row>
    //             &nbsp;
    //         </Row>
    //         <TeamInspection />
    //     </>
    // }

    // const FeeCard = (props) => {
    //     const today = new Date();

    //     var cardClass = 'taskCard';

    //     if (props.item.deadlineValueDisplay < today) {
    //         cardClass = 'taskCard overdueCard'
    //     }

    //     const pmString = props.item.personNameDisplay.split(" ")[0];
    //     const secondString = props.item.secondNameDisplay.split(" ")[0];

    //     var parentofItem = {
    //         values: []
    //     };
    //     parentofItem.values = props.item;

    //     return <>
    //         <Row className='mb-2'>
    //             <Card className={cardClass}>
    //                 <Row>
    //                     <Col md={10}>
    //                         <div>
    //                             <div style={{color:'var(--text)', fontSize:'0.8em'}}><strong>{pmString}</strong> {props.item.projectDisplay}</div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>{secondString}
    //                                 &nbsp;&nbsp;&nbsp;&nbsp;Due: {props.item.deadlineDisplay}</div>
    //                         </div>
    //                     </Col>
    //                     <Col md={2}>
    //                         <EditProjectBid
    //                             row={parentofItem}
    //                             projectManagerOptions={projectManagerOptions}
    //                             currentUser={currentUser}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col md={12}>
    //                         <EditProjectComment
    //                             row={parentofItem}
    //                             cell={props.item.commentDisplay}
    //                             fromDashboard={true}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </Row>
    //     </>
    // }

    // const AllFees = () => {
    //     if(isProjectsLoaded) {
    //         const today = new Date();
    //         const fourtyeight = new Date(today);
    //         fourtyeight.setDate(fourtyeight.getDate() + 2);

    //         const teamFees = projectData.filter((item) => ((item.statusDisplay === 'Quoting' || item.statusDisplay === 'Undecided' 
    //         || item.statusDisplay === 'Lead' || item.statusDisplay === 'FP on hold')));  

    //         teamFees.sort(
    //             (p1, p2) => (p1.deadlineValueDisplay > p2.deadlineValueDisplay) ? 1 : (p1.deadlineValueDisplay < p2.deadlineValueDisplay) ? -1 : 0);
                
    //         const quotingFees = teamFees.filter((item) => (item.statusDisplay === 'Quoting'));
    //         const quotingItems = quotingFees.map(item => <FeeCard item={item}/>);

    //         const holdFees = teamFees.filter((item) => (item.statusDisplay === 'FP on hold'));
    //         const holdItems = holdFees.map(item => <FeeCard item={item}/>);

    //         const overdueFees = teamFees.filter((item) => ((item.statusDisplay === 'Undecided' || item.statusDisplay === 'Lead') && item.deadlineValueDisplay < fourtyeight));
    //         const overdueItems = overdueFees.map(item => <FeeCard item={item}/>);

    //         let halfwayThrough = Math.ceil(overdueItems.length / 2)
    //         let overdueFirstHalf = overdueItems.slice(0, halfwayThrough);
    //         let overdueSecondHalf = overdueItems.slice(halfwayThrough, overdueItems.length);

    //         const followFees = teamFees.filter((item) => ((item.statusDisplay === 'Undecided' || item.statusDisplay === 'Lead') && item.deadlineValueDisplay >= fourtyeight));

    //         const firFees = followFees.filter((item) => (fire.includes(item.personDisplay)));
    //         const firItems = firFees.map(item => <FeeCard item={item}/>);

    //         const secFees = followFees.filter((item) => (security.includes(item.personDisplay)));
    //         const secItems = secFees.map(item => <FeeCard item={item}/>);

    //         let halfwaySec = Math.ceil(secItems.length / 2)
    //         let secFirstHalf = secItems.slice(0, halfwaySec);
    //         let secSecondHalf = secItems.slice(halfwaySec, secItems.length);

    //         return <>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">
    //                             To Issue
    //                             <AddProject 
    //                                 projectData={[...projectData]}
    //                                 currentUser={currentUser}
    //                                 dummyOrg={dummyOrg}
    //                                 dummyUser={dummyUser}
    //                                 projectManagerOptions={projectManagerOptions}
    //                                 nextProjectNumber={nextProjectNumber}
    //                                 taskMode={false}
    //                                 noText={true}
    //                                 fromDashboard={true}
    //                                 handleExit={handleExit}
    //                             /> 
    //                         </Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {quotingItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">
    //                             On Hold
    //                         </Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {holdItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">Follow Up Now (1)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {overdueFirstHalf}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">Follow Up Now (2)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {overdueSecondHalf}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">Future Follow up - Fire &#128293;</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {firItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">Future Follow up - Security &#128274; (1)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {secFirstHalf}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">Future Follow up - Security &#128274; (2)</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {secSecondHalf}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects loading...</em>
    //         </>
    //     }
    // }

    // const BidDashboard = () => {
    //     return <>
    //         <Row>
    //             &nbsp;
    //         </Row>
    //         {/* <Row> */}
    //             <AllFees />
    //         {/* </Row> */}
    //     </>
    // }

    // const FeedbackProjectCard = (props) => {
    //     // const pmString = props.item.personNameDisplay.split(" ").map((n)=>n[0]).join("");
    //     const pmString = props.item.personNameDisplay.split(" ")[0];
    //     var parentofItem = {
    //         values: []
    //     };
    //     parentofItem.values = props.item;
    //     return <>
    //         <Row className='mb-2'>
    //             <Card className='taskCard'>
    //                 <Row>
    //                     <Col md={2}>
    //                         <ProjectClient
    //                                 row={parentofItem}
    //                                 handleExit={handleExit}
    //                                 progressMode={false}
    //                                 users={users}
    //                                 setUsers={setUsers}
    //                                 userOptions={userOptions}
    //                                 setUserOptions={setUserOptions}
    //                                 orgs={orgs}
    //                                 setOrgs={setOrgs}
    //                                 orgOptions={orgOptions}
    //                                 setOrgOptions={setOrgOptions}
    //                                 projectData={[...projectData]}
    //                                 setProjectData={setProjectData}
    //                             />
    //                     </Col>
    //                     <Col md={2}>
    //                         <span style={{color:'var(--text)', fontSize:'0.8em'}}>{pmString}</span>
    //                     </Col>
    //                     <Col md={6}>
    //                         <span style={{color:'var(--text)', fontSize:'0.8em'}}>{props.item.projectDisplay}</span>
    //                     </Col>
    //                     <Col md={2}>
    //                         <EditProjectFeedback
    //                             row={parentofItem}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </Row>
    //     </>
    // }

    // const FeedbackProjects = () => {
    //     if(allProjectsLoaded && isProjectsLoaded) {

    //         const teamProjects = projectData.filter((item) => ((item.feedbackDisplay === 'To send' || item.feedbackDisplay === 'Feedback sent')));  
                
    //         const toProjects = teamProjects.filter((item) => (item.feedbackDisplay === 'To send'));
    //         const toItems = toProjects.map(item => <FeedbackProjectCard item={item}/>);

    //         const sentProjects = teamProjects.filter((item) => (item.feedbackDisplay === 'Feedback sent'));
    //         const sentItems = sentProjects.map(item => <FeedbackProjectCard item={item}/>);

    //         return <>
    //             <Col md={6}>
    //                 <Card className='mb-4'>
    //                     <Card.Header as="h5">To send feedback</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {toItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //             <Col md={6}>
    //                 <Card className='mb-4'>
    //                     <Card.Header as="h5">Feedback sent</Card.Header>
    //                     <Card.Body>
    //                         <Card.Text>
    //                             {sentItems}
    //                         </Card.Text>
    //                     </Card.Body>
    //                 </Card>
    //             </Col>
    //         </>
    //     } else if (isProjectsLoaded && !allProjectsLoaded && userIsAdmin && !databaseSwitch) {
    //             return <>
    //                 <Row>
    //                     <Col md={5}>
    //                         &nbsp;
    //                     </Col>
    //                     <Col md={2}>
    //                         <Button variant="outline-secondary" onClick={() => handleDatabaseFilter()}>
    //                             Query All Projects
    //                         </Button>
    //                     </Col>
    //                     <Col md={5}>
    //                         &nbsp;
    //                     </Col>
    //                 </Row>
    //             </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects loading...</em>
    //         </>
    //     }
    // }

    // const AdminDashboard = () => {
    //     return <>
    //         <Row>
    //             &nbsp;
    //         </Row>
    //         <Row>
    //             <FeedbackProjects />
    //         </Row>
    //     </>
    // // }

    // const DirectorCard = (props) => {
    //     const pmString = props.item.personNameDisplay;
    //     const secondString = props.item.secondNameDisplay;

    //     var parentofItem = {
    //         values: []
    //     };
    //     parentofItem.values = props.item;

    //     return <>
    //         <Row className='mb-2'>
    //             <Card className={'taskCard'}>
    //                 <Row>
    //                     <Col md={12}>
    //                         <div>
    //                             <div style={{color:'var(--text)', fontSize:'0.8em'}}><strong>{pmString}</strong> {props.item.projectDisplay}</div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>{secondString}</div>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //                 <Row>
    //                     <Col md={12}>
    //                         <EditProjectComment
    //                             row={parentofItem}
    //                             cell={props.item.commentDisplay}
    //                             fromDashboard={true}
    //                             handleExit={handleExit}
    //                         />
    //                     </Col>
    //                 </Row>
    //                 <Row className='mt-2'>
    //                     <Col md={6}>
    //                         <div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>Status: {props.item.statusDisplay}</div>
    //                         </div>
    //                     </Col>
    //                     {(props.createMode) && (
    //                         <Col md={6}>
    //                             <div>
    //                                 <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>Requested: {props.item.createDisplay}</div>
    //                             </div>
    //                         </Col>
    //                     )}
    //                 </Row>
    //                 <Row>
    //                     <Col md={6}>
    //                         <div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>Due: {props.item.deadlineDisplay}</div>
    //                         </div>
    //                     </Col>
    //                     <Col md={6}>
    //                         <div>
    //                             <div style={{color:'var(--text-muted)', fontSize:'0.8em'}}>Updated: {props.item.updateDisplay}</div>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             </Card>
    //         </Row>
    //     </>
    // }

    // const DirectorProjects = () => {
    //     if(isProjectsLoaded) {
    //         const today = new Date();
    //         const threedaysback = new Date(today);
    //         threedaysback.setDate(threedaysback.getDate() - 3);
    //         const twoweeksback = new Date(today);
    //         twoweeksback.setDate(twoweeksback.getDate() - 14);
    //         const fourweeksback = new Date(today);
    //         fourweeksback.setDate(fourweeksback.getDate() - 28);

    //         const teamFees = projectData.filter((item) => ((item.statusDisplay === 'Quoting' || item.statusDisplay === 'Undecided' 
    //         || item.statusDisplay === 'Lead' || item.statusDisplay === 'FP on hold')));  

    //         const teamProjects = projectData.filter((item) => ((item.statusDisplay === 'Active' || item.statusDisplay === 'Site Inspection' 
    //         || item.statusDisplay === 'Internal Review' || item.statusDisplay === 'C10 Review' || item.statusDisplay === 'SD Review' 
    //         || item.statusDisplay === 'On hold')));  

    //         const teamFees2 = projectData.filter((item) => ((item.statusDisplay === 'Quoting' || item.statusDisplay === 'Undecided' 
    //             || item.statusDisplay === 'Lead' || item.statusDisplay === 'FP on hold')));  

    //         teamFees2.sort(
    //             (p1, p2) => (p1.createdValueDisplay < p2.createdValueDisplay) ? 1 : (p1.createdValueDisplay > p2.createdValueDisplay) ? -1 : 0);
    
    //         teamFees.sort(
    //             (p1, p2) => (p1.updatedValueDisplay > p2.updatedValueDisplay) ? 1 : (p1.updatedValueDisplay < p2.updatedValueDisplay) ? -1 : 0);

    //         teamProjects.sort(
    //             (p1, p2) => (p1.updatedValueDisplay > p2.updatedValueDisplay) ? 1 : (p1.updatedValueDisplay < p2.updatedValueDisplay) ? -1 : 0);
                
    //         // const coldFee = teamFees.filter((item) => ((item.statusDisplay === 'Quoting') && (item.updatedValueDisplay < threedaysback)));
    //         // const coldFeeItems = coldFee.map(item => <DirectorCard item={item}/>);

    //         const coldFeesCreate = teamFees2.filter((item) => ((item.statusDisplay === 'Quoting') && (item.createdValueDisplay < threedaysback)));
    //         const coldFeeCreateItems = coldFeesCreate.map(item => <DirectorCard item={item} createMode={true}/>);

    //         const coldFeeHold = teamFees.filter((item) => ((item.statusDisplay === 'Fp on hold') && (item.updatedValueDisplay < twoweeksback)));
    //         const coldFeeHoldItems = coldFeeHold.map(item => <DirectorCard item={item}/>);

    //         const coldFollow = teamFees.filter((item) => ((item.statusDisplay !== 'Quoting') && (fire.includes(item.personDisplay)) && (item.updatedValueDisplay < twoweeksback)));
    //         const coldFollowItems = coldFollow.map(item => <DirectorCard item={item}/>);

    //         const coldSecFollow = teamFees.filter((item) => ((item.statusDisplay !== 'Quoting') && (security.includes(item.personDisplay)) && (item.updatedValueDisplay < twoweeksback)));
    //         const coldSecFollowItems = coldSecFollow.map(item => <DirectorCard item={item}/>);

    //         const coldFireProjects = teamProjects.filter((item) => ((item.statusDisplay !== 'On hold') && (fire.includes(item.personDisplay)) && (item.updatedValueDisplay < fourweeksback)));
    //         const coldFireProjectItems = coldFireProjects.map(item => <DirectorCard item={item}/>);

    //         const coldSecProjects = teamProjects.filter((item) => ((item.statusDisplay !== 'On hold') && (security.includes(item.personDisplay)) && (item.updatedValueDisplay < fourweeksback)));
    //         const coldSecProjectItems = coldSecProjects.map(item => <DirectorCard item={item}/>);

    //         const coldHoldProjects = teamProjects.filter((item) => ((item.statusDisplay === 'On hold') && (item.updatedValueDisplay < fourweeksback)));
    //         const coldHoldProjectItems = coldHoldProjects.map(item => <DirectorCard item={item}/>);

    //         return <>
    //             <Row>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">&#129398; Fees to Issue</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldFeeCreateItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">&#129398; Hold Fees</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldFeeHoldItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     {/* <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">&#129398; Fees to Issue</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldFeeItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card> */}
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">&#129398; Fire Follow up</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldFollowItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">&#129398; Security Follow up</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldSecFollowItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //                 <Col md={4}>
    //                     <Card className='mb-4 highlight-card-3'>
    //                         <Card.Header as="h5">&#129398; Fire Projects</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldFireProjectItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     <Card className='mb-4 highlight-card-1'>
    //                         <Card.Header as="h5">&#129398; Security Projects</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldSecProjectItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                     <Card className='mb-4 highlight-card-2'>
    //                         <Card.Header as="h5">&#129398; Hold Projects</Card.Header>
    //                         <Card.Body>
    //                             <Card.Text>
    //                                 {coldHoldProjectItems}
    //                             </Card.Text>
    //                         </Card.Body>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </>
    //     } else {
    //         return <>
    //             <em>&nbsp;&nbsp;&nbsp;Projects loading...</em>
    //         </>
    //     }
    // }

    // const DirectorDashboard = () => {
    //     return <>
    //         <Row>
    //             &nbsp;
    //         </Row>
    //         {/* <Row> */}
    //             <DirectorProjects />
    //         {/* </Row> */}
    //     </>
    // }
    
    return <>
        {showFireworks && <Fireworks {...fireworkProps} />}
        {showConfetti && <Confetti {...confettiProps} />}
        <div style={{textAlign: 'center'}}>
            <span id='emojiReward'/>
        </div>

        <Container>
                <Tab.Container 
                    activeKey={key}
                    onSelect={(k) => handleTabSwitch(k)} 
                >
                    <Nav justify variant='tabs'>
                        <Nav.Item>
                            <Nav.Link eventKey='home'>Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='tasks'>Tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='projects'>Projects</Nav.Link>
                        </Nav.Item>
                        {/* {(userIsAdmin) && (
                            <Nav.Item>
                                <Nav.Link eventKey='admin'>Admin</Nav.Link>
                            </Nav.Item>
                        )} */}
                        {/* <Nav.Item>
                            <Nav.Link eventKey='inspection'>Inspection</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='review'>Review</Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                            <Nav.Link eventKey='team'>Team</Nav.Link>
                        </Nav.Item> */}
                        {/* {(userIsBidAdmin) && (
                            <Nav.Item>
                                <Nav.Link eventKey='bid'>Pipeline</Nav.Link>
                            </Nav.Item>
                        )}
                        {(userIsAdmin) && (
                            <Nav.Item>
                                <Nav.Link eventKey='admin'>Admin</Nav.Link>
                            </Nav.Item>
                        )}
                        {(userIsBidAdmin) && (
                            <Nav.Item>
                                <Nav.Link eventKey='director'>Cold</Nav.Link>
                            </Nav.Item>
                        )} */}
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey='home'>
                            <div className="table-homepage">
                                <HomeDashboard />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='tasks'>
                            <div className="table-homepage">
                                <div>
                                    <TaskTable />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='projects'>
                            <div className="table-homepage">
                                <div>
                                    <ProjectTable />
                                </div>
                            </div>
                        </Tab.Pane>
                        {(userIsAdmin) && (
                            <Tab.Pane eventKey='admin'>
                                <div className="table-homepage">
                                    <AdminDashboard />
                                </div>
                            </Tab.Pane>
                        )}
                        {/* <Tab.Pane eventKey='team'>
                            <div className="table-homepage">
                                <TeamDashboard />
                            </div>
                        </Tab.Pane> */}
                        {/* <Tab.Pane eventKey='review'>
                            <div className="table-homepage">
                                <ReviewDashboard />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='inspection'>
                            <div className="table-homepage">
                                <InspectionDashboard />
                            </div>
                        </Tab.Pane>
                        {(userIsBidAdmin) && (
                            <Tab.Pane eventKey='bid'>
                                <div className="table-homepage">
                                    <BidDashboard />
                                </div>
                            </Tab.Pane>
                        )}
                        {(userIsAdmin) && (
                            <Tab.Pane eventKey='admin'>
                                <div className="table-homepage">
                                    <AdminDashboard />
                                </div>
                            </Tab.Pane>
                        )}
                        {(userIsBidAdmin) && (
                            <Tab.Pane eventKey='director'>
                                <div className="table-homepage">
                                    <DirectorDashboard />
                                </div>
                            </Tab.Pane>
                        )} */}
                    </Tab.Content>
                </Tab.Container>
        </Container>
        <div style={{textAlign: 'center'}}>
            <span id='balloonsReward'/>
        </div>
    </> 
}
