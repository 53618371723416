import { useState, useEffect, useRef } from 'react';
import { Button, Form, Modal, Row, Col, Spinner, Overlay } from 'react-bootstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { customStyles } from '../../data/Data.js'
import reportError from '../../utils/ReportError';


export default function ProjectClient(props) {

    const Parse = require('parse');

    const target = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const [show, setShow] = useState(false);
    const [hasClient, setHasClient] = useState(false);
    const [uniqueName, setUniqueName] = useState(false);
    const [loadedClient, setLoadedClient] = useState(false);
    const [orgName, setOrgName] = useState(props.row.values.orgDisplay.attributes.name);
    const [clientFirstName, setClientFirstName] = useState('');
    const [clientLastName, setClientLastName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientId, setClientId] = useState('');

    const [addForm, setAddForm] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [addSubmitString, setAddSubmitString] = useState('Save Project Client');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [newOrg, setNewOrg] = useState('');
    const [organisation, setOrganisation] = useState(null);

    const [foldForm, setFoldForm] = useState(false);
    const [foldButtonString, setFoldButtonString] = useState(<i className="fas fa-angle-up"></i>);
    const [formExisting, setFormExisting] = useState(true);
    const [formOrgExisting, setFormOrgExisting] = useState(false);

    const [editForm, setEditForm] = useState(false);
    const [isEditDisabled, setIsEditDisabled] = useState(true);
    const [editSubmitString, setEditSubmitString] = useState('Update Project Client');
    const [orgs, setOrgs] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [loadedExisting, setLoadedExisting] = useState(false);

    const [noClient, setNoClient] = useState(false);

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const labelSingleValue = (options, propsValue) => {
        return options.filter(option => option.label === propsValue);
    }

    useEffect(()=> {
        if (formExisting) {
            if (selectedClient !== null) {
                setIsAddDisabled(false);
            }
        } else {
            if (formOrgExisting) {
                (!firstName || !lastName || !email || !organisation) ? setIsAddDisabled(true) : setIsAddDisabled(false);
            } else {
                (!firstName || !lastName || !email || !newOrg) ? setIsAddDisabled(true) : setIsAddDisabled(false);
            }
        }
    }, [firstName, lastName, email, organisation, newOrg, selectedClient, formExisting, formOrgExisting]);

    useEffect(()=> {
        (!selectedClient) ? setIsEditDisabled(true) : setIsEditDisabled(false)
    }, [selectedClient]);

    const queryProjectClient = () => {
        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then(function(res) {
            res.relation('users').query().first().then((res)=>{
                setClientFirstName(res.get('firstName'));
                setClientLastName(res.get('lastName'));
                setClientEmail(res.get('username'));
                setClientId(res.id);
                setLoadedClient(true);
            }).catch((error) => {
                // Error case, no client user linked to project
                setClientFirstName('No user');
                setClientLastName('added');
                setClientEmail('-');
                setClientId('vebHQNgIU2');
                setLoadedClient(true);
                setNoClient(true);
            });
        }).catch((error) => {
            reportError('ProjectClient 1', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryOrgs = () => {
        const Organisation = Parse.Object.extend('Organisation');
        const query = new Parse.Query(Organisation);
        query.limit(999999);
        query.ascending('name');
        query.find()
        .then(function(orgQueryRes) {
            setOrgs(orgQueryRes);
            const options = orgQueryRes.map(org => ({
                "value" : org.id,
                "label" : org.attributes.name,
                "object" : org,
            }));
            setOrgOptions(options);
        }, (error) => {
            reportError('ProjectClient 16', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryUsers = () => {
        const clientQuery = new Parse.Query(Parse.Role);
        clientQuery.equalTo('name', 'client');
        clientQuery.first()
        .then(function(clientQueryRes) {
            const clients = clientQueryRes.getUsers().query();
            clients.limit(999999);
            clients.notEqualTo('email', 'dummy@loteconsulting.com');
            clients.ascending('firstName');
            clients.find()
            .then((userQueryRes) => {
                const options = userQueryRes.map(user => ({
                    "value" : user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName + ' – ' + user.attributes.username  + ' – '  + user.get('organisation').attributes.name,
                    "object" : user,
                    "org" : user.get('organisation'),
                }));
                if (clientId !== '') {
                    setSelectedClient(defaultSingleValue(options, clientId));
                }
                setUserOptions(options);
                setLoadedExisting(true);
            }, (error) => {
                reportError('ProjectClient 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('ProjectClient 2', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const handleClose = () =>  {
        setFoldForm(false);
        setFoldButtonString(<i className="fas fa-angle-up"></i>);
        setLoadedClient(false);
        setUniqueName(false);
        setOrgName(props.row.values.orgDisplay.attributes.name);
        setClientFirstName('');
        setClientLastName('');
        setClientEmail('');
        setClientId('');
        setHasClient(false);
        setAddForm(false);
        setAddSubmitString('Save Project Client');
        setFirstName('');
        setLastName('');
        setEmail('');
        setOrganisation(null);
        setNewOrg('');
        setSelectedClient(null);
        setEditForm(false);
        setEditSubmitString('Update Project Client');
        setLoadedExisting(false);
        setNoClient(false);
        setFormExisting(true);
        setFormOrgExisting(false);
        setShow(false);
    }

    const handleShow = () => {
        if (!loadedClient) {
            queryProjectClient();
        }
        if(props.row.values.orgDisplay.id !== 'FjB0exq7ZX' && (props.row.values.orgDisplay.id)) {
            if (props.row.values.orgDisplay.attributes.type === 'Org') {
                setUniqueName(true);
            }
            setHasClient(true);
            setOrgName(props.row.values.orgDisplay.attributes.name);
        }
        setShow(true);
    }

    const handleAddSubmit = (e) => {
        e.preventDefault();
        setAddSubmitString(<>
            Saving&nbsp;
            <Spinner
                as="span"
                animation="border"
                size="sm"
            />
        </>);

        if (formExisting) {
            addExistingUserToProject();
        } else {
            if (formOrgExisting) {
                addNewUserToProject('existingOrg', null);
            } else {
                var newOrgName = newOrg;
                const Organisation = Parse.Object.extend('Organisation');
                const query = new Parse.Query(Organisation);
                query.equalTo('name', newOrgName);
                query.find()
                .then(function(res) {
                    if (res.length < 1) {
                        const newOrg = new Organisation();
                        newOrg.set('name', newOrgName);
                        newOrg.set('type', 'Org');
        
                        var orgACL = new Parse.ACL();
                        orgACL.setRoleReadAccess('staff', true);
                        orgACL.setRoleWriteAccess('staff', true);
                        newOrg.setACL(orgACL);
        
                        newOrg.save().then((orgRes) => {
                            addNewUserToProject('newOrg', orgRes);
                        }).catch((error)=> {
                            reportError('ProjectClient 3', error.message);
                            alert('Error: Please refresh the page and try again. An error report has been sent.');
                            setEditSubmitString('Update Project Client');
                            setAddSubmitString('Save Project Client');
                        });
                    } else {
                        alert('Error: An organisation with this name already exists.');
                        setEditSubmitString('Update Project Client');
                        setAddSubmitString('Save Project Client');
                    }
                }, (error) => {
                    reportError('ProjectClient 4', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setEditSubmitString('Update Project Client');
                    setAddSubmitString('Save Project Client');
                });
            }
        }
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        setEditSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);
        addExistingUserToProject();
    }

    const addNewUserToProject = (type, orgRes) => {
        const User = Parse.Object.extend('User');
        const query = new Parse.Query(User);
        query.equalTo('email', email);
        query.find()
        .then(function(res) {
            if (res.length < 1) {
                const user = new User();
                user.set('firstName', firstName);
                user.set('lastName', lastName);
                user.set('username', email);
                user.set('email', email);
                user.set('slimView', false);
                user.set('darkMode', false);
                user.set('status', 'Inactive');

                if (type === 'newOrg') {
                    user.set('organisation', orgRes);
                } else {
                    user.set('organisation', organisation.object);
                }

                var userACL = new Parse.ACL();
                userACL.setRoleReadAccess('staff', true);
                userACL.setRoleWriteAccess('staff', true);
                user.setACL(userACL);

                const password = Math.random().toString(36) + Math.random().toString(36).toUpperCase();
                user.set('password', password);

                user.save().then((clientRes) => {

                    if (type === 'newOrg') {
                        var orgAcl = new Parse.ACL();
                        orgAcl.setReadAccess(clientRes, true);
                        orgAcl.setWriteAccess(clientRes, false);
                        orgAcl.setRoleReadAccess('staff', true);
                        orgAcl.setRoleWriteAccess('staff', true);
                        orgRes.setACL(orgAcl);
                        orgRes.save().catch((error)=>{
                            reportError('ProjectClient 5', error.message);
                            alert('Error: Please refresh the page and try again. An error report has been sent.');
                            setAddSubmitString('Save Project Client');
                        });
                    }

                    const queryRole = new Parse.Query(Parse.Role);
                    queryRole.equalTo('name','client').first().then(clientRoleRes => { 
                        clientRoleRes.relation('users').add(clientRes);
                        clientRoleRes.save().then((clientRoleRes2) => {
                            const queryUser = new Parse.Query(Parse.User);
                            queryUser.get(clientId)
                            .then((userToRemove) => {
                                const query = new Parse.Query(Parse.Object.extend('Project'));
                                query.get(props.row.values.idDisplay)
                                .then((project) =>{
                                    if (type === 'newOrg') {
                                        project.set('organisation', orgRes);
                                    } else {
                                        project.set('organisation', organisation.object);
                                    }
                                    project.relation('users').remove(userToRemove);
                                    project.relation('users').add(clientRes);

                                    var projectACL = new Parse.ACL();
                                    projectACL.setRoleReadAccess('staff', true);
                                    projectACL.setRoleWriteAccess('staff', true);
                                    projectACL.setReadAccess(clientRes, true);
                                    projectACL.setWriteAccess(clientRes, false);
                                    project.setACL(projectACL);

                                    project.save()
                                    .then((res)=>{
                                        setAddSubmitString(<>
                                            Saved&nbsp; 
                                            <i className="fas fa-check" style={{color: 'var(--success)'}}></i>
                                        </>);
                                        setClientId(clientRes.id);
                                        setClientEmail(email.toString());
                                        setClientFirstName(firstName);
                                        setClientLastName(lastName);
                                        if (type === 'newOrg') {
                                            setOrgName(orgRes.attributes.name);
                                        } else {
                                            setOrgName(organisation.label);
                                        }
                                        setUniqueName(true);

                                        setAddForm(false);
                                        setHasClient(true);
                                        if (!props.progressMode) {
                                            const dataCopy = props.projectData;
                                            var thisProj = dataCopy[props.row.index];
                                            if (type === 'newOrg') {
                                                thisProj.orgDisplay = orgRes;
                                                thisProj.orgNameDisplay = orgRes.attributes.name;
                                                setOrgName(orgRes.attributes.name);
                                            } else {
                                                thisProj.orgDisplay = organisation.object;
                                                thisProj.orgNameDisplay = organisation.object.attributes.name;
                                            }
                                            dataCopy[props.row.index] = thisProj;
                                            props.setProjectData(dataCopy);
                                        } else {
                                            props.setHasClient(true);
                                            handleClose();
                                        }
                                    }).catch((error)=>{
                                        reportError('ProjectClient 6', error.message);
                                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                                        setEditSubmitString('Update Project Client');
                                        setAddSubmitString('Save Project Client');
                                    })
                                }).catch((error)=>{
                                    reportError('ProjectClient 7', error.message);
                                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                                    setEditSubmitString('Update Project Client');
                                    setAddSubmitString('Save Project Client');
                                })
                            }).catch((error)=>{
                                reportError('ProjectClient 8', error.message);
                                alert('Error: Please refresh the page and try again. An error report has been sent.');
                                setEditSubmitString('Update Project Client');
                                setAddSubmitString('Save Project Client');
                            })
                        }).catch((error)=>{
                            reportError('ProjectClient 9', error.message);
                            alert('Error: Please refresh the page and try again. An error report has been sent.');
                            setEditSubmitString('Update Project Client');
                            setAddSubmitString('Save Project Client');
                        });
                    }, (error) => {
                        reportError('ProjectClient 10', error.message);
                        alert('Error: Please refresh the page and try again. An error report has been sent.');
                        setEditSubmitString('Update Project Client');
                        setAddSubmitString('Save Project Client');

                    });
                }).catch((error)=> {
                    reportError('ProjectClient 11', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setEditSubmitString('Update Project Client');
                    setAddSubmitString('Save Project Client');
                });
            } else {
                alert('Error: A user with this email address already exists.');
                setEditSubmitString('Update Project Client');
                setAddSubmitString('Save Project Client');
            }
        }, (error) => {
            reportError('ProjectClient 12', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setEditSubmitString('Update Project Client');
            setAddSubmitString('Save Project Client');
        });
    }

    const addExistingUserToProject = () => {
        const queryUser = new Parse.Query(Parse.User);
        queryUser.get(clientId)
        .then((userToRemove) => {
            const query = new Parse.Query(Parse.Object.extend('Project'));
            query.get(props.row.values.idDisplay)
            .then((project) =>{
                project.set('organisation', selectedClient.org);
                if (!noClient) {
                    project.relation('users').remove(userToRemove);
                }
                project.relation('users').add(selectedClient.object);

                var projectACL = new Parse.ACL();
                projectACL.setRoleReadAccess('staff', true);
                projectACL.setRoleWriteAccess('staff', true);
                projectACL.setReadAccess(selectedClient.object, true);
                projectACL.setWriteAccess(selectedClient.object, false);
                project.setACL(projectACL);

                project.save()
                .then((res)=>{
                    setEditSubmitString(<>
                        Saved&nbsp; 
                        <i className="fas fa-check" style={{color: 'var(--success)'}}></i>
                    </>);
                    setAddSubmitString(<>
                        Saved&nbsp; 
                        <i className="fas fa-check" style={{color: 'var(--success)'}}></i>
                    </>);
                    setClientId(selectedClient.object.id);
                    setClientEmail(selectedClient.object.attributes.username);
                    setClientFirstName(selectedClient.object.attributes.firstName);
                    setClientLastName(selectedClient.object.attributes.lastName);
                    setOrgName(selectedClient.org.attributes.name);
                    if (selectedClient.org.attributes.type === 'Org') {
                        setUniqueName(true);
                    } else {
                        setUniqueName(false);
                    }
                    if (!props.progressMode) {
                        const dataCopy = props.projectData;
                        var thisProj = dataCopy[props.row.index];
                        if (selectedClient) {
                            thisProj.orgDisplay = selectedClient.org;
                            thisProj.orgNameDisplay = selectedClient.org.attributes.name;
                        }
                        dataCopy[props.row.index] = thisProj;
                        props.setProjectData(dataCopy);
                    } else {
                        props.setHasClient(true);
                        handleClose();

                    }
                }).catch((error)=>{
                    reportError('ProjectClient 13', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setEditSubmitString('Update Project Client');
                    setAddSubmitString('Save Project Client');
                })
            }).catch((error)=>{
                reportError('ProjectClient 14', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setEditSubmitString('Update Project Client');
                setAddSubmitString('Save Project Client');
            })
        }).catch((error)=>{
            reportError('ProjectClient 15', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setEditSubmitString('Update Project Client');
            setAddSubmitString('Save Project Client');
        })
    }

    const handleEmail = (e) => {
        setEmail(e);
        for (let i = 0; i < userOptions.length; i++) {
            if (userOptions[i].object.attributes.username.toLowerCase() === e.toLowerCase()) {
                setEmail('');
                handleClientSelect(defaultSingleValue(userOptions, userOptions[i].value));
            }
        }
    }

    const handleNewOrg = (e) => {
        setNewOrg(e);
        if (e !== '') {
            setOrganisation(null);
            setFormOrgExisting(false);
            setFormExisting(false);
        }
       
        for (let i = 0; i < orgOptions.length; i++) {
            if (orgOptions[i].label.toLowerCase() === e.toLowerCase()) {
                setFormExisting(false);
                setNewOrg('');
                setFormOrgExisting(true);
                setOrganisation(labelSingleValue(orgOptions, orgOptions[i].label));
            }
        }
    }


    const handleOrgSelect = (e) => {
        setFormExisting(false);
        setFormOrgExisting(true);
        setNewOrg('');
        setOrganisation(e);
    }

    const handleClearOrg = () => {
        setOrganisation(null);
        setFormOrgExisting(false);
        setFormExisting(false);
    }

    const handleClientSelect = (e) => {
        setSelectedClient(e);
        setFormExisting(true);
        setFormOrgExisting(false);
        setFoldForm(true);
        setFoldButtonString(<i className="fas fa-angle-down"></i>);
    }

    const handleClearExisting = () => {
        setSelectedClient(null);
        setFormExisting(false);
    }

    const handleFoldButton = () => {
        if (foldForm) {
            setFoldForm(false);
            setSelectedClient(null);
            setFormExisting(false);
            setFoldButtonString(<i className="fas fa-angle-up"></i>);
        } else {
            setFoldForm(true);
            setFormExisting(true);
            setFoldButtonString(<i className="fas fa-angle-down"></i>);
        }
    }

    const handleAddForm = () => {
        if ((props.userOptions.length > 1) || (userOptions.length > 1)) {
            setUserOptions(props.userOptions);
            setOrgOptions(props.orgOptions);
            setLoadedExisting(true);
        } else {
            queryOrgs();
            queryUsers();
        }
        setAddForm(true);
    }

    const handleEditForm = () => {
        if ((props.userOptions.length > 1) || (userOptions.length > 1)) {
            setUserOptions(props.userOptions);
            setOrgOptions(props.orgOptions);
            setLoadedExisting(true);
            setSelectedClient(defaultSingleValue(props.userOptions, clientId));
        } else {
            queryOrgs();
            queryUsers();
        }
        setEditForm(true);
    }

    const EditForm = () => {
        if (!loadedExisting) {
            return <>
                <span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading client list...</em></span>
            </>
        } else {
            return <>
                <Form onSubmit={handleEditSubmit}>
                    <Select 
                        styles={customStyles}
                        options={userOptions}
                        value={selectedClient}
                        defaultValue={selectedClient}
                        onChange={e=>handleClientSelect(e)}
                    />
                </Form>
            </>
        }
    }

    const handleExited = () => {
        if (!props.progressMode) {
            if (props.userOptions.length < 10) {
                props.setUserOptions(userOptions);
                props.setOrgs(orgs);
                props.setOrgOptions(orgOptions)
            } 
            props.handleExit();
        }
    }

    return <>
        <Button variant='outline-dark' className='cerulean-button square-button' onClick={handleShow} 
            ref={target} onMouseEnter={() => setShowOverlay(true)} onMouseLeave={() => setShowOverlay(false)}>
            <i className="fas fa-user"></i>
        </Button>
        <Overlay target={target.current} show={showOverlay} placement="right">
            {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
            }) => (
                <div
                    {...props}
                    style={{
                        position: 'absolute',
                        backgroundColor: 'var(--box-background)',
                        padding: '2px 10px',
                        color: 'var(--text)',
                        borderRadius: 3,
                        ...props.style,
                    }}
                >
                    {orgName}
                </div>
            )}
        </Overlay>
        <Modal show={show} onHide={handleClose} onExited={handleExited} centered backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Client: {props.row.values.projectNumberDisplay} — {props.row.values.projectNameDisplay}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                {!hasClient && (                    
                    <span>
                        {!addForm && (
                            <Button variant="outline-primary" onClick={handleAddForm}>
                                Add Client to Project
                            </Button>
                        )}
                        {addForm && (
                            <Form onSubmit={handleAddSubmit}>
                                <Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Existing Client</Form.Label>                            
                                    </Form.Group>
                                </Row>
                                <Row>
                                     <Form.Group as={Col} md="10" className="mb-3">
                                        <Select 
                                            styles={customStyles}
                                            options={userOptions}
                                            value={selectedClient}
                                            defaultValue={selectedClient}
                                            onChange={e=>handleClientSelect(e)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" className="mb-3">
                                        <Button className='mx-auto' variant="outline-secondary" onClick={handleClearExisting}>
                                            Clear
                                        </Button>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Button
                                        variant="outline-secondary"
                                        className='square-button ms-auto'
                                        onClick={handleFoldButton}
                                    >
                                        {foldButtonString}
                                    </Button>
                                </Row>
                                {!foldForm && (
                                    <span>
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                /> 
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="12" className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => handleEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="12">
                                                <Form.Label>Organisation</Form.Label>                            
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="10" className="mb-3">
                                                <Select 
                                                    styles={customStyles}
                                                    options={orgOptions}
                                                    value={organisation}
                                                    defaultValue={organisation}
                                                    onChange={e=>handleOrgSelect(e)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2" className="mb-3">
                                                <Button className='mx-auto' variant="outline-secondary" onClick={handleClearOrg}>
                                                    Clear
                                                </Button>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Control
                                                    value={newOrg}
                                                    type="text"
                                                    onChange={(e) => handleNewOrg(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </span>
                                )}
                            </Form>
                        )}
                    </span>
                )}
                {hasClient && (
                    <div>
                        {loadedClient && (
                            <span>
                                {uniqueName && (
                                    <p>Organisation: {orgName}</p>
                                )}
                                <p>Name: {clientFirstName}&nbsp;{clientLastName} </p>
                                <p>Email: {clientEmail}</p>
                                {!editForm && (
                                    <Button variant="outline-primary" onClick={handleEditForm}>
                                        Edit Project Client
                                    </Button>
                                )}
                                {editForm && (
                                    <EditForm />
                                )}
                            </span>
                        )}
                        {!loadedClient && (
                            <span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading client...</em></span>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {editForm && (
                    <Button
                        variant="primary"
                        onClick={handleEditSubmit}
                        disabled={isEditDisabled}
                    >
                        {editSubmitString}
                    </Button>
                )}
                {addForm && (
                    <Button
                        variant="primary"
                        onClick={handleAddSubmit}
                        disabled={isAddDisabled}
                    >
                        {addSubmitString}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    </>
}
