import { useState, useEffect } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { customStyles } from '../../data/Data.js'
import reportError from '../../utils/ReportError';



export default function EditProjectClient(props) {

    const Parse = require('parse');

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [orgs, setOrgs] = useState([]);
    const [isOrgsLoaded, setIsOrgsLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [client, setClient] = useState();
    const [submitString, setSubmitString] = useState('Update Project Client');

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    useEffect(()=>{
        setClient(defaultSingleValue(userOptions, props.clientId));
    }, [isUsersLoaded])

    useEffect(()=> {
        (!client) ? setIsDisabled(true) : setIsDisabled(false)
    }, [client]);

    const handleClose = () =>  {
        setShow(false);
        setIsOrgsLoaded(false);
        setIsUsersLoaded(false);
        setSubmitString('Update Project Client');
    }

    const handleShow = () => {
        setShow(true);
        if (!isOrgsLoaded) {
            queryOrgs();
        }
        if (!isUsersLoaded) {
            queryUsers();
        }
    }

    const queryOrgs = () => {
        const Organisation = Parse.Object.extend('Organisation');
        const query = new Parse.Query(Organisation);
        query.limit(999999);
        query.ascending('name');
        query.find()
        .then(function(orgQueryRes) {
            setOrgs(orgQueryRes);
            setIsOrgsLoaded(true);
        }, (error) => {
            reportError('EditProjectClient 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const queryUsers = () => {
        const clientQuery = new Parse.Query(Parse.Role);
        clientQuery.equalTo('name', 'client');
        clientQuery.first()
        .then(function(clientQueryRes) {
            const clients = clientQueryRes.getUsers().query();
            clients.limit(999999);
            clients.notEqualTo('email', 'dummy@loteconsulting.com');
            clients.ascending('firstName');
            clients.find()
            .then((userQueryRes) => {
                setUsers(userQueryRes);
                const options = userQueryRes.map(user => ({
                    "value" : user.id,
                    "label" : user.attributes.firstName + ' ' + user.attributes.lastName + ' – ' + user.attributes.username  + ' – '  + user.get('organisation').attributes.name,
                    "object" : user,
                    "org" : user.get('organisation'),
                }));
                setUserOptions(options);
                setIsUsersLoaded(true);
            }, (error) => {
                reportError('EditProjectClient 2', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
            })
        }, (error) => {
            reportError('EditProjectClient 3', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const queryUser = new Parse.Query(Parse.User);
        queryUser.get(props.clientId)
        .then((userToRemove) => {
            const query = new Parse.Query(Parse.Object.extend('Project'));
            query.get(props.project.id)
            .then((project) =>{
                project.set('organisation', client.org);
                project.relation('users').remove(userToRemove);
                project.relation('users').add(client.object);
                project.save()
                .then((res)=>{
                    const clientName = client.object.attributes.firstName + ' ' + client.object.attributes.lastName;
                    const orgName = client.org.attributes.name;
                    if (clientName === orgName) {
                        props.setOrganisationName('');
                    } else {
                        props.setOrganisationName(orgName);
                    }
                    props.setClientName(clientName);
                    props.setClientEmail(client.object.attributes.username);
                    props.setClientId(client.value);
                    handleClose();
                }).catch((error)=>{
                    reportError('EditProjectClient 4', error.message);
                    alert('Error: Please refresh the page and try again. An error report has been sent.');
                    setSubmitString('Update Project Client');
                })
            }).catch((error)=>{
                reportError('EditProjectClient 5', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Update Project Client');
            })
        }).catch((error)=>{
            reportError('EditProjectClient 6', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Update Project Client');
        })
    }

    const handleClientSelect = (e) => {
        setClient(e);
    }

    return <>
        <Button variant='outline-secondary' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header className='modal-header' closeButton>
                <Modal.Title>Update Client</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'> 
                <Form onSubmit={handleSubmit}>
                    <Select 
                        styles={customStyles}
                        options={userOptions}
                        value={client}
                        defaultValue={client}
                        onChange={e=>handleClientSelect(e)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
