import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import history from '../../utils/History';
import reportError from '../../utils/ReportError';

export default function Login() {

    const logoutHandler = () => {
        const Parse = require('parse');

        // Cookies.remove('email');
        // Cookies.remove('user');
        Cookies.remove('sessionToken');

        Parse.User.logOut()
        .then(() => {
            history.push('/');
            history.go(0);
        }).catch((error) => {
            history.push('/');
            history.go(0);
            // reportError('Logout 1', error.message);
            // alert('Error: Please refresh the page and try again. An error report has been sent.');
        });
    }
    
    return <>
        <Button variant="outline-primary" onClick={logoutHandler}>
            Logout
        </Button>
    </> 
}
