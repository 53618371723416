import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Spinner, Stack } from 'react-bootstrap';
import NumericFormat from 'react-number-format';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-au';
import { customStyles, 
    documentOptions} from '../../data/Data.js'
import RangeSlider from 'react-bootstrap-range-slider';
import ProjectTasks from './ProjectTasks';
import ProjectClient from './ProjectClient';
import reportError from '../../utils/ReportError';


export default function ProgressProject(props) {

    const Parse = require('parse');

    const [mode, setMode] = useState('pipeline');
    const [errorText, setErrorText] = useState('');
    
    const [showLoss, setShowLoss] = useState(false);
    const [showWin, setShowWin] = useState(false);
    const [showComplete, setShowComplete] = useState(false);

    const [submitStringLoss, setSubmitStringLoss] = useState('Mark as Lost');
    const [submitStringWin, setSubmitStringWin] = useState('Mark as Won');
    const [submitStringComplete, setSubmitStringComplete] = useState('Mark as complete');

    const [isDisabledLoss, setIsDisabledLoss] = useState(true);
    const [isDisabledWin, setIsDisabledWin] = useState(true);
    const [isDisabledComplete, setIsDisabledComplete] = useState(true);

    const [hasClient, setHasClient] = useState(false);

    const [fee, setFee] = useState('');
    const [document, setDocument] = useState('');
    const [invoiced, setInvoiced] = useState('');
    const [manager, setManager] = useState();
    // const [number, setNumber] = useState();
    const [commencedOn, setCommencedOn] = useState('');
    const [completedOn, setCompletedOn] = useState('');

    const today = new Date();
    const thisYear2Digit = today.getFullYear().toString().slice(-2);

    const defaultSingleValue = (options, propsValue) => {
        return options.filter(option => option.value === propsValue);
    }

    const defaultSingleValue2 = (options, propsValue) => {
        return options.filter(option => option.value === propsValue)[0];
    }

    const defaultMultiValue = (options, propsValue) => {
        return options.filter(option => 
            propsValue.map(propOption => {return propOption}).includes(option.value));
    }

    const simplifyMultiArray = (arrayToMod) => {
        var toReturn = [];
        for (let i = 0; i < arrayToMod.length; i++) {
            toReturn[i] = arrayToMod[i].value
        }
        return toReturn;
    }

    useEffect(()=>{
        if (props.pipeSwitch) {
            setMode('pipeline');
        } else if (props.databaseSwitch) {
            setMode('database');
        } else {
            setMode('current');
        }
    }, [props.pipeSwitch, props.databaseSwitch])

    useEffect(()=> {
        (!document[0] || !fee || !hasClient) ? setIsDisabledLoss(true) : setIsDisabledLoss(false)

    }, [document, fee, hasClient]);

    useEffect(()=> {
        (!document[0] || !fee || !commencedOn || !hasClient) ? setIsDisabledWin(true) : setIsDisabledWin(false)
    }, [document, fee, commencedOn, hasClient]);

    useEffect(()=> {
        if (invoiced === fee) {
            setErrorText('');
            (!invoiced || !completedOn) ? setIsDisabledComplete(true) : setIsDisabledComplete(false)
        } else {
            setErrorText('Invoiced amount must equal total fee');
            setIsDisabledComplete(true);
        }
    }, [invoiced, completedOn, fee]);

    const loadMetas = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        if(props.row.values.orgDisplay.id !== 'qRTS98lQsP' && (props.row.values.orgDisplay.id)) {
            setHasClient(true);
        }

        setManager(defaultSingleValue2(props.projectManagerOptions, props.row.values.personDisplay));
        // var nextNum = props.nextProjectNumber;
        // switch (nextNum.toString().length) {
        //     case 3:
        //         setNumber('RCE-' + thisYear2Digit + nextNum);
        //         break;
        //     case 2:
        //         setNumber('RCE-' + thisYear2Digit + '0' + nextNum);
        //         break;
        //     case 1:
        //         setNumber('RCE-' + thisYear2Digit + '00' + nextNum);
        //         break;    
        //     default:
        //         setNumber('RCE-' + thisYear2Digit + nextNum);
        //         break;
        // }
        setFee(props.row.values.feeDisplay);
        setInvoiced(props.row.values.invoicedDisplay);
        setDocument(defaultMultiValue(documentOptions, props.row.values.documentDisplay.split(',')));

        if (props.row.values.commencedOnDisplay === '') {
            setCommencedOn(today);
        } else {
            setCommencedOn(props.row.values.commencedOnDisplay);
        }

        if (props.row.values.completedOnDisplay === '') {
            setCompletedOn(today);
        } else {
            setCompletedOn(props.row.values.completedOnDisplay);
        }
    }

    const closeMetas = () => {
        setHasClient(true);
        // setHasClient(false);
        setFee('');
        setInvoiced('');
        setDocument('');
        setCommencedOn('');
        setCompletedOn('');
    }

    const handleCloseLoss = () =>  {
        closeMetas()
        setSubmitStringLoss('Mark as Lost');
        setShowLoss(false);
    }

    const handleShowLoss = () => {
        loadMetas();
        setShowLoss(true);
    }

    const handleSubmitLoss = () => {
        setSubmitStringLoss(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Loss');
            if (document[0]) {
                project.set('document', simplifyMultiArray(document));
            }
            if (fee) {
                project.set('fee', parseInt(fee.slice(1)));
            }
            project.save()
            .then((res2)=>{
                const dataCopy = props.projectData;
                var thisProj = dataCopy[props.row.index];
                thisProj.statusDisplay = 'Loss';
                if (fee) {
                    thisProj.feeDisplay = fee;
                }
                // if (document[0]) {
                //     thisProj.documentDisplay = simplifyMultiArray(document);
                // }
                dataCopy[props.row.index] = thisProj;
                props.setProjectData(dataCopy);
                handleCloseLoss();
            }).catch((error)=>{
                reportError('ProgressProject 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitStringLoss('Mark as Lost');
            })
        }).catch((error)=>{
            reportError('ProgressProject 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitStringLoss('Mark as Lost');
        })
    }

    // function isInt(value) {
    //     return !isNaN(value) && 
    //            parseInt(Number(value)) === value && 
    //            !isNaN(parseInt(value, 10));
    // }

    const handleCloseWin = () =>  {
        closeMetas();
        setSubmitStringWin('Mark as won');
        setShowWin(false);
    }

    const handleShowWin = () => {
        loadMetas();
        setShowWin(true);
    }

    const handleSubmitWin = () => {
        setSubmitStringWin(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Allocated');
            // var displayNumber;
            // if (isInt(number)) {
            //     displayNumber = 'RCE-' + thisYear2Digit + number;
            //     project.set('projectId', displayNumber);
            // } else {
            //     displayNumber = number;
            //     project.set('projectId', displayNumber);
            // }

            if (manager.object) {
                project.set('managerId', manager.object);
            }

            if (document[0]) {
                project.set('document', simplifyMultiArray(document));
            }
            if (fee) {
                project.set('fee', parseInt(fee.slice(1)));
            }
            if (commencedOn !== '') {
                project.set('commencedOn', commencedOn);
            }
            const today = new Date();
            const fourweeks = new Date(today);
            fourweeks.setDate(fourweeks.getDate() + 28);
            project.set('deadline', fourweeks);

            // if (contract && contractTask) {
            //     const Task = Parse.Object.extend('Task');
            //     const task = new Task();

            //     var reviewUser = defaultSingleValue(props.projectManagerOptions, 'BKD0LlA2EC');
            //     var existingProject = defaultSingleValue(props.projectOptions, props.row.values.idDisplay);;

            //     task.set('assignedTo', reviewUser[0].object);
            //     task.set('action', 'Review contract');
            //     task.set('recurring', false);
            //     task.set('link', false);
            //     task.set('complete', false);
            //     task.set('deadline', today);
            //     task.set('createdBy', props.currentUser);

            //     var taskACL = new Parse.ACL();
            //     taskACL.setRoleReadAccess('staff', true);
            //     taskACL.setRoleWriteAccess('staff', true);
            //     task.setACL(taskACL);

            //     task.set('project', existingProject[0].object);
            //     task.save()
            //     .then((task)=>{
            //         setContractTask(false);
            //     }, (error) => {
                // reportError('ProgressProject 3', error.message);
                // alert('Error: Please refresh the page and try again. An error report has been sent.');
            //     });
            // }

            project.save()
            .then((res2)=>{
                const dataCopy = props.projectData;
                var thisProj = dataCopy[props.row.index];
                // if (number) {
                //     thisProj.projectNumberDisplay = displayNumber.slice(-3);
                //     thisProj.projectDisplay = <Link target='_blank' to={'/staff/' + displayNumber}>{displayNumber} — {props.row.values.projectNameDisplay}</Link>;
                // }
                if (manager.object) {
                    thisProj.personDisplay = manager.object.id;
                    thisProj.personNameDisplay = manager.object.attributes.firstName + ' ' + manager.object.attributes.lastName;
                }
                    thisProj.statusDisplay = 'Allocated';

                if (fee) {
                    thisProj.feeDisplay = fee;
                }
                // if (document[0]) {
                //     thisProj.documentDisplay = simplifyMultiArray(document);
                // }
                dataCopy[props.row.index] = thisProj;
                props.setProjectData(dataCopy);
                handleCloseWin();
                props.handleCelebrate();
            }).catch((error)=>{
                reportError('ProgressProject 4', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitStringWin('Mark as Won');
            })
        }).catch((error)=>{
            reportError('ProgressProject 5', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitStringWin('Mark as Won');
        })
    }

    const handleCloseComplete = () =>  {
        closeMetas();
        setSubmitStringComplete('Mark as complete');
        setShowComplete(false);
    }

    const handleShowComplete = () => {
        loadMetas();
        setShowComplete(true);
    }

    const handleSubmitComplete = () => {
        setSubmitStringComplete(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const query = new Parse.Query(Parse.Object.extend('Project'));
        query.get(props.row.values.idDisplay)
        .then((project) =>{
            project.set('status', 'Invoiced');
            if (fee) {
                project.set('fee', parseInt(fee.slice(1)));
            }
            if (invoiced) {
                project.set('invoiced', parseInt(invoiced.slice(1)));
            }
            if (completedOn !== '') {
                project.set('completedOn', completedOn);
            }
            project.set('star', false);
            project.save()
            .then((res2)=>{
                const dataCopy = props.projectData;
                var thisProj = dataCopy[props.row.index];
                thisProj.statusDisplay = 'Invoiced';
                if (fee) {
                    thisProj.feeDisplay = fee;
                }
                if (invoiced) {
                    thisProj.invoicedDisplay = invoiced;
                }
                dataCopy[props.row.index] = thisProj;
                props.setProjectData(dataCopy);
                handleCloseComplete();
                props.handleCelebrate();
            }).catch((error)=>{
                reportError('ProgressProject 6', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitStringComplete('Mark as complete');
            })
        }).catch((error)=>{
            reportError('ProgressProject 7', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitStringComplete('Mark as complete');
        })
    }
    
    switch (mode) {
        case 'pipeline':
            return <>
                <Stack direction="horizontal" gap={1} >
                    <div>
                        <Button variant='outline-success' className='square-button' onClick={handleShowWin}>
                            <i className="fas fa-check"></i>
                        </Button>
                    </div>
                    <div>
                        <Button variant='outline-danger' className='square-button' onClick={handleShowLoss}>
                            <i className="fas fa-times"></i>
                        </Button>
                    </div>
                </Stack>
                <Modal show={showLoss} onHide={handleCloseLoss} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Loss: {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitLoss}>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Group controlId="form-project-manager">
                                        <Form.Label>Fee (AUD)</Form.Label>
                                        <NumericFormat
                                            className="form-control"
                                            displayType="input"
                                            prefix={'$'}
                                            allowNegative={false}
                                            value = {fee}
                                            onChange={(e) => setFee(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Group>
                                        <Form.Label>Documents</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={documentOptions}
                                                value={document}
                                                defaultValue={document}
                                                onChange={e=>setDocument(e)}
                                                isMulti
                                            />
                                    </Form.Group>
                                </Form.Group>
                            </Row>
                            {!hasClient && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="12">
                                        <Stack direction='horizontal' gap={1}>
                                            <ProjectClient
                                                row={props.row}
                                                handleExit={props.handleExit}
                                                progressMode={true}
                                                users={props.users}
                                                setUsers={props.setUsers}
                                                userOptions={props.userOptions}
                                                setUserOptions={props.setUserOptions}
                                                orgs={props.orgs}
                                                setOrgs={props.setOrgs}
                                                orgOptions={props.orgOptions}
                                                setOrgOptions={props.setOrgOptions}
                                                projectData={[...props.projectData]}
                                                setProjectData={props.setProjectData}
                                                hasClient={hasClient}
                                                setHasClient={setHasClient}
                                            />
                                            <span>&nbsp;&nbsp;&nbsp;
                                                <span className='form-fail'>
                                                    No client linked to project
                                                </span>
                                            </span>
                                            <span
                                                className='ms-auto'
                                            >
                                                &nbsp;
                                            </span>
                                        </Stack>
                                    </Form.Group>
                                </Row>
                            )}

                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseLoss}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitLoss}
                            disabled={isDisabledLoss}
                        >
                            {submitStringLoss}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showWin} onHide={handleCloseWin} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Win: {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitWin}>
                            <Row className="mb-2">
                                {/* <Form.Group as={Col} md="4" id="new-project-number" className="mb-3">
                                    <Form.Label>Project Number</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'RCE-' + thisYear2Digit}
                                        allowNegative={false}
                                        allowLeadingZeros
                                        value = {number}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Group controlId="form-project-manager">
                                        <Form.Label>Allocated To</Form.Label>
                                        <Select
                                            styles={customStyles}
                                            id='form-project-manager'
                                            options={props.projectManagerOptions}
                                            value={manager}
                                            defaultValue={manager}
                                            onChange={e=>setManager(e)}
                                        />
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Fee (AUD)</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        displayType="input"
                                        prefix={'$'}
                                        allowNegative={false}
                                        value = {fee}
                                        onChange={(e) => setFee(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Commenced On</Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={commencedOn}
                                        onChange={(date)=> setCommencedOn(date)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Group>
                                        <Form.Label>Documents</Form.Label>
                                            <Select
                                                styles={customStyles}
                                                options={documentOptions}
                                                value={document}
                                                defaultValue={document}
                                                onChange={e=>setDocument(e)}
                                                isMulti
                                            />
                                    </Form.Group>
                                </Form.Group>
                            </Row>                            
                            {!hasClient && (
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="12">
                                        <Stack direction='horizontal' gap={1}>
                                            <ProjectClient
                                                row={props.row}
                                                handleExit={props.handleExit}
                                                progressMode={true}
                                                users={props.users}
                                                setUsers={props.setUsers}
                                                userOptions={props.userOptions}
                                                setUserOptions={props.setUserOptions}
                                                orgs={props.orgs}
                                                setOrgs={props.setOrgs}
                                                orgOptions={props.orgOptions}
                                                setOrgOptions={props.setOrgOptions}
                                                projectData={[...props.projectData]}
                                                setProjectData={props.setProjectData}
                                                hasClient={hasClient}
                                                setHasClient={setHasClient}
                                            />
                                            <span>&nbsp;&nbsp;&nbsp;
                                                <span className='form-fail'>
                                                    No client linked to project
                                                </span>
                                            </span>
                                            <span
                                                className='ms-auto'
                                            >
                                                &nbsp;
                                            </span>
                                        </Stack>
                                    </Form.Group>
                                </Row>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseWin}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitWin}
                            disabled={isDisabledWin}
                        >
                            {submitStringWin}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        case 'current':
            return <>
                <Button variant='outline-success' className='square-button' onClick={handleShowComplete}>
                    <i className="fas fa-check"></i>
                </Button>
                <Modal show={showComplete} onHide={handleCloseComplete} onExited={props.handleExit} centered size='lg' backdrop="static">
                    <Modal.Header className='modal-header' closeButton>
                        <Modal.Title>Mark Complete: {props.row.values.projectNameDisplay}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modal-body'>
                        <Form onSubmit={handleSubmitComplete}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Group controlId="form-project-manager">
                                        <Form.Label>Fee (AUD)</Form.Label>
                                        <NumericFormat
                                            className="form-control"
                                            displayType="input"
                                            prefix={'$'}
                                            allowNegative={false}
                                            value = {fee}
                                            onChange={(e) => setFee(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Group controlId="form-project-manager">
                                        <Form.Label>Invoiced</Form.Label>
                                        <NumericFormat
                                            className="form-control"
                                            displayType="input"
                                            prefix={'$'}
                                            allowNegative={false}
                                            value = {invoiced}
                                            onChange={(e) => setInvoiced(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Group>
                                    <Form.Group as={Col} md="4">
                                    <Form.Label>Completed On</Form.Label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control'
                                        selected={completedOn}
                                        onChange={(date)=> setCompletedOn(date)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <div className='form-fail'>
                                    {errorText}
                                </div>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='modal-header'>
                        <Button variant="secondary" onClick={handleCloseComplete}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmitComplete}
                            disabled={isDisabledComplete}
                        >
                            {submitStringComplete}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        default:
            return <>
                <span></span>
            </>
    }
}
