import { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Row, Col } from 'react-bootstrap';
import reportError from '../../utils/ReportError';


export default function EditOrg(props) {

    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState();
    const [PO, setPO] = useState(false);
    const [submitString, setSubmitString] = useState('Save Organisation');

    useEffect(()=>{
        setName(props.row.values.nameDisplay);
        if (props.row.values.POValueDisplay === 'true') {
            setPO(true);
        } else {
            setPO(false);
        }
    }, [show])

    useEffect(()=> {
        (!name) ? setIsDisabled(true) : setIsDisabled(false)
    }, [name]);


    const handleClose = () =>  {
        setShow(false);
        setName('');
        setPO(false);
        setSubmitString('Save Organisation');
    }

    const handleShow = () => {
        setShow(true);
    }
      
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitString(<>
            Saving&nbsp; 
            <Spinner
                as="span"
                animation="border"
                size="sm"
            /> 
        </>);

        const orgId = props.row.values.idDisplay;
        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Organisation'));
        query.get(orgId)
        .then((org) => {
            org.set('name', name);
            org.set('POrequired', PO);
            org.save()
            .then((res)=>{
                const dataCopy = props.orgData;
                var thisOrg = dataCopy[props.row.index];
                if (name) {
                    thisOrg.nameDisplay = name;
                } 
                if (PO) {
                    thisOrg.POValueDisplay = 'true';
                    thisOrg.PODisplay = <i className="fas fa-check" style={{color: 'var(--success)'}}></i>;
                } else {
                    thisOrg.POValueDisplay = 'false';
                    thisOrg.PODisplay = <i className="fas fa-times" style={{color: 'var(--danger)'}}></i>;
                }
                dataCopy[props.row.index] = thisOrg;
                props.setOrgData(dataCopy);
                handleClose();
            }).catch((error)=>{
                reportError('EditOrg 1', error.message);
                alert('Error: Please refresh the page and try again. An error report has been sent.');
                setSubmitString('Save Organisation');
            })
        }).catch((error)=>{
            reportError('EditOrg 2', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
            setSubmitString('Save Organisation');
        })
    }

    return <>
        <Button variant='outline-secondary' onClick={handleShow}>
            <i className="fas fa-pen"></i>
        </Button>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title>Edit Organisation</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                id='name-text'
                                type='text'
                                value={name}
                                onChange={(e) => {setName(e.target.value);}}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group>
                            <Form.Check
                                type="switch"
                                label="PO Required"
                                checked={PO}
                                onChange={(e) => setPO(e.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='modal-header'>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {submitString}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
