import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/en-au';
import reportError from '../../utils/ReportError';


export default function RecurringHistory(props) {

    const [show, setShow] = useState(false);
    const [taskDisplayString, setTaskDisplayString] = useState('');
    const [recurringOutcomes, setRecurringOutcomes] = useState([]);
    const [outcomesLoaded, setOutcomesLoaded] = useState(false);

    const handleClose = () =>  {
        setTaskDisplayString('');
        setRecurringOutcomes([]);
        setOutcomesLoaded(false);
        setShow(false);
    }

    const handleShow = () => {
        if (props.fromDashboard) {
            props.hideLastPopover();
        }
        const Parse = require('parse');
        const query = new Parse.Query(Parse.Object.extend('Task'));
        query.get(props.row.values.idDisplay)
        .then((task) => {
            var outcomes = task.get('recurringOutcomes');
            var dateDisplay = [];
            let j = 0;
            if (outcomes) {
                for (let i = outcomes.length - 1; i >= 0; i--) {
                    var thisDate = moment(outcomes[i].date).locale('en-au').calendar({
                        // sameDay: '[Today]',
                        // nextDay: '[Tomorrow]',
                        nextWeek: 'ddd DD MMM YYYY h:mm A',
                        // lastDay: '[Yesterday]',
                        lastWeek: 'ddd DD MMM YYYY h:mm A',
                        sameElse: 'ddd DD MMM YYYY h:mm A' 
                    });
                    dateDisplay[j] = thisDate;
                    j++;
                }
                setRecurringOutcomes(dateDisplay);
                setOutcomesLoaded(true);
            } else {
                setRecurringOutcomes(['No history']);
                setOutcomesLoaded(true);
            }
        }).catch((error)=>{
            reportError('RecurringHistory 1', error.message);
            alert('Error: Please refresh the page and try again. An error report has been sent.');
        })
        var taskString = '';
        taskString += props.row.values.actionTextDisplay;
        if (props.row.values.projStringDisplay.length > 0) {
            taskString += ' ';
            taskString += props.row.values.projStringDisplay;
        }
        setTaskDisplayString(taskString);
        setShow(true);
    }

    const ShowOutcomes = () => {
        if (outcomesLoaded) {
            return <>
                {recurringOutcomes.map(
                    name => <span>{name}<br /></span>
                )}
            </>
        } else {
            return <>
               <span style={{color:'var(--text)', fontSize:'1em'}}><em>Loading...</em></span>
            </>
        }
    }

    const handleExited = () => {
        if (!props.insideComplete) {
            props.handleExit();
        }
    }

    if (props.row.values.isRecurring === true && (props.row.values.isComplete === 'true' || props.insideComplete === true)) {
        return <>
            <Button variant='outline-info' className='square-button' onClick={handleShow}>
                <i className="fas fa-history"></i>
            </Button>
            <Modal show={show} onHide={handleClose} onExited={handleExited} centered size='md' backdrop="static">
                <Modal.Header className='modal-header' closeButton>
                    <Modal.Title>Recurring History</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    {taskDisplayString}
                    <br />
                    <br />
                    <span>
                        <ShowOutcomes />
                    </span>
                </Modal.Body>
                <Modal.Footer className='modal-header'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    } else {
        return null;
    }
}
